import type { FC, ReactNode } from "react";
import useAuth from "@/hooks/useAuth";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";

type PartialAuthGuardProps = {
  children: ReactNode;
};

const REDIRECT_KEY = "_redirect";

function redirectToPath(path: string, redirectAfterParam: string) {
  return <Navigate to={`${path}?${REDIRECT_KEY}=${encodeURIComponent(redirectAfterParam)}`} replace={true} />;
}

const PartialAuthGuard: FC<PartialAuthGuardProps> = (props) => {
  const { children } = props;
  const { policy } = useAuth();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const redirect = decodeURIComponent(searchParams.get(REDIRECT_KEY) || "/");

  if (!policy) {
    return <Navigate to={redirect} replace={true} />;
  }

  if (
    (policy === "mfa_challenge" && location.pathname.includes("mfa-setup")) ||
    (policy === "mfa_required" && location.pathname.includes("mfa-challenge"))
  ) {
    return redirectToPath("/authentication/login", redirect);
  }

  return <>{children}</>;
};

export default PartialAuthGuard;
