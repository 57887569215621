export function createDateWithoutCurrentTimeZone(stringDate: string) {
  if (isNaN(new Date(stringDate).getDate())) {
    throw new Error("Invalid string date");
  }

  const date = new Date(stringDate);
  date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  return date;
}

export function isStringDate(dateStr: string): boolean {
  if (isNaN(Number(dateStr)) && !isNaN(Date.parse(dateStr))) return true;
  else return false;
}
