import { mapToOption } from "@/components/group-by/utils";
import useAuth from "@/hooks/useAuth";
import { useEmployeeFields } from "@/hooks/useEmployeeFields";
import { isString, uniq } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useDeepCompareEffect, useDeepCompareMemo } from "use-deep-compare";

export const GROUP_BY_SEARCH_PARAM_KEY = "groupBy";

export enum GroupByOption {
  DATE = "date",
  DEPARTMENT = "department",
  COUNTRY = "country",
  TENURE = "tenure",
  SUB_DEPARTMENT = "sub_department",
  LOCATION = "location",
  DIVISION = "division",
  CUSTOM_TEAM = "custom_team",
  DIRECT_TEAM = "direct_team",
  INDIRECT_TEAM = "indirect_team",
  PERFORMANCE_SCORE = "performance_score",
  EMPLOYEE_GRADE = "employee_grade",
}

/**
 * Grabs the group by options from the URL and returns them as an array
 * Safely removes invalid options or attack attempts
 */
const urlSearchParamsToGroupByArray = <T extends string>(searchParams: URLSearchParams, options: T[]): T[] => {
  const groupBySearchParams = searchParams.get(GROUP_BY_SEARCH_PARAM_KEY);
  if (!groupBySearchParams) return [];

  return groupBySearchParams.split(",").filter((option): option is T => {
    if (!isString(option)) return false;
    // If the option is not in the available options, return false
    if (!options.includes(option as T)) return false;
    // Otherwise, return true
    return true;
  });
};

export interface GroupByConfiguration<T extends string> {
  options: T[];
  defaultValues?: T[];
  /**
   * If a value is required, it will be added to the group by options and cannot be removed
   * Also the value will be added to the default values
   */
  requiredValues?: T[];
}

export interface UseGroupByState<T extends string> {
  configuration: GroupByConfiguration<T>;
  options: T[];
  optionsWithLabels: { label: string; value: T }[];
  /**
   * Group by options in the order they should be displayed
   */
  values: T[];
  setValues: (values: T[]) => void;
  /**
   * Returns the group by options that are required
   * These options cannot be removed
   */
  required: T[];
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const useGroupBy = <T extends string>(
  { options, requiredValues = [], defaultValues = [], ...rest }: GroupByConfiguration<T>,
  persistInUrl = true
): UseGroupByState<T> => {
  const user = useAuth();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { getEmployeeFieldTranslation } = useEmployeeFields();

  const groupByFromUrl = urlSearchParamsToGroupByArray(searchParams, options);

  const initialValues = uniq([...(persistInUrl ? groupByFromUrl : []), ...requiredValues, ...defaultValues]);

  const [grouping, setGrouping] = useState<T[]>(initialValues);

  const optionsWithLabels = useMemo(
    () => options.map((option) => mapToOption(t, getEmployeeFieldTranslation, option)),
    [options, user.organizationReference, i18n.language]
  );

  const setValue = (values: T[]) => {
    // Set required values if not already set
    const valuesWithRequired = uniq([...values, ...requiredValues]);
    setGrouping(valuesWithRequired);
  };

  /**
   * This function returns the filters as URLSearchParams, which can be used to update the URL
   * or to query the API.
   */
  const getGroupByAsURLParams = (appendTo?: URLSearchParams): URLSearchParams => {
    const params = new URLSearchParams(appendTo);
    params.set(GROUP_BY_SEARCH_PARAM_KEY, grouping.join(","));
    return params;
  };

  // If the URL changes, update the filters.
  useDeepCompareEffect(() => {
    if (persistInUrl) {
      setValue(groupByFromUrl);
    }
  }, [groupByFromUrl]);

  // If the filters change, update the URL.
  useEffect(() => {
    if (persistInUrl && options.length > 0) {
      setSearchParams(getGroupByAsURLParams(searchParams), { replace: true });
    }
  }, [grouping]);

  const memoizedValues = useDeepCompareMemo(() => uniq(grouping), [grouping]);

  return {
    configuration: { options, requiredValues, defaultValues, ...rest },
    options,
    optionsWithLabels,
    required: requiredValues ?? [],
    values: memoizedValues,
    setValues: setValue,
    open,
    setOpen,
  };
};
