import { JWTUser } from "@alanszp/jwt";
import { PermissionCode } from "@/types/PermissionCode";

/**
 * Util to return the options the user has depending on their permissions.
 *
 * @param jwtUser
 * @param optionsMap Key will be the option returned, value will be the permission code
 * @returns null if the user has all permissions, otherwise an array with the options the user has permission to
 */
export async function getOptionsWithPermissionFromJwtUser<T extends string | number | symbol>(
  jwtUser: JWTUser,
  optionsMap: Record<T, PermissionCode>
): Promise<T[] | null> {
  const allEntries = Object.entries(optionsMap) as [T, PermissionCode][];

  const promiseResult = await Promise.all(
    allEntries.map(async ([elem, p]) => ((await jwtUser.hasPermission(p)) ? [elem] : []))
  );

  const filteredEntries = promiseResult.flat();

  if (filteredEntries.length === allEntries.length) return null;

  return filteredEntries;
}
