import { FC } from "react";
import { Box } from "@mui/material";
import { SxProps } from "@mui/system";
import { AvatarSize } from "@/types/common";

export type StatusIndicatorCircleProps = {
  size?: AvatarSize;
  color?: string;
  showRing?: boolean;
  ringColor?: string;
};

const sizeStyles: Record<AvatarSize, SxProps> = {
  [AvatarSize.LARGE]: { width: 20, height: 20, right: 7, bottom: 7 },
  [AvatarSize.NORMAL]: { width: 10, height: 10, right: 0, bottom: 0 },
  [AvatarSize.MEDIUM]: { width: 7, height: 7, right: 2, bottom: 2 },
  [AvatarSize.SMALL]: { width: 5, height: 5, right: 1, bottom: 1 },
};

const ringSize: Record<AvatarSize, number> = {
  [AvatarSize.LARGE]: 2,
  [AvatarSize.NORMAL]: 1,
  [AvatarSize.MEDIUM]: 1,
  [AvatarSize.SMALL]: 0,
};

const StatusIndicatorCircle: FC<StatusIndicatorCircleProps> = ({
  size = AvatarSize.NORMAL,
  color = "error.main",
  showRing = true,
  ringColor = "white",
}) => {
  const sizeSx = sizeStyles[size];
  const ringSx = showRing
    ? {
        borderColor: ringColor,
        borderStyle: "solid",
        borderSize: ringSize[size] || 0,
      }
    : {};
  return (
    <Box
      sx={{
        borderRadius: "50%",
        position: "absolute",
        zIndex: 101,
        backgroundColor: color,
        ...sizeSx,
        ...ringSx,
      }}
    />
  );
};

export default StatusIndicatorCircle;
