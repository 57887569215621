import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { Environment, getEnvironment, env } from "@/config";

interface AppInformationProps {
  show?: boolean;
}

export const AppInformation: FC<AppInformationProps> = ({ show = true }) => {
  const environment = getEnvironment();

  if (environment === Environment.PRODUCTION || !show) {
    return null;
  }

  return (
    <Box>
      <Typography color="primary" sx={{ fontSize: 11 }}>
        Environment: {environment}
      </Typography>
      <Typography sx={{ fontSize: 9 }}>{env.commit}</Typography>
    </Box>
  );
};
