import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { UseFiltersState } from "./useFilters";

interface FiltersButtonProps {
  filters: UseFiltersState;
}

export const FiltersButton: FC<FiltersButtonProps> = ({ filters }) => {
  const { t } = useTranslation();

  const onClick = useCallback(() => {
    filters.setOpen(!filters.open);
  }, [filters.open]);

  return (
    <Badge color="secondary" badgeContent={filters.countFilterApplies}>
      <Button startIcon={<FilterAltIcon fontSize="small" />} variant="contained" color="primary" onClick={onClick}>
        {t("common.filters.buttonText")}
      </Button>
    </Badge>
  );
};
