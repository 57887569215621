import { RawTeam, TeamsByManager, listTeams, searchTeamsByManager } from "@/services/teamsService";
import { useEffect, useState } from "react";
import { usePager } from "./usePager";
import { TeamType } from "@/types/common";
import { compact, isNumber, merge, uniqBy } from "lodash";
import { usePrevious } from "./usePrevious";

const DEFAULT_PAGE_SIZE_CUSTOM = 25;

const DEFAULT_PAGE_SIZE_HIERARCHY = 75; // we use different pageSize because in hierarchy we can load all first page and render a tree navigation

interface UseTeamReturn {
  loading: boolean;
  teams: BothTeams[] | undefined;
  treeTeamsNavigation: TeamTreeNavigation[] | undefined;
  useTreeNavigation: boolean;
  goNextPage: () => void;
  stop: () => void;
}

type BothTeams = ManagerTeamCustom | ManagerTeamHierarchy;

export interface TeamTreeNavigation extends ManagerTeamHierarchy {
  children: TeamTreeNavigation[];
}

export enum TeamCategoryType {
  CUSTOM = "custom",
  HIERARCHY = "hierarchy",
}

interface ManagerTeam {
  teamName: string;
  type: TeamCategoryType;
  managerId: string;
}

export interface ManagerTeamCustom extends ManagerTeam {
  customTeamId: string;
  managerName: string;
  type: TeamCategoryType.CUSTOM;
}

export function isManagerTeamCustom(team: BothTeams): team is ManagerTeamCustom {
  return team.type === TeamCategoryType.CUSTOM;
}

export interface ManagerTeamHierarchy extends ManagerTeam {
  directReportTeamId: string;
  indirectReportTeamId: string;
  parentManagerId: string | undefined;
  type: TeamCategoryType.HIERARCHY;
}

function mapCustomTeamToManagerTeam(customTeam: RawTeam): ManagerTeamCustom {
  const [manager] = customTeam.managers;
  return {
    customTeamId: customTeam.id,
    teamName: customTeam.name,
    type: TeamCategoryType.CUSTOM,
    managerName: manager?.fullName || "",
    managerId: manager?.id,
  };
}

function mapTeamsByManagerToManagerTeam(teamByManager: TeamsByManager): ManagerTeamHierarchy {
  return {
    teamName: teamByManager.managerName,
    managerId: teamByManager.managerId,
    directReportTeamId: teamByManager.directReportTeamId,
    indirectReportTeamId: teamByManager.indirectReportTeamId,
    parentManagerId: teamByManager.parentManagerId,
    type: TeamCategoryType.HIERARCHY,
  };
}

function shouldUseTreeNavigation(category: TeamCategoryType, page: Number, isLastPage: boolean): boolean {
  return category === TeamCategoryType.HIERARCHY && page === 1 && isLastPage;
}

function createTreeNavigation(teams: ManagerTeamHierarchy[]): TeamTreeNavigation[] {
  const map: Record<string, number> = {};
  const newTeams: TeamTreeNavigation[] = [];
  const roots: TeamTreeNavigation[] = [];

  for (let i = 0; i < teams.length; i += 1) {
    if (teams[i].managerId) {
      map[`${teams[i].managerId}`] = i;
    }

    newTeams[i] = merge({}, teams[i], { children: [] as TeamTreeNavigation[] });
  }

  newTeams.forEach((node) => {
    if (!node.parentManagerId) {
      return roots.push(node);
    }

    const principalManagerIndex = map[`${node.parentManagerId}`];

    if (!isNumber(principalManagerIndex)) {
      return roots.push(node);
    }

    newTeams[principalManagerIndex].children?.push(node);
  });

  return roots;
}

export function useTeams(category: TeamCategoryType, search?: string, viewAs?: string): UseTeamReturn {
  const [loading, setLoading] = useState(true);

  const { page, restart, goNextPage, isLastPage, stop, isStop } = usePager();
  const [teams, setTeams] = useState<BothTeams[] | undefined>();
  const prevCategory = usePrevious(category);
  const prevSearch = usePrevious(search);
  const [fetchCounter, setFetchCounter] = useState(1);

  const loadHierarchyTeams = async () => {
    setLoading(true);
    const teams = await searchTeamsByManager({ search, viewAs, page, pageSize: DEFAULT_PAGE_SIZE_HIERARCHY });

    setTeams((t) => {
      const newTeams = uniqBy(
        compact([...(t || []), ...teams.elements.map((tm) => mapTeamsByManagerToManagerTeam(tm))]),
        "managerId"
      );

      if (isLastPage(teams)) {
        stop();
      }

      return newTeams;
    });

    setLoading(false);
  };

  const loadCustomTeams = async () => {
    setLoading(true);

    const customTeams = await listTeams({
      s: search,
      viewAs,
      page,
      type: TeamType.CUSTOM,
      pageSize: DEFAULT_PAGE_SIZE_CUSTOM,
    });

    setTeams((t) => {
      const newTeams = uniqBy(
        compact([...(t || []), ...customTeams.elements.map((tm) => mapCustomTeamToManagerTeam(tm))]),
        "customTeamId"
      );

      if (isLastPage(customTeams)) {
        stop();
      }

      return newTeams;
    });

    setLoading(false);
  };

  useEffect(() => {
    if (prevCategory !== category || search !== prevSearch) {
      setTeams(undefined);
      restart();
    }

    setFetchCounter((i) => i + 1);
  }, [category, page, search]);

  useEffect(() => {
    if (category === TeamCategoryType.HIERARCHY) {
      loadHierarchyTeams();
    }

    if (category === TeamCategoryType.CUSTOM) {
      loadCustomTeams();
    }
  }, [fetchCounter]);

  const useTreeNavigation = shouldUseTreeNavigation(category, page, isStop);

  const treeTeamsNavigation = useTreeNavigation ? createTreeNavigation(teams as ManagerTeamHierarchy[]) : undefined;

  return { loading, teams, treeTeamsNavigation, goNextPage, stop, useTreeNavigation };
}
