import { useEffect, useState } from "react";

export enum ClientStorageType {
  LOCAL_STORAGE = "localStorage",
  SESSION_STORAGE = "sessionStorage",
}

const useLocalStorageValue = <T>(key: string, initialValue: T, memoryType = ClientStorageType.LOCAL_STORAGE) => {
  const clientStorage = window[memoryType];

  const [value, setValue] = useState<T>(() => {
    try {
      const storedValue = clientStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : initialValue;
    } catch {
      return initialValue;
    }
  });

  useEffect(() => {
    clientStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue] as const;
};

export default useLocalStorageValue;
