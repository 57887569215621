import { SplitSdk } from "@splitsoftware/splitio-react";
import { splitConfigApiKey } from "@/config";
import { localSplitTreatments } from "@/featureFlags";

export function splitFactory(organization: string) {
  return SplitSdk({
    core: {
      authorizationKey: splitConfigApiKey,
      key: organization,
      trafficType: "organization",
    },
    debug: false,
    features: localSplitTreatments,
  });
}
