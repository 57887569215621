import { useEffect } from "react";
import type { FC } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { Box, Container, Divider, Grid, Tab, Tabs, Typography } from "@mui/material";
import UserAvatar from "@/components/UserAvatar";
import { useDispatch, useSelector } from "@/store";
import { loadEmployeeDetails, resetEmployeeDetails } from "../stores/employeeDetailSlice";
import { getEmployee } from "@/services/employeeService";
import EmployeeMetadata from "../components/layout/EmployeeMetadata";
import EmployeeSkeleton from "../components/layout/EmployeeSkeleton";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { AvatarSize } from "@/types/common";
import { useEmployeeFields } from "@/hooks/useEmployeeFields";

function extractPageFromPath(path: string) {
  const match = path.match(/employees\/(\d+)(\/.*)?/);
  if (!match) {
    return {
      employeeId: "0",
      subpath: "",
    };
  }

  const [, section] = match[2] ? match[2].split("/") : [];

  return {
    employeeId: match[1],
    subpath: match[2] === "/" || !match[2] ? "" : `/${section}`,
  };
}

const EmployeeLayout: FC = () => {
  const navigate = useNavigate();
  const employee = useSelector((s) => s.employeeDetail.employee);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const location = useLocation();
  const { findEmployeeField } = useEmployeeFields();
  const { employeeId, subpath } = extractPageFromPath(location.pathname);

  useEffect(() => {
    dispatch(resetEmployeeDetails());
    getEmployee(employeeId)
      .then((employee) => dispatch(loadEmployeeDetails(employee)))
      .catch((error: unknown) => {
        enqueueSnackbar(t("employee.detail.error.generic"), {
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
          variant: "error",
        });
        navigate("/");
        console.log(error);
      });

    return () => {
      dispatch(resetEmployeeDetails());
    };
  }, [employeeId]);

  if (!employee) {
    return <EmployeeSkeleton />;
  }

  const tabs = [
    { label: t("employee.detail.history.title"), subpath: "" },
    { label: t("employee.detail.organization.title"), subpath: "/org" },
    // { label: t("employee.detail.audit.title"), subpath: "/audit" },
  ];

  if (employee.directReports.length > 0) {
    tabs.push({
      label: t("employee.detail.team.title"),
      subpath: "/team",
    });
  }

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{ display: "flex", flexDirection: "column", height: "calc(100% - 30px)", mt: 1 }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexWrap: {
            xs: "wrap",
            md: "nowrap",
          },
          width: "fit-content",
          position: "relative",
        }}
      >
        <UserAvatar
          size={AvatarSize.LARGE}
          src={employee.avatar}
          name={employee.name}
          engagementScore={employee.engagementScore}
          leavingDate={employee.leavingDate}
          sx={{
            position: {
              xs: "relative",
              md: "absolute",
            },
            height: {
              xs: 80,
              md: 110,
            },
            width: {
              xs: 80,
              md: 110,
            },
            ml: {
              xs: 2,
              md: 1,
            },
            bottom: {
              xs: "none",
              md: -30,
            },
          }}
        />
        <Box
          sx={{
            my: 2,
            ml: {
              xs: 2,
              md: "130px",
            },
          }}
        >
          <Typography color="textPrimary" variant="h5">
            {employee.name}
          </Typography>

          {
            // Show job title if it is visible in the employee detail
            findEmployeeField("jobTitle")?.options.visibility.showInEmployeeDetail ? (
              <Typography
                color="textSecondary"
                sx={{ maxWidth: "200px", lineHeight: 1, display: "inline-block" }}
                variant="overline"
              >
                {employee.jobTitle}
              </Typography>
            ) : null
          }
        </Box>
        <Tabs
          sx={{ ml: 3, alignSelf: "flex-end", flexGrow: 1 }}
          indicatorColor="primary"
          scrollButtons="auto"
          textColor="primary"
          value={subpath}
          variant="scrollable"
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.subpath}
              label={tab.label}
              value={tab.subpath}
              onClick={() => navigate(`/employees/${employeeId}${tab.subpath}`)}
            />
          ))}
        </Tabs>
      </Box>
      <Divider />
      <Grid container spacing={2} flexWrap="wrap-reverse" sx={{ flexGrow: 1 }}>
        <Grid item xs={12} md={3}>
          <EmployeeMetadata employee={employee} />
        </Grid>
        <Grid item xs={12} md={9}>
          <Outlet />
        </Grid>
      </Grid>
    </Container>
  );
};

export default EmployeeLayout;
