import { OldRoleCode, User } from "@/types/user";
import { isArray } from "lodash";

/**
 * Returns true if the user has any of the roles passed as argument
 */
export function userHasRoles(user: User | null, roles: OldRoleCode | OldRoleCode[]): boolean {
  if (!user) return false;
  const rolesArray = isArray(roles) ? roles : [roles];
  return rolesArray.some((roleCode) => user.role.oldRoleCodeEquivalent === roleCode);
}
