import React, { FC, Fragment } from "react";
import { Grid, TextField, Autocomplete, IconButton, Divider } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { AutocompleteOption } from "@/components/InputAutocomplete";
import { useTranslation } from "react-i18next";
import { CustomFieldMapping, mapStringToAutocompleteOption } from "./integrations/steps/MapLaraFieldsStep";
interface CustomFieldProps {
  field: CustomFieldMapping;
  index: number;
  context: {
    headers: string[];
  };
  handleCustomFieldNameChange: (index: number, value: string) => void;
  handleCustomMappingChange: (index: number, value: AutocompleteOption | null) => void;
  handleRemoveCustomField: (index: number) => void;
}

const CustomField: FC<CustomFieldProps> = ({
  field,
  index,
  context,
  handleCustomFieldNameChange,
  handleCustomMappingChange,
  handleRemoveCustomField,
}) => {
  const { t } = useTranslation();
  const fieldMapping = field.value;
  const fieldName = field.key;

  const noneOption = {
    label: t("settings.integrationSettings.install.steps.mapFields.laraField_none"),
    value: "none",
  };

  const options = [noneOption, ...context.headers.map(mapStringToAutocompleteOption)];

  const value = fieldMapping ? mapStringToAutocompleteOption(fieldMapping) : noneOption;

  return (
    <Fragment key={index}>
      <Grid container>
        <Grid container item xs={6} sx={{ justifyContent: "center", flexDirection: "column" }}>
          <TextField
            value={fieldName}
            onChange={(event) => handleCustomFieldNameChange(index, event.target.value)}
            sx={{ mr: 3 }}
          />
        </Grid>
        <Grid container item xs={5} sx={{ alignItems: "center" }}>
          <Autocomplete
            filterSelectedOptions
            openOnFocus
            fullWidth
            isOptionEqualToValue={(option, value) => option.value === value.value}
            onChange={(_, value) => handleCustomMappingChange(index, value)}
            value={value}
            options={options}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  "& .MuiInputBase-input::placeholder": {
                    color: "black",
                  },
                }}
                placeholder={Array.isArray(value) && value.length === 0 ? noneOption.label : undefined}
              />
            )}
          />
        </Grid>
        <Grid container xs={1} sx={{ alignItems: "center" }}>
          <IconButton sx={{ ml: 3 }} onClick={() => handleRemoveCustomField(index)}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Divider />
    </Fragment>
  );
};

export default CustomField;
