import { Tooltip } from "@/components/Tooltip";
import useAuth from "@/hooks/useAuth";
import HelpIcon from "@mui/icons-material/Help";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import { Box, Chip, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TutorialFeature } from "@/components/TutorialFeature";
import { usePermission } from "@/hooks/usePermissions";

export enum ViewOrgScope {
  SHOW_SWITCH,
  NOTHING,
}
export function useViewOrgScope(): ViewOrgScope {
  const auth = useAuth();
  const { granted: viewOrgScope } = usePermission("reporting.scope.org:fetch");

  if (auth.user?.segmentReference === null) {
    return ViewOrgScope.NOTHING;
  }

  return viewOrgScope ? ViewOrgScope.SHOW_SWITCH : ViewOrgScope.NOTHING;
}

export const SwitchScope: FC<{ handleChangeValue: (value: boolean) => void; value: boolean }> = ({
  value,
  handleChangeValue,
}) => {
  const { t } = useTranslation();

  const handleOnClick = () => handleChangeValue(!value);
  const label = value ? t("reporting.viewOrgScope.switch.viewOrg") : t("reporting.viewOrgScope.switch.myEmployees");

  return (
    <TutorialFeature
      identify="report-filter-view-org"
      title={t("reporting.viewOrgScope.switch.feature.title")}
      body={t("reporting.viewOrgScope.switch.feature.body")}
      placement={"left"}
    >
      <Chip
        icon={<GroupWorkIcon />}
        color="primary"
        variant="outlined"
        label={
          <Tooltip title={t("reporting.viewOrgScope.switch.tooltip") || ""}>
            <Box display="flex">
              <Typography variant={"body2"}>{label}</Typography>
              <HelpIcon color="inherit" fontSize="small" sx={{ ml: 1 }} />
            </Box>
          </Tooltip>
        }
        onClick={handleOnClick}
      />
    </TutorialFeature>
  );
};
