export enum Environment {
  PRODUCTION = "production",
  STAGING = "preview",
  DEVELOPMENT = "development",
  TEST = "test",
}

export function isProd(): boolean {
  return process.env.REACT_APP_VERCEL_ENV === Environment.PRODUCTION;
}

function isStg(): boolean {
  return process.env.REACT_APP_VERCEL_ENV === Environment.STAGING;
}

export function isLocal(): boolean {
  return process.env.REACT_APP_VERCEL_ENV === Environment.DEVELOPMENT;
}

export function isTest(): boolean {
  return process.env.REACT_APP_VERCEL_ENV === Environment.TEST;
}

function configForEnv<T>(local: T, staging: T, production: T, test: T): T {
  if (isProd()) return production;
  if (isStg()) return staging;
  if (isLocal()) return local;
  if (isTest()) return test;
  throw new Error(`Missing configuration for env ${process.env.REACT_APP_VERCEL_ENV}`);
}

export function getEnvironment(): Environment {
  if (isProd()) return Environment.PRODUCTION;
  if (isStg()) return Environment.STAGING;
  if (isLocal()) return Environment.DEVELOPMENT;
  if (isTest()) return Environment.TEST;
  throw new Error(`Missing configuration for env ${process.env.REACT_APP_VERCEL_ENV}`);
}

export const gtmConfig = {
  containerId: configForEnv("", "", "GTM-K6JWSQH", ""),
};

export const mixPanelConfig = configForEnv(
  "a20b04af94e1bd8b2c6eb99800a58fe2",
  "a20b04af94e1bd8b2c6eb99800a58fe2",
  "1000837fb27190ccb833cfd4505bd7bb",
  ""
);

export const googleSSOConfig = configForEnv(
  "796736301953-7nhdi9kahojjko6kvhl37ripqs9ve3ma.apps.googleusercontent.com",
  "796736301953-7nhdi9kahojjko6kvhl37ripqs9ve3ma.apps.googleusercontent.com",
  "934688853265-r5iej7bvukm1705pl0nojaibe4k9jaig.apps.googleusercontent.com",
  ""
);

export const microsoftSSOConfig = configForEnv(
  "0bc37dd0-2e61-46f7-a224-473b570591e4",
  "0bc37dd0-2e61-46f7-a224-473b570591e4",
  "0bc37dd0-2e61-46f7-a224-473b570591e4",
  ""
);

export const baseUrl = {
  dashboardApi: configForEnv(
    "http://localhost:4000",
    "https://dashboard.meetlara.net",
    "https://dashboard.meetlara.ai",
    ""
  ),
};

export const slack = {
  oAuthURL: configForEnv(
    "https://localhost:3000/slack/oauth/redirect",
    "https://app.meetlara.net/slack/oauth/redirect",
    "https://app.meetlara.ai/slack/oauth/redirect",
    ""
  ),
  clientId: configForEnv(
    "2045867451408.2307057122640",
    "2238421014949.2881652654355",
    "2238421014949.2400504686818",
    ""
  ),
};

export const splitConfigApiKey = configForEnv(
  "localhost", // Edit in featureFlags.ts
  "kel0svk36bfanfpv6bg7q2f90htke2a2sjkn",
  "iactjmv50behv2r8vh60jtkhp19phh95cfuq",
  ""
);

export const env = {
  env: process.env.REACT_APP_VERCEL_ENV,
  isProd: isProd(),
  commit: process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA || "development",
  addMockRequests: false,
  jwtPublicKey: configForEnv(
    '{"kty":"RSA","n":"pmsYNSKkGFqU3TTmqRniZ7spL97ArlPprwm5CAUlFPsdhvxmxLhPZEDMfPEEzFYce5OBGMEs1sDSMlPgpz1gJefoHNE2udcl7Z4UeZ7VeIANq588Ns_l42ywMkjaaBZbNfVj9d4qkIoNRrzc3lwif-FMWTsj05pSABuf0pEVdxbddF9UxnrNcYNTQ7V7pHl3rdBD3lYXxYEQXrb8DmCbfx47FVphb9iezQI8KNvnR8bG9Hgc8UHscd4qJjZEhcKIef2ovNRZltHGC_h_u3-33OOCRHr6cQ5pHY_AP34Gq12LOvWXKLuWWbGj1pfNx3EzoWH7qPe3TELKSz4_KSNUykPYImBvcNj76QBYpf1dUhTRiWkWA8U3xHS48xsBek7ZG9yKiTCDOSJFsJilRl6wMikZD44RBB1hLEL2itX_eGStVMGsUaCN-blR-JY5s2CFvPx7wZHrmAtBj4Q72U3dDF1qsf4EjKsKNkp_5E3Wu8U1nJG-JnSEcQThpSutqs-wuJ_5KxVhOebkl76tUhJ8iMYahSCV5RuOwiLXnlGokFztsMgft0y6ogFK3q8U8Lh-zi6iaCF6rS9XK40pcOqh2tK3tVwZKzJq5GNxzfs3jWwZINSA-zygB0dywibtQ0w5SCWVNu4MauOV1FZelJ5v3_5FVgq36hXtj0w8axz6x5U","e":"AQAB"}',
    '{"kty": "RSA","n": "m4TL9zZgAOAs-i90A-eHuk7Q6a9B_Hy1SgsXgorostvzGmASJkBw1KBQnMcP9b3RwswAN2gjXYUDs4Ki1nttfQnXqjOCZ8KuI8mv2swJm9p_OXOq331knzewQA-QJ1dMPkWUfcIMKgIL3uo7pY8xp_A9Jxh0l1_pdtjA64H_k1YS-azF4X4116QT62KRgvjKAGfHTkzh2ECXVIm-ETXk79yDFAYrauuAIdQAf_BJ9Tlga-fF__O-ULW71FfpSDs4vYBJEzLO7-bOd_X_YFh-GnIue0qaQ2Ij65z3kVPaKm_euAbvdHMd6wsphR0zEdLOL9TsqAM0N0DzDlogQj_aEITh9mhgUIiaZ1G9Fn5v6ldpnMD9lcXl1MoORhJQdjozOA4M0fi5aCwe9FTtOgU04eKvzjJ_IpIXOTJuHSn-NblRluTRjOK1qcHQShYM5DiPEUlmi3l7hUgSOUkIppe19gZk9Fv6b7ouUxQg1kfwnzNoHQEUJHAPMQ2ldAN6k4Rkx2BBTD9QLbi3vhDijuyxpCSV7r4yLHi747-1mh7M6iyqxb6VxFMd15iGS65Sn3phEcAnAGJKTCDyHt8gO_58qrsYV9ftfQJTmtxbTJulEd-Z05oVfnJGe5kk9kVvXU-xtLDe862gghBdI7Hkwkz_2SgFS4tsNFP9xkgjS-eCpFk","e": "AQAB"}',
    '{"kty":"RSA","n":"5uH_AeOnmuJHdDpYapKPMdmFso-woyiHcGWscmB7gSu6tCzDqhqTAq-tlF19vfxooQ3LO3fJFDk3aPmlfgwKNGQ75zww4QEMBBx16EXT6Dp_Qz-12pR5bPssw9E_hQQIbs17HyLaQ50eSgJGM-Iiz5Jz4wbJfavWrVGiLA1yMrmU5GcolOrUKb9DgWrEuIsPCgMWZFth4S4vR8cBVMZU1lt67PTXqglDgyZ53pEiNj9JdDmFBANykaENTZZRWujiwhWQcPE6mpzkxhtPlR1s4zTIKsCHI-a290yFfBGx_4qOJtq-NO1a-2s0CbAJAjAb88_iSGabS7bYxzHWj7vVHgfSoAOU1BXwq0CUcX4lwB1-0AdByc03RuIgFTyc5vu_TWDgy4dx1MmWpVqCYDfhE2WSGPrmWqToEE0vytdbvLqRKL7HYB5JT4mnRg5j4fPXRt7zNJuCroNPjWtaSluuNJFEM5i4MrcqxZjrxKmWkSNIkUTf1VNUHK6fE9IbUGaYUwpb1LZ0txuuvYoGBlGIvQFa-nyYuOZBFNAYH8HEPYMvN0swPrKWtU5rU2bfdnXNULEscaFjPo4Tt48gZx5n_NQ97EtrdJE5xy0nHhRRxotk4FzYEP6YLoBlM0xE5gVGC_pW_HbwLE8t04nJkSE7bTlRsMoudPsnwHORTz_IM6s","e":"AQAB"}',
    ""
  ),
};

export const APP_NAME = "dashboard";
