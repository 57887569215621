import { Configuration, PopupRequest } from "@azure/msal-browser";

import { microsoftSSOConfig } from "./config";

export const msalConfig: Configuration = {
  auth: {
    clientId: microsoftSSOConfig,
    authority: "https://login.microsoftonline.com/common",
    redirectUri: "/",
    postLogoutRedirectUri: "/",
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: ["User.Read"],
};
