import { isNil, reduce } from "lodash";

const DEFAULT_SELECT_VALUE = "all";

export type SanitizeParamsType = Record<string, string | number | boolean | string[]>;
export function sanitizeParamsService(params: SanitizeParamsType): SanitizeParamsType {
  return reduce(
    params,
    (result, value, key) => {
      if (value !== DEFAULT_SELECT_VALUE && !isNil(value)) {
        result[key] = value;
      }

      return result;
    },
    {}
  );
}
