import { env } from "@/config";
import { importJWK, jwtVerify } from "jose";
import { JWTUser, JWTPayload } from "@alanszp/jwt";

const JWT_VERIFICATION_ALGORITHM = "RS512";

async function publicKey() {
  return importJWK(JSON.parse(env.jwtPublicKey), JWT_VERIFICATION_ALGORITHM);
}

export async function verifyToken(token: string): Promise<JWTUser> {
  const verify = await jwtVerify(token, await publicKey(), {
    issuer: "hodor",
    audience: "web",
    algorithms: [JWT_VERIFICATION_ALGORITHM],
  });
  const payload = verify.payload as JWTPayload;
  return JWTUser.fromPayload(payload);
}

export async function verifyPartialToken(token: string): Promise<JWTUser> {
  const verify = await jwtVerify(token, await publicKey(), {
    issuer: "partial",
    audience: "login",
    algorithms: [JWT_VERIFICATION_ALGORITHM],
  });
  const payload: JWTPayload = verify.payload as JWTPayload;
  return JWTUser.fromPayload(payload);
}
