import React from "react";
import { FC, ReactNode, useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { Box, Container, Grid, Typography, Theme, ButtonProps, Breakpoint, Link, Skeleton } from "@mui/material";
import { SxProps } from "@mui/system";
import { UseFiltersState } from "./filters/useFilters";
import { FiltersContainer } from "./FiltersContainer";
import { Filters } from "./filters/Filters";
import { FiltersButton } from "./filters/FilterButton";
import { PageTrack, useTrackPageView } from "@/lib/track";

export interface PageButton extends ButtonProps {
  label: string;
}

export interface PageProps {
  children: ReactNode;
  locales: PageLocales;
  preButton?: ReactNode;
  postButton?: ReactNode;
  containerWidth?: false | Breakpoint;
  outerSx?: SxProps<Theme>;
  innerSx?: SxProps<Theme>;
  buttons?: ReactNode[];
  buttonsRightFilter?: ReactNode[];
  hideTitle?: boolean;
  filters?: UseFiltersState;
  track: PageTrack;
  skeleton?: boolean;
}

export interface PageLocales {
  /**
   * The title of the page, used in page header
   */
  title?: string;
  /**
   * The subtitle of the page, used in page header
   */
  subtitle?: string;
  /**
   * The link to the wiki page of the page (if any). Used in the page header after the subtitle
   */
  wiki?: {
    title: string;
    url: string;
  };
  /**
   * The title of the page, used in the <title> tag
   */
  headTitle: string;
}

const Page: FC<PageProps> = (props) => {
  const {
    containerWidth = "xxl",
    outerSx = {},
    innerSx = {},
    preButton,
    postButton,
    children,
    locales,
    buttons = [],
    buttonsRightFilter = [],
    hideTitle = false,
    filters,
    track,
    skeleton = false,
  } = props;

  useEffect(() => {
    if (filters && filters.countFilterApplies > 0) {
      filters.setOpen(true);
    }
  }, []);

  useTrackPageView(track);

  return (
    <>
      <Helmet>
        <title>{locales.headTitle}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          pt: hideTitle ? 0 : 4,
          pb: 8,
          ...outerSx,
        }}
      >
        <Container maxWidth={containerWidth}>
          {hideTitle ? null : (
            <>
              <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
                <Grid item>
                  {skeleton ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      {preButton}
                      <Skeleton>
                        <Typography color="textPrimary" variant="h4">
                          {locales.title ?? "placeholder title"}
                        </Typography>
                      </Skeleton>
                      {postButton}
                    </Box>
                  ) : (
                    <Typography color="textPrimary" variant="h5">
                      {preButton} {locales.title} {postButton}
                    </Typography>
                  )}
                </Grid>
                <Grid item display="flex">
                  {buttons.map((button, i) => (
                    <React.Fragment key={`page-buttons-${i}`}>{button}</React.Fragment>
                  ))}

                  {filters && (
                    <Box sx={{ ml: 1, mr: 1 }}>
                      <FiltersButton filters={filters} />
                    </Box>
                  )}

                  {buttonsRightFilter.map((button, i) => (
                    <React.Fragment key={`page-buttons-right-filter-${i}`}>{button}</React.Fragment>
                  ))}
                </Grid>
              </Grid>
              {(skeleton || Boolean(locales.subtitle) || Boolean(locales.wiki)) && (
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  mt={{
                    xs: 2,
                    sm: filters || buttons.length > 0 ? 2 : 1,
                  }}
                >
                  <Grid item>
                    {skeleton ? (
                      <Skeleton>
                        <Typography color="textPrimary" variant="body1">
                          {locales.subtitle ?? "some large subtitle to be displayed as skeleton"}
                          {locales.wiki?.title ?? ""}
                        </Typography>
                      </Skeleton>
                    ) : (
                      <Typography color="textPrimary" variant="body1">
                        {locales.subtitle}{" "}
                        {locales.wiki && (
                          <Link underline="always" variant="body1" target="_blank" href={locales.wiki.url}>
                            {locales.wiki.title}
                          </Link>
                        )}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              )}
            </>
          )}

          {!skeleton && filters && (
            <Box sx={{ mt: 3, ...innerSx }}>
              <FiltersContainer open={filters.open}>
                <Filters filters={filters} />
              </FiltersContainer>
            </Box>
          )}
          <Box sx={{ mt: 3, ...innerSx }}>{children}</Box>
        </Container>
      </Box>
    </>
  );
};

Page.propTypes = {
  children: PropTypes.node,
};

export default Page;
