import { trackEvent } from "@/lib/track";

export function trackOpenEmployeeSearchBar() {
  trackEvent("header.openEmployeeSearch");
}

export function trackEmployeeSearchBarInputText() {
  trackEvent("header.employeeSearchBarInputText");
}

export function trackEmployeeSearchSubmit() {
  trackEvent("header.employeeSearchBarSubmit");
}

export function trackHelpOpenMenu() {
  trackEvent("header.helpOpenMenu");
}

export function trackHelpWiki() {
  trackEvent("header.helpWiki");
}

export function trackHelpContact() {
  trackEvent("header.helpContact");
}
