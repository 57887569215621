import { MouseEventHandler, useState, useEffect } from "react";
import type { FC, ReactNode } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { Collapse, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import type { ListItemProps } from "@mui/material";
import ChevronDownIcon from "@/icons/ChevronDown";
import ChevronRightIcon from "@/icons/ChevronRight";
import { SIDEBAR } from "src/theme/layout";
import { matchPath } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { NavSectionItem } from "./NavSection";
import { InferablePermissionCheck, usePermissionStrategy } from "@/hooks/usePermissions";

interface NavItemProps extends ListItemProps {
  children?: ReactNode;
  depth: number;
  icon?: ReactNode;
  info?: ReactNode;
  path?: string;
  isSubpath?: boolean;
  label: (t: TFunction) => string;
  pathname?: string;
  collapsed: boolean;
  childrenNavItems?: NavSectionItem[];
  adornment?: (t: TFunction) => JSX.Element;
  permissions: InferablePermissionCheck | undefined;
}

const pathMatch = (partial: boolean, pathname?: string, path?: string): boolean => {
  if (!path || !pathname) {
    return false;
  }

  const isExactMatch = !!matchPath(
    {
      path: path,
      end: !partial || path === "/",
    },
    pathname
  );

  const isPartialMatch = path !== "/" && pathname.includes(path);

  return isExactMatch || isPartialMatch;
};

const NavItem: FC<NavItemProps> = (props) => {
  const {
    children,
    childrenNavItems,
    depth,
    icon,
    path,
    label,
    pathname,
    isSubpath = false,
    collapsed,
    adornment,
    permissions,
  } = props;
  const { t } = useTranslation();
  const match = pathMatch(isSubpath || !!children, pathname, path);
  const [open, setOpen] = useState<boolean>(match);
  const [active, setActive] = useState<boolean>(match);

  useEffect(() => {
    const newMatch = pathMatch(!!children, pathname, path);
    const hasActiveChildren =
      childrenNavItems?.some((childrenItem) => pathMatch(false, pathname, childrenItem.path)) ?? false;
    const isActive = newMatch || hasActiveChildren;
    setOpen(isActive);
    setActive(isActive);
  }, [children, pathname, path, isSubpath]);

  const handleToggle: MouseEventHandler<any> = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 16;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const { granted } = usePermissionStrategy(permissions ?? { strategy: "deny" });

  if (permissions && !granted) {
    return null;
  }

  // Branch
  if (children) {
    return (
      <ListItem
        onClick={handleToggle}
        button
        disableGutters
        disableRipple
        sx={{
          display: "flex",
          py: 0,
          color: "text.secondary",
          width: SIDEBAR.width,
          flexWrap: "wrap",
          overflowX: "hidden",
          ...(active && {
            color: "primary.main",
            fontWeight: "fontWeightBold",
            "& > svg": {
              color: "primary.main",
            },
          }),
        }}
      >
        <ListItemIcon
          sx={{
            color: active ? "primary.main" : "text.secondary",
            pl: `${paddingLeft}px`,
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          sx={{
            fontWeight: "fontWeightMedium",
            color: "text.secondary",
            pr: "8px",
            pl: "10px",
            py: "12px",
            textAlign: "left",
            textTransform: "none",
            width: "100%",
            overflowX: "hidden",
            flexBasis: "130px",
          }}
        >
          <Typography
            style={{
              width: SIDEBAR.width,
            }}
          >
            {label(t)}
          </Typography>
        </ListItemText>
        {adornment?.(t) ?? null}
        <ListItemIcon
          sx={{
            alignSelf: "right",
            color: "text.secondary",
            pl: `${paddingLeft}px`,
          }}
        >
          {open ? <ChevronDownIcon fontSize="small" /> : <ChevronRightIcon fontSize="small" />}
        </ListItemIcon>
        <Collapse
          sx={{
            flexBasis: SIDEBAR.width,
          }}
          in={open && !collapsed}
        >
          {children}
        </Collapse>
      </ListItem>
    );
  }

  // Leaf
  return (
    <ListItem
      button
      component={(path && RouterLink) as any}
      to={path}
      disableGutters
      sx={{
        display: "flex",
        color: "text.secondary",
        py: 0,
        ...(active && {
          color: "primary.main",
          fontWeight: "fontWeightBold",
          "& svg": {
            color: "primary.main",
          },
        }),
      }}
    >
      <ListItemIcon
        sx={{
          color: "text.secondary",
          pl: `${paddingLeft}px`,
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText
        disableTypography={true}
        sx={{
          fontWeight: "fontWeightMedium",
          textAlign: "left",
          pr: "8px",
          pl: "10px",
          py: "12px",
          textTransform: "none",
          width: "100%",
          overflowX: "hidden",
        }}
      >
        <Stack
          direction={"row"}
          columnGap={1}
          sx={{
            width: SIDEBAR.width,
          }}
        >
          <Typography>{label(t)}</Typography>
          {adornment?.(t) ?? null}
        </Stack>
      </ListItemText>
    </ListItem>
  );
};

export default NavItem;
