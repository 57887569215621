import { FC } from "react";
import { ErrorBox } from "@/components/ErrorBox";
import { Box, Grid } from "@mui/material";
import { InferablePermissionCheck, usePermissionStrategy } from "@/hooks/usePermissions";
import LoadingScreen from "@/modules/skeleton/components/LoadingScreen";
import { PermissionServiceError } from "@alanszp/jwt/dist/errors/PermissionServiceError";
import Forbidden from "@/pages/Forbidden";

const PermissionErrorState: FC<{ error: unknown }> = ({ error }) => {
  const reload = () => {
    document.location.reload();
  };

  const message = error instanceof PermissionServiceError ? error.message : undefined;

  return (
    <Grid container alignContent={"center"} justifyContent={"center"} height="100%">
      <Grid item m={2}>
        <img alt="error state" width={300} src={"/static/error/undraw_qa_engineers.svg"} />
        <Box sx={{ mt: 2 }}>
          <ErrorBox onRetry={() => reload()} error={message} />
        </Box>
      </Grid>
    </Grid>
  );
};

type PermissionGuardProps = InferablePermissionCheck & {
  children: React.ReactNode;
};

export const PermissionGuard: FC<PermissionGuardProps> = ({ children, ...strategy }) => {
  const { render } = usePermissionStrategy(strategy);

  return (
    <>
      {render(
        () => (
          <LoadingScreen />
        ),
        (error: unknown) => (
          <PermissionErrorState error={error} />
        ),
        (granted) => {
          if (!granted) {
            return <Forbidden />;
          }
          return <>{children}</>;
        }
      )}
    </>
  );
};
