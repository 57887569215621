import { Box } from "@mui/material";

export enum IconPathType {
  LEFT_BOTTOM = "leftBottom",
  BOTTOM = "bottom",
}

interface IconPathProps {
  type: IconPathType;
}

export const IconPath = ({ type }: IconPathProps) => {
  if (type === IconPathType.BOTTOM) {
    return (
      <Box>
        <Box sx={{ top: 30, left: 27, position: "absolute", width: 20, height: 20, borderLeft: "1px solid #ccc" }} />
      </Box>
    );
  }

  if (type === IconPathType.LEFT_BOTTOM) {
    return (
      <Box>
        <Box sx={{ top: 30, left: 27, position: "absolute", width: 20, height: 20, borderLeft: "1px solid #ccc" }} />
        <Box sx={{ top: 50, left: 27, position: "absolute", width: 20, height: 20, borderTop: "1px solid #ccc" }} />
      </Box>
    );
  }

  return null;
};
