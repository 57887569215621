import { MetricDataOrder } from "@/modules/board/models/metricDataSource";
import { Report } from "@/services/reportService";
import { orderBy } from "lodash";

export function generateOrderByFn(
  orderObject: MetricDataOrder
): (object: Record<string, string | number>) => string | number {
  return function orderByFn(object) {
    const { key, asNumber, nullsFirst } = orderObject;
    let value = object.hasOwnProperty(key) ? object[key] : null;

    if (value === null) {
      if (nullsFirst) {
        return -Infinity;
      } else {
        return Infinity;
      }
    }

    if ((asNumber ?? true) && value !== "") {
      const numericValue = Number(value);
      if (isFinite(numericValue)) {
        value = numericValue;
      }
    }

    return value;
  };
}

export function orderReport(report: Report, orderByDefinition: MetricDataOrder[]): Report {
  return {
    headers: report.headers,
    data: orderBy(
      report.data,
      orderByDefinition.map((order) => generateOrderByFn(order)),
      orderByDefinition.map((order) => order.direction.toLowerCase() as "asc" | "desc")
    ),
  };
}
