import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { FC } from "react";

interface ProfileItemInfoProps {
  fontSize: number;
  translation: string;
  value: any;
  Icon?: any;
}

export const ProfileItemInfo: FC<ProfileItemInfoProps> = ({ fontSize, translation, value, Icon }) => {
  return (
    <Box alignItems="center" sx={{ display: "flex", alignItems: "center", alignContent: "center" }}>
      {Icon && <Icon color="primary" sx={{ fontSize }} />}
      <Typography color="text.secondary" variant="body2" display="inline" fontSize={fontSize} style={{ marginLeft: 2 }}>
        {`${translation}: `}
      </Typography>
      <Typography
        variant="body2"
        display="inline"
        fontWeight="fontWeightMedium"
        fontSize={fontSize}
        style={{ marginLeft: 4 }}
      >
        {value}
      </Typography>
    </Box>
  );
};
