import { useEffect, useRef, useState } from "react";

interface UseIsTextOverflowing {
  isTextOverflowing: boolean;
  ref: React.RefObject<HTMLElement>;
}

function isContentBiggerThanContainer(e: HTMLElement) {
  return e.offsetWidth < e.scrollWidth;
}

export const useIsTextOverflowing = (): UseIsTextOverflowing => {
  const ref = useRef<HTMLElement>(null);
  const [isTextOverflowing, setIsTextOverflowing] = useState(false);

  useEffect(() => {
    if (ref.current) {
      const isOverflowing = isContentBiggerThanContainer(ref.current);
      setIsTextOverflowing(isOverflowing);
    }
  }, [ref.current, ref.current?.offsetWidth, ref.current?.scrollWidth]);

  return { isTextOverflowing, ref };
};
