import { IconButton, InputAdornment, TextField } from "@mui/material";
import { FC } from "react";
import DatePicker, { DatePickerProps } from "@mui/lab/DatePicker";
import { createTargetEvent } from "@/utils/createTargetEvent";
import { useTranslation } from "react-i18next";
import { isValid, parseISO } from "date-fns";
import ClearIcon from "@mui/icons-material/Clear";

export type InputDateProps = Omit<DatePickerProps<string>, "onChange" | "value" | "renderInput"> &
  React.RefAttributes<HTMLDivElement> & {
    name: string;
    value: string | null;
    onChange: (event: { target: { name: string; value: unknown } }, value: string | null) => void;
  };

export const InputDate: FC<InputDateProps> = ({ onChange, name, value, maxDate, minDate, ...props }) => {
  const { t } = useTranslation();

  function handleOnChange(value) {
    if (!isValid(value)) return;
    const stringValue = value.toISOString().substring(0, 10);
    onChange(createTargetEvent(name, stringValue), stringValue);
  }

  function resetDate() {
    onChange(createTargetEvent(name, null), null);
  }

  return (
    <DatePicker
      {...props}
      maxDate={maxDate ? parseISO(maxDate) : undefined}
      minDate={minDate ? parseISO(minDate) : undefined}
      inputFormat={t("common.dates.shortDate")}
      value={value ? parseISO(value) : null}
      onChange={handleOnChange}
      renderInput={({ InputProps, ...params }) => {
        return (
          <TextField
            {...params}
            sx={{ width: { xs: "100%", md: value ? 190 : 155 } }}
            InputProps={{
              endAdornment: (
                <>
                  {value && (
                    <InputAdornment position="end">
                      <IconButton onClick={resetDate} edge="end" size="medium">
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  )}
                  {InputProps?.endAdornment}
                </>
              ),
            }}
          />
        );
      }}
    />
  );
};
