import { isEnumValue } from "@/components/advanced-filters/utils";
import { GroupByOption } from "@/components/group-by/useGroupBy";
import { EmployeeFieldTranslationGetter } from "@/hooks/useEmployeeFields";
import { commonGroupByOptionLocale } from "@/utils/localizeConstants";
import { TFunction } from "i18next";

export const mapToOption = <T extends string>(
  t: TFunction,
  getEmployeeFieldTranslation: EmployeeFieldTranslationGetter,
  option: T
) => ({
  label: getEmployeeFieldTranslation(
    option, // If is an employee field, translate it with the employee field definition, else use the common group by local or the option itself
    isEnumValue(GroupByOption, option) ? commonGroupByOptionLocale(t, option as GroupByOption) : option
  ),
  value: option,
});
