import { FC } from "react";
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material/styles";
import { SxProps } from "@mui/system";

interface CircularProgressProps {
  value: number;
  sx?: SxProps;
  strokeSize?: number;
  color?: string;
}

const CircularProgressRoot = styled("div")({
  height: 56,
  width: 56,
  background: "transparent",
  border: 0,
});

const CircularProgressBackground = styled("path")(() => ({
  fill: "none",
  stroke: "rgb(0, 0, 0, 0.7)",
  strokeLinecap: "round",
}));

const CircularProgressValue = styled("path")(({ theme }) => ({
  animation: "$progress 1s ease-out forwards",
  fill: "none",
  stroke: theme.palette.primary.main,
  strokeLinecap: "round",
  "@keyframes progress": {
    "0%": {
      strokeDasharray: "0 100",
    },
  },
}));

const CircularProgress: FC<CircularProgressProps> = (props) => {
  const { value, sx, strokeSize = 2, color, ...other } = props;
  const theme = useTheme();

  return (
    <CircularProgressRoot sx={sx} {...other}>
      <svg viewBox="0 0 36 36">
        <filter id="dropshadow" x="-2" y="-2" width="400" height="400">
          <feGaussianBlur stdDeviation="3" />
        </filter>
        <CircularProgressBackground
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          strokeDasharray={`${value + 3}, 100`}
          strokeWidth={strokeSize + 1}
          style={{ filter: "url(#dropshadow)" }}
        />
        <CircularProgressValue
          d="M18 2.0845 a 15.9155 15.9155 0  0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          strokeDasharray={`${value}, 100`}
          strokeWidth={strokeSize}
          sx={{ stroke: color || (theme.palette.primary.main as string) }}
        />
      </svg>
    </CircularProgressRoot>
  );
};

CircularProgress.propTypes = {
  value: PropTypes.number.isRequired,
};

export default CircularProgress;
