import { trackEvent } from "@/lib/track";
import { MetricCompareType } from "../components/MetricCompareSelector";

export function trackChangeReportType(pageName: string, type) {
  trackEvent(`${pageName}.changeTabReports`, { type });
}

export function trackOpenDriver(pageName, driver: string) {
  trackEvent(`${pageName}.openDrive`, { driver });
}

export function trackCloseDriver(pageName) {
  trackEvent(`${pageName}.closeDrive`);
}

export function trackGoToDriverQuestions(pageName: string, driver: string) {
  trackEvent(`${pageName}.goToDriverQuestions`, { driver });
}

export function trackTeamNavigationOpenSelector(pageName: string) {
  trackEvent(`${pageName}.teamNavigationOpenSelector`);
}

export function trackTeamNavigationCloseSelector(pageName: string) {
  trackEvent(`${pageName}.teamNavigationCloseSelector`);
}

export function trackTeamNavigationSelectTeam(pageName: string) {
  trackEvent(`${pageName}.teamNavigationSelectTeam`);
}

export function trackTeamStatusToggle(pageName: string, open: boolean) {
  trackEvent(`${pageName}.teamStatusToggle`, {
    open,
  });
}

export function trackCompareType(pageName: string, compareType: MetricCompareType) {
  trackEvent(`${pageName}.teamChangeCompareType`, {
    compareType,
  });
}

export function trackEditTeamSave(status: "success" | "error") {
  trackEvent(`editTem.save`, { status });
}

export function trackCreateTeam(status: "success" | "error") {
  trackEvent(`createTeam.save`, { status });
}

export function trackEditOpenMemberDialog() {
  trackEvent(`editTem.openMemberDialog`);
}

export function trackEditCloseMemberDialog() {
  trackEvent(`editTem.openMemberDialog`);
}

export function trackEditAddMember() {
  trackEvent(`editTem.addMember`);
}

export function trackEditRemoveMember() {
  trackEvent(`editTem.removeMember`);
}
