import type { FC } from "react";
import { Theme, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import type { SxProps } from "@mui/system";
import { ThemeName } from "@/types/constants";
import { LogoColor } from "@/theme/logo";

interface LogoProps {
  theme?: LogoColor;
  sx?: SxProps<Theme>;
}

const LogoRoot = styled("svg")({});

const Logo: FC<LogoProps> = ({ theme, ...props }) => {
  const generalTheme = useTheme();

  return (
    <LogoRoot width="52px" height="52px" viewBox="0 0 100 100" version="1.1" {...props}>
      <title>Lara</title>
      <polygon
        points="49.987 25.589 70.832 69.561 84.727 69.561 51.65 0.5 48.54 0.5 15.25 69.561 29.143 69.561 49.987 25.589"
        fill={theme || (generalTheme.palette.mode === ThemeName.LIGHT ? LogoColor.BLUE : LogoColor.WHITE)}
      />
      <path
        d="M 68.453 81.376 C 64.119 84.99 57.395 87.359 49.987 87.359 C 42.582 87.359 35.855 84.99 31.523 81.376 L 15.25 81.376 C 20.376 91.931 34.004 99.5 49.987 99.5 C 65.972 99.5 79.601 91.935 84.727 81.376 Z"
        fill={LogoColor.LIGHT_BLUE}
      />
    </LogoRoot>
  );
};

export default Logo;
