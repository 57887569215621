import { Box, IconButton, Skeleton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { FC } from "react";
import { ProfileAvatar } from "../profile-avatar/ProfileAvatar";

interface EmployeeActionableProps {
  handleRemoveMember: (employeeId: string) => void;
  employee: {
    id: string;
    fullName: string;
    avatar?: string;
    jobTitle?: string;
  };
  editable: boolean;
}

export const EmployeeActionableSkeleton: FC = () => {
  return (
    <Box
      sx={{
        width: { xs: "100%", sm: "49%" },
        background: "#f5f5f5",
        p: 2,
        borderRadius: 2,
        mb: "2%",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "flex-start",
          overflowX: "hidden",
        }}
      >
        <Box>
          <Skeleton animation="wave" variant="circular" width={40} height={40} sx={{ mr: 2 }} />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Skeleton animation="wave" height={10} width="100%" style={{ marginBottom: 6 }} />
          <Skeleton animation="wave" height={10} width="100%" style={{ marginBottom: 6 }} />
        </Box>
      </Box>
    </Box>
  );
};

export const EmployeeActionable: FC<EmployeeActionableProps> = ({ employee, handleRemoveMember, editable }) => (
  <Box
    sx={{
      display: "flex",
      width: { xs: "100%", sm: "49%" },
      background: "#f5f5f5",
      p: 2,
      borderRadius: 2,
      justifyContent: "space-between",
      alignItems: "center",
      mb: "2%",
    }}
  >
    <ProfileAvatar
      user={{
        name: employee.fullName,
      }}
      hideArrow={true}
    />
    {editable && (
      <Box sx={{ ml: 2, mt: -0.5 }}>
        <IconButton onClick={() => handleRemoveMember(employee.id)}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Box>
    )}
  </Box>
);
