import { Popper } from "@mui/material";
import { styled } from "@mui/system";

function defineTranslationPopper(placement?: string): string {
  const defaultTranslate = "0em 0em";
  if (!placement) {
    return defaultTranslate;
  }

  if (placement.startsWith("top")) {
    return "0em -.3em";
  }

  if (placement.startsWith("bottom")) {
    return "0em .3em";
  }

  if (placement.startsWith("right")) {
    return ".3em 0em";
  }

  if (placement.startsWith("left")) {
    return "-.3em 0em";
  }

  return defaultTranslate;
}

const StyledPopper = styled(Popper)(({ theme, placement }) => ({
  zIndex: 102,
  translate: defineTranslationPopper(placement),
}));

export const StyledPopperCard = ({ anchorEl, open, placement, children }) => {
  return (
    <StyledPopper disablePortal={true} open={open} anchorEl={anchorEl} placement={placement}>
      {children}
    </StyledPopper>
  );
};
