import useAuth from "@/hooks/useAuth";
import { useCurrentLanguage } from "@/hooks/useCurrentLanguage";
import { DriversWithSubDrivers, fetchDrivers } from "@/services/driverService";
import { FC, createContext, useEffect, useState } from "react";

interface DriversContextValue {
  drivers: DriversWithSubDrivers[];
  loading: boolean;
  error: unknown | null;
}

export const DriversContext = createContext<DriversContextValue>({
  drivers: [],
  loading: true,
  error: null,
});

export const DriversProvider: FC = ({ children }) => {
  const [drivers, setDrivers] = useState<DriversWithSubDrivers[]>([]);
  const [awaitingFetchDrivers, setAwaitingFetchDrivers] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | null>();
  const auth = useAuth();
  const locale = useCurrentLanguage();

  useEffect(() => {
    if (awaitingFetchDrivers) return;

    setLoading(true);
    setError(null);

    if (!auth.isAuthenticated || !auth.organizationReference) {
      setDrivers([]);
      return;
    }

    const loadDrivers = async () => {
      try {
        setAwaitingFetchDrivers(true);
        const driversResponse = await fetchDrivers(auth.organizationReference, locale);
        setError(null);
        setDrivers(driversResponse);
      } catch (err: unknown) {
        setDrivers([]);
        setError(err);
      } finally {
        setAwaitingFetchDrivers(false);
        setLoading(false);
      }
    };

    loadDrivers();
  }, [auth.organizationReference, locale]);

  return <DriversContext.Provider value={{ drivers, loading, error }}>{children}</DriversContext.Provider>;
};
