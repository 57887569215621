import { createTargetEvent } from "@/utils/createTargetEvent";
import { debounce, TextField, TextFieldProps } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";

export type SearchInputProps = TextFieldProps & {
  name: string;
  value: string;
  onChange: (event: { target: { name: string; value: string } }, value: string | null) => void;
  inputProps?: TextFieldProps["InputProps"];
};

export const SearchInput: FC<SearchInputProps> = ({ onChange, name, value, label, inputProps, ...props }) => {
  const [localValue, setLocalValue] = useState("");

  useEffect(() => {
    setLocalValue(value);
  }, []);

  const updateSearch = useCallback(
    debounce((nextValue: string) => {
      onChange(createTargetEvent(name, nextValue), nextValue);
    }, 200),
    []
  );

  function handleChange(event) {
    const nextValue = event.target.value;
    setLocalValue(nextValue);
    updateSearch(nextValue);
  }

  const finalValue = localValue !== value ? localValue : value;

  return (
    <TextField
      sx={{ width: "100%" }}
      {...props}
      name={name}
      label={label}
      onChange={handleChange}
      value={finalValue}
      type="search"
      InputProps={inputProps}
    />
  );
};
