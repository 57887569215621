import type { FC } from "react";
import { Box, Theme, useMediaQuery } from "@mui/material";
import LogoSpinner from "@/svgs/LogoSpinner";
import Logo from "@/svgs/Logo";

const SlashScreen: FC = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "background.paper",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        left: 0,
        p: 3,
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 2000,
      }}
    >
      {isMobile ? <Logo /> : <LogoSpinner />}
    </Box>
  );
};

export default SlashScreen;
