import { FC } from "react";
import { Box, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { SelectInput } from "../SelectInput";
import { InputDate } from "../InputDate";
import { FilterType, FilterValues, FormikHandleChangeType } from "./useFilters";
import { FilterConfiguration, HiddenFilterConfiguration } from "./FilterConfiguration";
import { InputAutocomplete } from "../InputAutocomplete";
import { SelectTeamInputNew } from "../assign-user/SelectTeamInputNew";
import { CheckboxInput } from "../CheckboxInput";
import { valueFromFunctionOrValue } from "../FunctionOrValue";
import { mapValues } from "lodash";
import { SearchInput } from "../SearchInput";
import { InputAutocompleteEmployee } from "../InputAutocompleteEmployee";
import { SelectSeverity } from "../SelectSeverity";
import { SelectDriverInput } from "@/components/assign-user/SelectDriverInput";

const MapTypeComponent: Record<FilterType, any> = {
  [FilterType.HIDDEN]: () => null,
  [FilterType.SELECT]: (props) => <SelectInput {...props} fullWidth />,
  [FilterType.AUTOCOMPLETE]: InputAutocomplete,
  [FilterType.DATE]: InputDate,
  [FilterType.TEAM]: SelectTeamInputNew,
  [FilterType.SELECT_DRIVER]: SelectDriverInput,
  [FilterType.CHECKBOX]: CheckboxInput,
  [FilterType.SEARCH]: SearchInput,
  [FilterType.SELECT_EMPLOYEE]: InputAutocompleteEmployee,
  [FilterType.SELECT_SEVERITY]: SelectSeverity,
};

const mapToFieldComponent = (filterType: FilterType) => {
  return MapTypeComponent[filterType];
};

interface ComponentFilterProps {
  filter: Exclude<FilterConfiguration, HiddenFilterConfiguration>;
  handleChange: FormikHandleChangeType;
  values: FilterValues;
  boxStyle: SxProps<Theme>;
}

export const FilterBuilder: FC<ComponentFilterProps> = ({ filter, handleChange, values, boxStyle }) => {
  const BuildComponent = mapToFieldComponent(filter.type);

  if (valueFromFunctionOrValue(filter.visible, values) === false) return null;

  const fields = filter.fieldConfig
    ? mapValues(filter.fieldConfig, (val) => valueFromFunctionOrValue(val, values))
    : {};

  return (
    <Box sx={boxStyle}>
      <BuildComponent
        {...fields}
        name={filter.name}
        id={filter.id || filter.name}
        label={filter.label}
        onChange={handleChange}
        value={(values[filter.name] as string) ?? ""}
      />
    </Box>
  );
};
