import { FC, useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useAuth from "@/hooks/useAuth";
import { InputAutocompleteUser } from "@/components/InputAutocompleteUser";
import { useSnackbar } from "notistack";

interface ImpersonateModalProps {
  open: boolean;
  onClose: () => void;
}

export const ImpersonateModal: FC<ImpersonateModalProps> = ({ open, onClose }) => {
  const { isImpersonating, jwtUser, impersonate } = useAuth();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedUserId, setSelectedUserId] = useState<string | null>(isImpersonating && jwtUser ? jwtUser.id : null);

  function handleClose() {
    onClose();
  }

  function confirmImpersonation() {
    if (!selectedUserId) return;

    onClose();
    impersonate(selectedUserId).catch((error) => {
      console.error("[Impersonate Modal] Failed to impersonate", error);
      enqueueSnackbar(t("navbar.impersonateModal.impersonateFailed"), {
        anchorOrigin: {
          horizontal: "left",
          vertical: "bottom",
        },
        variant: "error",
      });
    });
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="impersonate-modal-title"
      aria-describedby="impersonate-modal-title"
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          maxWidth: 900,
          maxHeight: "85vh",
          boxShadow: 24,
          p: 4,
          overflowY: "scroll",
          display: "flex",
        }}
      >
        <Typography color="textPrimary" variant="h5" id="impersonate-modal-title">
          {t("navbar.impersonateModal.title")}
        </Typography>
        <Typography color="textPrimary" variant="body1" id="impersonate-modal-title">
          {t("navbar.impersonateModal.explain")}
        </Typography>
        <InputAutocompleteUser
          name="user"
          label={t("navbar.impersonateModal.userList.selector")}
          disabledIds={jwtUser?.originalId ? [jwtUser.originalId] : []}
          onChange={({ target }) => setSelectedUserId((target.value ?? null) as string | null)}
          value={selectedUserId}
        />
        <Button
          sx={{
            float: "right",
          }}
          variant="contained"
          disabled={!selectedUserId}
          onClick={confirmImpersonation}
        >
          {t("navbar.impersonateModal.userList.confirmSelection")}
        </Button>
      </Box>
    </Modal>
  );
};
