import { useEffect } from "react";
import type { FC } from "react";
import NProgress from "nprogress";
import { Box } from "@mui/material";
import Logo from "@/svgs/Logo";

const LoadingScreen: FC = () => {
  useEffect(() => {
    NProgress.start();

    return (): void => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "transparent",
        height: "100%",
        left: 0,
        p: 3,
        position: "fixed",
        top: 0,
        width: "100%",
      }}
    >
      <Logo sx={{ animation: "breathing 3s ease-out infinite" }} />
      <style>
        {`
        @keyframes breathing {
          0% {
            -webkit-transform: scale(0.9);
            -ms-transform: scale(0.9);
            transform: scale(0.9);
          }

          25% {
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            transform: scale(1);
          }

          60% {
            -webkit-transform: scale(0.9);
            -ms-transform: scale(0.9);
            transform: scale(0.9);
          }

          100% {
            -webkit-transform: scale(0.9);
            -ms-transform: scale(0.9);
            transform: scale(0.9);
          }
        }`}
      </style>
    </Box>
  );
};

export default LoadingScreen;
