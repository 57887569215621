import { trackEvent } from "@/lib/track";
import { User } from "@/types/user";

export function trackLogin(user: User) {
  trackEvent("login", {
    role: user.role,
    organization: user.organizationReference,
    email: user.email,
    user: user.id,
    employeeReference: user.employeeReference,
  });
}
