export function createTargetEvent<T>(
  name: string,
  value: T
): {
  target: { name: string; value: T };
} {
  return {
    target: { name, value },
  };
}
