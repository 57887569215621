export type Lang = "en" | "es" | "pt";

export interface Language {
  language: Lang;
  label: string;
  icon: string;
}

export const AvailableLanguages: Language[] = [
  {
    language: "en",
    label: "English",
    icon: "/static/icons/us_flag.svg",
  },
  {
    language: "es",
    label: "Español",
    icon: "/static/icons/es_flag.svg",
  },
  {
    language: "pt",
    label: "Português",
    icon: "/static/icons/br_flag.svg",
  },
];
