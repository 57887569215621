import { searchParamExists } from "./url";

const NOW_PARAM = "now";

export function reloadPage() {
  const { location } = window;
  const now = new Date().getTime();
  const updateLocation = `${location.href}${location.href.includes("?") ? "&" : "?"}${NOW_PARAM}=${now}`;

  document.location = updateLocation;
}

export function hasNowInParams() {
  return searchParamExists(NOW_PARAM);
}
