import { Typography } from "@mui/material";
import { ComponentProps, FC } from "react";

const Kbd: FC<ComponentProps<typeof Typography>> = ({ children, ...props }) => {
  return (
    <Typography
      component="kbd"
      fontSize={10}
      sx={{
        fontFamily: "monospace",
        borderColor: (theme) => theme.palette.divider,
        borderStyle: "solid",
        borderWidth: "1px 1px 2px",
        backgroundColor: (theme) => theme.palette.grey[200],
        borderRadius: "0.25rem",
        px: 0.5,
        py: 0.1,
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};

export default Kbd;
