import { useRef, useState } from "react";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Divider, IconButton, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from "@mui/material";
import { AvailableLanguages } from "@/translations/AvailableLanguages";
import { groupBy, mapValues } from "lodash";

const languageOptions = mapValues(groupBy(AvailableLanguages, "language"), (v) => v[0]);

const LanguagePopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { i18n, t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleChangeLanguage = (language: string): void => {
    i18n.changeLanguage(language);
    setOpen(false);
  };

  if (!i18n.language || i18n.language === "cimode") {
    return <> </>;
  }

  const selectedOption = languageOptions[i18n.language.slice(0, 2)];
  return (
    <>
      <IconButton ref={anchorRef} onClick={handleOpen}>
        <Typography color="white" variant="subtitle2">
          {selectedOption.language.toUpperCase()}
        </Typography>
      </IconButton>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {t("navbar.accountPopover.list.changeLanguage")}
          </Typography>
        </Box>
        <Divider />
        {Object.keys(languageOptions).map((language) => (
          <MenuItem onClick={() => handleChangeLanguage(language)} key={language}>
            <ListItemIcon>
              <Box
                sx={{
                  display: "flex",
                  height: 20,
                  width: 20,
                  "& img": {
                    width: "100%",
                  },
                }}
              >
                <img alt={languageOptions[language].label} src={languageOptions[language].icon} />
              </Box>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  {languageOptions[language].label}
                </Typography>
              }
            />
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default LanguagePopover;
