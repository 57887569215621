import { Lang } from "@/translations/AvailableLanguages";
import { useTranslation } from "react-i18next";

export const AVAILABLE_LANGUAGES: Lang[] = ["es", "en", "pt"];

function parseSafeLanguage(locale: string): string {
  if (AVAILABLE_LANGUAGES.includes(locale as Lang)) {
    return locale;
  }

  const find = AVAILABLE_LANGUAGES.find((l) => locale.startsWith(l));

  if (find) {
    return find;
  }

  return locale;
}

export function useCurrentLanguage(): string {
  const { i18n } = useTranslation();

  return parseSafeLanguage(i18n.language);
}
