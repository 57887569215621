import UserAvatar from "@/components/UserAvatar";
import EmployeeMetadataSkeleton from "@/modules/employee/components/layout/EmployeeMetadataSkeleton";
import { AvatarSize } from "@/types/common";
import { Box, Container, Divider, Grid, Skeleton, Typography } from "@mui/material";
import { FC } from "react";

const EmployeeSkeleton: FC = () => {
  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100% - 10px)",
        mt: 1,
        overflow: "hidden", // So that the scroll bar is not visible.
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexWrap: {
            xs: "wrap",
            md: "nowrap",
          },
          width: "fit-content",
          position: "relative",
        }}
      >
        <UserAvatar
          skeleton
          size={AvatarSize.LARGE}
          name={""}
          sx={{
            width: { xs: 80, md: 110 },
            height: { xs: 80, md: 110 },
            position: { xs: "relative", md: "absolute" },
            ml: { xs: 2, md: 1 },
            bottom: { xs: "none", md: -30 },
          }}
        />

        <Box sx={{ my: 2, ml: { xs: 2, md: "130px" } }}>
          <Skeleton variant="text">
            <Typography variant="h5">Employee Name</Typography>
          </Skeleton>
          <Skeleton>
            <Typography sx={{ maxWidth: "200px", lineHeight: 1, display: "inline-block" }} variant="overline">
              Employee's Job title
            </Typography>
          </Skeleton>
        </Box>
      </Box>
      <Divider />
      <Grid container spacing={2} flexWrap="wrap-reverse" sx={{ flexGrow: 1 }}>
        <Grid item xs={12} md={3}>
          <EmployeeMetadataSkeleton />
        </Grid>
        <Grid container item xs={12} md={9} px={3}>
          <Grid container item mt={1} ml={1} spacing={2}>
            <Grid item xs={12} md={6} height={"60vh"}>
              <Skeleton variant="rectangular" height={"100%"} sx={{ borderRadius: 1 }} />
            </Grid>
            <Grid item xs={12} md={6} height={"60vh"}>
              <Skeleton variant="rectangular" height={"100%"} sx={{ borderRadius: 1 }} />
            </Grid>
            <Grid item xs={12} height={"20vh"}>
              <Skeleton variant="rectangular" height={"100%"} sx={{ borderRadius: 1 }} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EmployeeSkeleton;
