import { FormikErrors, useFormik } from "formik";

type ReturnType<T extends (...args: any[]) => any> = T extends (...args: any[]) => infer R ? R : never;

export interface MaterialUIFieldProps {
  id: string;
  name: string;
  onChange: any;
  onBlur: any;
  value: string;
  error: boolean;
  helperText: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  disabled?: boolean;
  placeholder?: string;
  label?: string;
  visible?: boolean;
}

export type MaterialUIFieldPropsKeys = keyof MaterialUIFieldProps;

export function useFormikWithTranslations(formik: ReturnType<typeof useFormik>) {
  function formikField(fieldName: string): MaterialUIFieldProps;
  function formikField(fieldName: string, omitFields?: MaterialUIFieldPropsKeys[]): Partial<MaterialUIFieldProps>;
  function formikField(
    fieldName: string,
    omitFields?: MaterialUIFieldPropsKeys[]
  ): Partial<MaterialUIFieldProps> | MaterialUIFieldProps {
    const props = {
      id: fieldName,
      name: fieldName,
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      value: formik.values[fieldName],
      error: Boolean(formik.touched[fieldName] && formik.errors[fieldName]),
      helperText: (formik.touched[fieldName] && formik.errors[fieldName]) as
        | string
        | FormikErrors<any>
        | string[]
        | FormikErrors<any>[],
    };

    if (!omitFields) {
      return props;
    }

    omitFields.forEach((k) => {
      delete props[k];
    });
    return props;
  }

  return formikField;
}
