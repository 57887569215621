import { FC, ReactNode } from "react";
import { List, ListSubheader } from "@mui/material";
import type { ListProps } from "@mui/material";
import NavItem from "./NavItem";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { OldRoleCode } from "@/types/user";
import useAuth from "@/hooks/useAuth";
import { SplitTreatment } from "@/hooks/useSplitTreatment";
import { InferablePermissionCheck } from "@/hooks/usePermissions";
import { JWTUser } from "@alanszp/jwt";

export interface NavSectionItem {
  path?: string;
  icon?: ReactNode;
  info?: ReactNode;
  children?: NavSectionItem[];
  label: (t: TFunction) => string;
  onPress?: () => void;
  name: string;
  adornment?: (t: TFunction) => JSX.Element;
  /**
   * Show the item only if some of the configured criteria are met
   */
  showRules?: {
    /**
     * Show the item only if the user has some of the specified roles
     */
    roles?: OldRoleCode[];
    /**
     * Show the item only if the user has the specified treatments
     * If rules are specified, the value of the rule will be used to check the treatment
     */
    treatments?: {
      values: SplitTreatment[];
      rules?: boolean[];
    };
    /**
     * Show the item only if the user has the specified permissions
     * Permission check strategy can be configured
     */
    permissions?: InferablePermissionCheck;
  };
}

interface NavSectionProps extends ListProps {
  items: NavSectionItem[];
  pathname: string;
  label?: (t: TFunction) => string;
  collapsed: boolean;
}

const renderNavItems = ({
  jwtUser,
  depth = 0,
  items,
  pathname,
  collapsed,
}: {
  jwtUser: JWTUser;
  items: NavSectionItem[];
  pathname: string;
  depth?: number;
  collapsed: boolean;
}): JSX.Element => (
  <List disablePadding>
    {items
      .filter((item) => {
        return !item.showRules || !item.showRules.roles || jwtUser.hasRoles(item.showRules.roles);
      })
      .reduce(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
        (acc, item, index) =>
          reduceChildRoutes({
            jwtUser,
            acc,
            item,
            pathname,
            depth,
            index,
            collapsed,
          }),
        []
      )}
  </List>
);

const reduceChildRoutes = ({
  jwtUser,
  acc,
  pathname,
  item,
  depth,
  index,
  collapsed,
}: {
  jwtUser: JWTUser;
  acc: JSX.Element[];
  pathname: string;
  item: NavSectionItem;
  depth: number;
  index: number;
  collapsed: boolean;
}): Array<JSX.Element> => {
  const key = `${item.name}`;

  if (item.children) {
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        path={item.path}
        label={item.label}
        pathname={pathname}
        collapsed={collapsed}
        childrenNavItems={item.children}
        adornment={item.adornment}
        permissions={item.showRules?.permissions}
      >
        {renderNavItems({
          jwtUser,
          depth: depth + 1,
          items: item.children,
          pathname,
          collapsed,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        path={item.path}
        label={item.label}
        pathname={pathname}
        collapsed={collapsed}
        adornment={item.adornment}
        permissions={item.showRules?.permissions}
      />
    );
  }

  return acc;
};

const NavSection: FC<NavSectionProps> = (props) => {
  const { jwtUser } = useAuth();
  const { t } = useTranslation();
  const { items, pathname, label, collapsed, ...other } = props;

  return (
    <List
      subheader={
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: "text.primary",
            fontSize: "0.75rem",
            lineHeight: 2.5,
            fontWeight: 700,
            textTransform: "uppercase",
            transition: (theme) =>
              theme.transitions.create(["height"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
          }}
        >
          {label && !collapsed ? label(t) : ""}
        </ListSubheader>
      }
      {...other}
    >
      {jwtUser &&
        renderNavItems({
          jwtUser,
          items,
          pathname,
          collapsed,
        })}
    </List>
  );
};

export default NavSection;
