import { Box, Link, Typography } from "@mui/material";
import UserAvatar from "../UserAvatar";
import { Link as RouterLink } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTranslation } from "react-i18next";
import isFormerEmployee from "@/utils/isFormerEmployee";
import { AvatarSize } from "@/types/common";
import { FC, useRef } from "react";
import { ProfileSummary } from "./ProfileSummary";

export interface User {
  employeeReference?: string | null;
  name?: string;
  firstName?: string;
  lastName?: string;
  avatar?: string;
  jobTitle?: string;
}

interface ProfileAvatarProps {
  user: User;
  size?: AvatarSize;
  link?: string;
  engagementScore?: number;
  hideName?: boolean;
  hideArrow?: boolean;
  principalManager?: string;
  profileSummary?: boolean;
  leavingDate?: string;
  subText?: string | JSX.Element;
  mr?: number;
}

export const ProfileAvatar: FC<ProfileAvatarProps> = ({
  user,
  link,
  engagementScore,
  size = AvatarSize.NORMAL,
  hideName = false,
  hideArrow = false,
  profileSummary = false,
  principalManager,
  leavingDate,
  subText,
  mr = 1,
}) => {
  if (!user.name && !user.firstName && !user.lastName) {
    console.warn("Profile avatar without name", JSON.stringify(user));
  }
  const { t } = useTranslation();
  const fontSize = size === AvatarSize.SMALL ? 12 : 14;
  const anchorEl = useRef<HTMLElement>();

  const formerEmployee = isFormerEmployee(leavingDate);

  const avatarAndText = (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-start",
        overflowX: "hidden",
      }}
      ref={anchorEl}
    >
      <Box>
        <UserAvatar
          size={size}
          src={user.avatar}
          name={user.name || `${user.firstName} ${user.lastName}`}
          sx={{ mr }}
          engagementScore={engagementScore}
          nameOnTooltip={hideName && !profileSummary}
          leavingDate={leavingDate}
        />
      </Box>
      {!hideName && (
        <Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography fontWeight={"bold"} fontSize={fontSize} color={formerEmployee ? "textSecondary" : undefined}>
              {user.name || `${user.firstName} ${user.lastName}`}
            </Typography>
            {!hideArrow && (
              <ArrowForwardIcon sx={{ ml: 0.1, fontSize: "13px" }} color={formerEmployee ? "disabled" : "inherit"} />
            )}
          </Box>

          {!profileSummary && (
            <>
              {user.jobTitle && (
                <Typography fontSize={fontSize} color={formerEmployee ? "textSecondary" : undefined}>
                  {user.jobTitle}
                </Typography>
              )}
              {principalManager && (
                <Typography fontSize={10}>
                  {t("common.userRole.role_manager")}: {principalManager}
                </Typography>
              )}
            </>
          )}
          {subText && <Typography fontSize={10}>{subText}</Typography>}
        </Box>
      )}
    </Box>
  );

  return (
    <>
      {profileSummary && user.employeeReference && (
        <ProfileSummary anchorEl={anchorEl} employeeReference={user.employeeReference} size={size} mr={mr} />
      )}
      {link ? (
        <Link color="inherit" underline="hover" to={link} component={RouterLink}>
          {avatarAndText}
        </Link>
      ) : (
        avatarAndText
      )}
    </>
  );
};

export const ProfileAvatarAnonymous: FC<{ size?: AvatarSize; hideName?: boolean; subText?: string | JSX.Element }> = ({
  hideName = false,
  subText,
  size = AvatarSize.NORMAL,
}) => {
  const { t } = useTranslation();
  const fontSize = size === AvatarSize.SMALL ? 12 : 14;
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-start",
        overflowX: "hidden",
      }}
    >
      <Box>
        <UserAvatar size={size} src={"/static/icons/user.svg"} name={t("feedback.anonymous")} sx={{ mr: 1 }} />
      </Box>
      {!hideName && (
        <Box>
          <Typography fontWeight={"bold"} fontSize={fontSize}>
            {t("feedback.anonymous")}
          </Typography>
          {subText && <Typography fontSize={10}>{subText}</Typography>}
        </Box>
      )}
    </Box>
  );
};
