import { FC } from "react";
import { useDeepCompareMemo } from "use-deep-compare";
import { FilterBuilder } from "./FilterBuilder";
import { FilterType, UseFiltersState } from "./useFilters";

interface FiltersProps {
  filters: UseFiltersState;
}

export const Filters: FC<FiltersProps> = ({ filters }) => {
  return useDeepCompareMemo(() => {
    return (
      <>
        {filters.filters.map((filter, index) => {
          const margin = index !== filters.filters.length - 1 ? 2 : 0;

          if (filter.type === FilterType.HIDDEN) return null;

          return (
            <FilterBuilder
              key={filter.name}
              filter={filter}
              handleChange={filters.handleChange}
              values={filters.values}
              boxStyle={{
                minWidth: 120,
                mr: { md: margin },
                mb: { md: margin },
                width: { xs: "100%", md: filter.minWidth || "inherit" },
              }}
            />
          );
        })}
      </>
    );
  }, [filters]);
};
