import { useRef, useState } from "react";
import type { FC } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material";
import useAuth from "@/hooks/useAuth";
import CogIcon from "@/icons/Cog";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import UserAvatar from "@/components/UserAvatar";
import { useTranslation } from "react-i18next";
import OrganizationSwitcher from "@/components/OrganizationSwitcher";
import { AppInformation } from "@/components/AppInformation";
import { ImpersonateModal } from "@/modules/skeleton/components/navbar/ImpersonateModal";
import { usePermission } from "@/hooks/usePermissions";
import { PermissionCode } from "@/types/PermissionCode";

const AccountPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { user, logout, isImpersonating, stopImpersonating } = useAuth();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [impersonateModalOpen, setImpersonateModalOpen] = useState<boolean>(false);
  const { granted: hasImpersonatePermission } = usePermission(PermissionCode.USER_IMPERSONATE);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      navigate("/authentication/login");
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Unable to logout", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
    }
  };

  function handleImpersonateClick() {
    handleClose();
    setImpersonateModalOpen(true);
  }

  function handleStopImpersonatingClick() {
    handleClose();
    stopImpersonating().catch((error) => {
      console.error("[Impersonate Modal] Failed to stop impersonating", error);
      enqueueSnackbar(t("navbar.impersonateModal.stopImpersonationFailed"), {
        anchorOrigin: {
          horizontal: "left",
          vertical: "bottom",
        },
        variant: "error",
      });
    });
  }

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        <UserAvatar
          src={user?.avatar}
          name={user?.fullName || ""}
          sx={{
            height: 32,
            width: 32,
          }}
        />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {user?.firstName} {user?.lastName}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          <MenuItem component={RouterLink} to="/settings">
            <ListItemIcon sx={{ mr: 0, my: 1 }}>
              <CogIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              sx={{ textAlign: "left" }}
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  {t("navbar.accountPopover.list.settings")}
                </Typography>
              }
            />
          </MenuItem>
        </Box>

        {isImpersonating ? (
          <Box sx={{ mt: 0 }}>
            <MenuItem onClick={handleStopImpersonatingClick}>
              <ListItemIcon sx={{ mr: 0, my: 1 }}>
                <SupervisedUserCircleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                sx={{ textAlign: "left" }}
                primary={
                  <Typography color="textPrimary" variant="subtitle2">
                    {t("navbar.accountPopover.list.stopImpersonating")}
                  </Typography>
                }
              />
            </MenuItem>
          </Box>
        ) : null}

        {!isImpersonating && hasImpersonatePermission ? (
          <Box sx={{ mt: 0 }}>
            <MenuItem onClick={handleImpersonateClick}>
              <ListItemIcon sx={{ mr: 0, my: 1 }}>
                <SupervisedUserCircleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                sx={{ textAlign: "left" }}
                primary={
                  <Typography color="textPrimary" variant="subtitle2">
                    {t("navbar.accountPopover.list.impersonate")}
                  </Typography>
                }
              />
            </MenuItem>
          </Box>
        ) : null}

        <Box sx={{ mt: 1, px: 2 }}>
          <OrganizationSwitcher onSwitchOrganizationShortcut={handleOpen} />
        </Box>

        <Box sx={{ mt: 2, px: 2 }}>
          <AppInformation />
        </Box>

        <Divider sx={{ mt: 2 }} />

        <Box sx={{ p: 2 }}>
          <Button color="primary" fullWidth onClick={handleLogout} variant="outlined">
            {t("navbar.accountPopover.list.logout")}
          </Button>
        </Box>
      </Popover>

      {!isImpersonating && hasImpersonatePermission && (
        <ImpersonateModal open={impersonateModalOpen} onClose={() => setImpersonateModalOpen(false)} />
      )}
    </>
  );
};

export default AccountPopover;
