import { isArray, isNil, isNumber, isString, isFinite, isEmpty, isBoolean, isObject } from "lodash";
import {
  MetricDataFilterLogicalOperator,
  MetricDataFilter,
  MetricDataFilterDefinition,
  MetricDataFilterValueSimpleType,
  MetricDataFilterOperator,
  MetricDataFilterValueType,
} from "@/modules/board/models/metricDataSource";

const MetricDataFilterOperatorValues = Object.values(MetricDataFilterOperator);

function isMetricDataFilterValueSimpleType(
  value: MetricDataFilterValueSimpleType
): value is MetricDataFilterValueSimpleType {
  return isString(value) || isNumber(value) || isBoolean(value);
}

function isMetricDataFilterValueType(value: MetricDataFilterValueType): value is MetricDataFilterValueType {
  if (isArray(value)) {
    return value.every((v) => isMetricDataFilterValueSimpleType(v));
  }
  return isMetricDataFilterValueSimpleType(value);
}

function isNonEmptyString(value: unknown): value is string {
  return isString(value) && !isEmpty(value);
}

function isMetricDataFilterDefinition(valueGiven: MetricDataFilter): valueGiven is MetricDataFilterDefinition {
  const value = valueGiven as MetricDataFilterDefinition;
  return (
    isObject(value) &&
    isNonEmptyString(value.field) &&
    MetricDataFilterOperatorValues.includes(value.operator) &&
    (value.value === undefined || isMetricDataFilterValueType(value.value))
  );
}

export function isMetricDataFilter(value: MetricDataFilter): boolean {
  return isObject(value) && (isMetricDataFilterDefinition(value) || isLogicalUnitedFilters(value));
}

function isLogicalUnitedFilters(valueGiven: MetricDataFilter): valueGiven is {
  operator: MetricDataFilterLogicalOperator;
  filters: MetricDataFilter[];
} {
  const value = valueGiven as {
    operator: MetricDataFilterLogicalOperator;
    filters: MetricDataFilter[];
  };
  return (
    isObject(value) &&
    Object.values(MetricDataFilterLogicalOperator).includes(value.operator) &&
    isArray(value.filters) &&
    value.filters.every((f) => isMetricDataFilter(f))
  );
}

function inOperator(value: unknown, filter: MetricDataFilterDefinition): boolean {
  return isArray(filter.value)
    ? filter.value.includes(value as MetricDataFilterValueSimpleType)
    : (filter.value as MetricDataFilterValueSimpleType) === value;
}

function isEmptyOperator(value: unknown): boolean {
  return !isBoolean(value) && !isNumber(value) && isEmpty(value);
}

function unknownToNumber(value: unknown): number | null {
  if (isNumber(value)) {
    return value;
  }

  if (isString(value) && value !== "") {
    const number = Number(value);
    if (isFinite(number)) {
      return number;
    }
  }

  return null;
}

function numberOperations(left: unknown, right: unknown, operator: (left: number, right: number) => boolean): boolean {
  const numericLeft = unknownToNumber(left);
  const numericRight = unknownToNumber(right);
  if (numericLeft === null || numericRight === null) return false;
  return operator(numericLeft, numericRight);
}

const FILTER_OPERATOR_FNS: Record<
  MetricDataFilterOperator,
  (value: unknown, filter: MetricDataFilterDefinition) => boolean
> = {
  [MetricDataFilterOperator.IS_EMPTY]: isEmptyOperator,
  [MetricDataFilterOperator.IS_NOT_EMPTY]: (value) => !isEmptyOperator(value),
  [MetricDataFilterOperator.IS_NULL]: (value) => isNil(value),
  [MetricDataFilterOperator.IS_NOT_NULL]: (value) => !isNil(value),
  [MetricDataFilterOperator.EQ]: inOperator,
  [MetricDataFilterOperator.NOT_EQ]: (value, filter) => !inOperator(value, filter),
  [MetricDataFilterOperator.IN]: inOperator,
  [MetricDataFilterOperator.NOT_IN]: (value, filter) => !inOperator(value, filter),
  [MetricDataFilterOperator.GREATER_THAN]: (value, filter) => numberOperations(value, filter.value, (l, r) => l > r),
  [MetricDataFilterOperator.LOWER_THAN]: (value, filter) => numberOperations(value, filter.value, (l, r) => l < r),
  [MetricDataFilterOperator.GREATER_THAN_OR_EQUAL]: (value, filter) =>
    numberOperations(value, filter.value, (l, r) => l >= r),
  [MetricDataFilterOperator.LOWER_THAN_OR_EQUAL]: (value, filter) =>
    numberOperations(value, filter.value, (l, r) => l <= r),
};

function failWithUnknownFilter(filter: unknown): never {
  console.error("[FilterHelper] Unknown filter:", filter);
  throw new Error(`Unknown filter ${JSON.stringify(filter)}`);
}

export class FilterHelper {
  #filter: MetricDataFilter;

  constructor(filter: MetricDataFilter) {
    if (!isMetricDataFilter(filter)) {
      failWithUnknownFilter(filter);
    }

    this.#filter = filter;
  }

  public filterRows<T extends Record<string, unknown>>(rows: T[]): T[] {
    const filter = this.#filter;
    if (isNil(filter)) return rows;
    return rows.filter((row) => this.filterRow(row, filter));
  }

  private filterRow<T extends Record<string, unknown>>(row: T, filter: MetricDataFilter): boolean {
    if (filter.operator === MetricDataFilterLogicalOperator.AND) {
      return filter.filters.every((f) => this.filterRow(row, f));
    }
    if (filter.operator === MetricDataFilterLogicalOperator.OR) {
      return filter.filters.some((f) => this.filterRow(row, f));
    }
    if (MetricDataFilterOperatorValues.includes(filter.operator)) {
      return this.filterRowWithFilter(row, filter as MetricDataFilterDefinition);
    }

    failWithUnknownFilter(filter);
  }

  public filterRowWithFilter<T extends Record<string, unknown>>(row: T, filter: MetricDataFilterDefinition): boolean {
    const value = row[filter.field];
    try {
      return FILTER_OPERATOR_FNS[filter.operator]?.(value, filter) ?? false;
    } catch (error: unknown) {
      return false;
    }
  }
}
