import { ElementType, FC } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@/components/Tooltip";
import { Avatar, AvatarProps, AvatarTypeMap, Box, Skeleton, Theme, useTheme } from "@mui/material";
import InitialsAvatar from "./InitialsAvatar";
import merge from "lodash/merge";
import CircularProgress from "./CircularProgress";
import { SxProps } from "@mui/system";
import { useTranslation } from "react-i18next";
import { laraScoreColor } from "@/theme/laraScoreColor";
import StatusIndicatorCircle from "./StatusIndicatorCircle";
import isFormerEmployee from "@/utils/isFormerEmployee";
import { AvatarSize } from "@/types/common";

const avatarSizeStyles = {
  [AvatarSize.LARGE]: { width: 60, height: 60, strokeSize: 2, fontSize: "30px" },
  [AvatarSize.NORMAL]: { width: 35, height: 35, strokeSize: 4, fontSize: "15px" },
  [AvatarSize.MEDIUM]: { width: 32, height: 32, strokeSize: 3, fontSize: "14px" },
  [AvatarSize.SMALL]: { width: 24, height: 24, strokeSize: 5, fontSize: "13px" },
};

const DEFAULT_AVATAR_STYLES: AvatarStyles = {
  width: 30,
  height: 30,
  strokeSize: 3,
  fontSize: "14px",
};

type AvatarStyles = {
  width: number;
  height: number;
  strokeSize: number;
  fontSize: string;
};

export function getAvatarSize(size?: AvatarSize): AvatarStyles {
  return size ? avatarSizeStyles[size] : DEFAULT_AVATAR_STYLES;
}

const RESET_PROPS = (t: Theme): SxProps => ({
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  position: "absolute",
  border: "1px solid",
  borderColor: t.palette.grey[200],
});

export type UserAvatarProps<T extends ElementType = AvatarTypeMap["defaultComponent"]> = AvatarProps<
  T,
  {
    size?: AvatarSize;
    name: string;
    src?: string;
    engagementScore?: number;
    nameOnTooltip?: boolean;
    leavingDate?: string;
    /**
     * @default false
     * @description If true, the avatar will be shown as a skeleton.
     */
    skeleton?: boolean;
  }
>;

const UserAvatar: FC<UserAvatarProps> = ({
  name,
  src,
  engagementScore,
  leavingDate,
  size = AvatarSize.NORMAL,
  nameOnTooltip = false,
  skeleton = false,
  ...other
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const propsSize = getAvatarSize(size);

  if (other.sx) {
    other.sx = merge({ backgroundColor: skeleton ? "unset" : "#ccc", ...propsSize }, other.sx);
  } else {
    other.sx = propsSize;
  }

  const tooltip = `${nameOnTooltip ? `${name}` : ""}${engagementScore ? " " : ""}${
    engagementScore ? t("common.avatar.laraScore", { laraScore: engagementScore }) : ""
  }`;

  const formerEmployee = isFormerEmployee(leavingDate);

  return (
    <Tooltip title={formerEmployee ? (t("common.avatar.formerEmployee") as string) : tooltip}>
      <Box
        sx={{
          borderRadius: "50%",
          border: 0,
          position: "relative",
          ...other.sx,
        }}
      >
        <Box
          sx={{
            ...other.sx,
            top: 0,
            left: 0,
            ml: 0,
            overflow: "hidden",
            borderRadius: "50%",
          }}
        >
          {engagementScore && (
            <CircularProgress
              sx={{ top: "-2.5%", left: "-2.5%", width: "105%", height: "105%", zIndex: 99, position: "relative" }}
              value={engagementScore}
              strokeSize={propsSize.strokeSize}
              color={laraScoreColor(engagementScore)}
            />
          )}
          {skeleton ? (
            <Skeleton sx={RESET_PROPS(theme)} variant="circular" />
          ) : src ? (
            <Avatar src={src} {...other} sx={RESET_PROPS(theme)} />
          ) : (
            <InitialsAvatar size={size} name={name} {...other} sx={RESET_PROPS(theme)} />
          )}
        </Box>
        {formerEmployee && <StatusIndicatorCircle size={size} />}
      </Box>
    </Tooltip>
  );
};

UserAvatar.propTypes = {
  name: PropTypes.string.isRequired,
};

export default UserAvatar;
