import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { AVAILABLE_LANGUAGES } from "@/hooks/useCurrentLanguage";

const languageDetector = new LanguageDetector();

languageDetector.addDetector({
  name: "fallback",

  lookup() {
    return "en";
  },
});

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ["translation"],
    supportedLngs: AVAILABLE_LANGUAGES,
    defaultNS: "translation",
    load: "languageOnly",
    fallbackLng: ["es"],
    initImmediate: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    detection: {
      order: ["querystring", "localStorage", "sessionStorage", "navigator", "htmlTag", "fallback"],
      lookupQuerystring: "lng",
      lookupLocalStorage: "lang",
      lookupSessionStorage: "lang",
    },
  });

export default i18n;
