import { SelectOption } from "@/components/SelectInput";
import {
  BooleanFilterConfiguration,
  DateFilterConfiguration,
  DriverFilterConfiguration,
  EmployeeFilterConfiguration,
  FilterConfiguration,
  FilterOperatorType,
  FilterType,
  NumberFilterConfiguration,
  SelectFilterConfiguration,
  StringFilterConfiguration,
} from "@/components/advanced-filters/FilterConfiguration";
import { isArray, isDate as isDateType, isString, isNumber, isBoolean, isObject } from "lodash";
import { filterCustomEncodeValue } from "@/helpers/queryParams";

export function getFilterConfigurationByFieldName(
  field: string | undefined,
  filters: FilterConfiguration[]
): FilterConfiguration | undefined {
  return filters.find((filter) => filter.name === field);
}

export function dateToString(date?: Date): string | null {
  if (!date) return null;
  return date.toISOString().split("T")[0];
}

type DefaultOptionValue =
  | DateFilterConfiguration["defaultValue"]
  | StringFilterConfiguration["defaultValue"]
  | NumberFilterConfiguration["defaultValue"]
  | BooleanFilterConfiguration["defaultValue"]
  | EmployeeFilterConfiguration["defaultValue"]
  | (SelectOption | SelectOption[]);

export function mapValueToString(value: DefaultOptionValue | null): string | null {
  if (isString(value)) return value;
  if (isDateType(value)) return dateToString(value);
  if (isNumber(value) || isBoolean(value)) return value.toString();
  if (isArray(value))
    return value.map((a) => mapValueToString(isDateType(a) ? a : filterCustomEncodeValue(a!))).join(",");
  // Select Option
  if (isObject(value) && value.hasOwnProperty("value")) {
    return mapValueToString(value["value"]);
  }

  return null;
}

export function mapToSelectOption(value: string): SelectOption {
  return { label: value, value };
}

export const getAsArray = <T>(value?: T | T[]): T[] => {
  if (!value) return [];
  return isArray(value) ? value : [value];
};

export const getFirstValue = <T>(value?: T | T[]): T | undefined => {
  return isArray(value) ? value[0] : value;
};

export const isEnumValue = <T extends Record<string, string>>(enumType: T, value: unknown): value is T => {
  return Object.values(enumType).includes(value as T[keyof T]);
};

export function isSelectTypeConfiguration(filter: FilterConfiguration): filter is SelectFilterConfiguration {
  return filter.type === FilterType.SELECT;
}

export function isEmployeeTypeConfiguration(filter: FilterConfiguration): filter is EmployeeFilterConfiguration {
  return filter.type === FilterType.EMPLOYEE;
}

export function isDriverTypeConfiguration(filter: FilterConfiguration): filter is DriverFilterConfiguration {
  return filter.type === FilterType.DRIVER;
}

export function isFilterOperator(operator: string): operator is FilterOperatorType {
  return Object.values(FilterOperatorType).includes(operator as FilterOperatorType);
}

/**
 * Extracts the operator from a key
 * @returns the operator and the key without the operator
 * @warning It assumes that the operator is valid.
 * @example extractOperatorFromKey("date") => ["date", "eq"]
 * @example extractOperatorFromKey("date[eq]") => ["date", "eq"]
 * @example extractOperatorFromKey("date[between]") => ["date", "between"]
 */
export function extractOperatorFromKey(key: string): [string, FilterOperatorType] {
  let [field, operator] = key.split("[");
  operator = operator?.replace("]", "") ?? null;

  if (isFilterOperator(operator)) {
    return [field, operator];
  }
  return [key, FilterOperatorType.EQUALS];
}
