import {
  FieldDefinitionJoinDataSource,
  JoinCondition,
  JoinDataSource,
  JoinDataSourceType,
  MultipleResolutionJoinDataSource,
} from "@/modules/board/models/metricDataSource";
import { Report } from "@/services/reportService";
import { compact, fromPairs, groupBy, isNil, isString, mapValues, sum } from "lodash";

const DEFAULT_MULTIPLE_RESOLUTION = MultipleResolutionJoinDataSource.FIRST;
const SEPARATOR_BETWEEN_TERMS = "ಠ_ಠ";
const NULL_VALUE_FILLER = "";

function onlyNumberArray(
  operatorFn: (numbers: number[]) => number
): (rows: Record<string, string | number | undefined>[], key: string) => number | null {
  return (rows, key) => {
    const numbers = rows
      .filter((row) => !isNil(row[key]))
      .map((row) => Number(row[key]))
      .filter((row) => isFinite(row));
    return numbers.length === 0 ? null : operatorFn(numbers);
  };
}

function withoutNulls(
  operatorFn: (rows: Record<string, string | number | undefined>[], key: string) => string | number | null
): (rows: Record<string, string | number | undefined>[], key: string) => string | number | null {
  return (rows, key) => {
    return operatorFn(
      rows.filter((row) => !isNil(row[key])),
      key
    );
  };
}

export const MULTIPLE_RESOLUTION_FN: Record<
  MultipleResolutionJoinDataSource,
  (rows: Record<string, string | number | undefined>[], key: string) => string | number | null
> = {
  [MultipleResolutionJoinDataSource.FIRST]: withoutNulls((rows, key) => rows[0]?.[key] ?? null),
  [MultipleResolutionJoinDataSource.LAST]: withoutNulls((rows, key) => rows.at(-1)?.[key] ?? null),
  [MultipleResolutionJoinDataSource.COUNT]: withoutNulls((rows) => rows.length),
  [MultipleResolutionJoinDataSource.SUM]: onlyNumberArray((numbers) => sum(numbers)),
  [MultipleResolutionJoinDataSource.AVERAGE]: onlyNumberArray((numbers) => sum(numbers) / numbers.length),
  [MultipleResolutionJoinDataSource.MAX]: onlyNumberArray((numbers) => Math.max(...numbers)),
  [MultipleResolutionJoinDataSource.MIN]: onlyNumberArray((numbers) => Math.min(...numbers)),
};

function normalizeFields(joinDefinition: JoinDataSource): Required<FieldDefinitionJoinDataSource>[] {
  return joinDefinition.fields.map((field) =>
    isString(field)
      ? {
          fromField: field,
          toField: field,
          multipleResolution: DEFAULT_MULTIPLE_RESOLUTION,
          defaultIfNull: null,
          overrideHeaderName: null,
          ifNoMatchingRows: "keepOldValue",
        }
      : {
          fromField: field.fromField,
          toField: field.toField || field.fromField,
          multipleResolution: field.multipleResolution || DEFAULT_MULTIPLE_RESOLUTION,
          defaultIfNull: field.defaultIfNull ?? null,
          overrideHeaderName: field.overrideHeaderName ?? null,
          ifNoMatchingRows: field.ifNoMatchingRows || "keepOldValue",
        }
  );
}

function keyOfObject(
  object: Record<string, unknown>,
  condition: JoinCondition[] | true,
  keyToTake: "currentKey" | "originalKey"
): string {
  if (condition === true) return NULL_VALUE_FILLER;

  const keys = condition.map((condition) => condition[keyToTake]);
  return keys.map((key) => object[key] ?? NULL_VALUE_FILLER).join(SEPARATOR_BETWEEN_TERMS);
}

function generateNullFieldObject(
  fieldsToCopy: Required<FieldDefinitionJoinDataSource>[]
): Record<string, string | number | null> {
  return fromPairs(
    fieldsToCopy.map((field) => {
      return [field.toField, field.defaultIfNull ?? null];
    })
  );
}

function generateObjectFromFieldsToCopy(
  fieldsToCopy: Required<FieldDefinitionJoinDataSource>[],
  matchingRows: Record<string, string | number>[]
): Record<string, string | number | null> {
  return fromPairs(
    compact(
      fieldsToCopy.map((field) => {
        if (matchingRows.length === 0 && field.ifNoMatchingRows === "keepOldValue") return null;

        const resolutionFn = MULTIPLE_RESOLUTION_FN[field.multipleResolution];
        return [field.toField, resolutionFn(matchingRows, field.fromField) ?? field.defaultIfNull];
      })
    )
  );
}

export function joinReports(accumulatedReport: Report, report: Report, joinDefinition: JoinDataSource): Report {
  const joinType = joinDefinition.type ?? JoinDataSourceType.LEFT;
  const fieldsToCopy = normalizeFields(joinDefinition);

  const index = groupBy(report.data, (row) => keyOfObject(row, joinDefinition.condition, "currentKey"));

  const usedTracker: Set<string> = new Set();

  const data = compact(
    accumulatedReport.data.map((row) => {
      const key = keyOfObject(row, joinDefinition.condition, "originalKey");

      usedTracker.add(key);

      const matchingRows = index[key] || [];
      const nullRow = generateNullFieldObject(fieldsToCopy);
      const addRows = generateObjectFromFieldsToCopy(fieldsToCopy, matchingRows);
      return joinType === JoinDataSourceType.INNER && matchingRows.length === 0
        ? null
        : { ...nullRow, ...row, ...addRows };
    })
  );

  if (joinType === JoinDataSourceType.OUTER) {
    const remainingRowKeys = Object.keys(index).filter((key) => {
      return !usedTracker.has(key);
    });

    data.push(
      ...remainingRowKeys.map((rowKey) => {
        const matchingRows = index[rowKey];
        const nullRow = generateNullFieldObject(fieldsToCopy);
        const rightRows = generateObjectFromFieldsToCopy(fieldsToCopy, matchingRows);
        const joinKeys =
          joinDefinition.condition === true
            ? {}
            : fromPairs(
                joinDefinition.condition.map((condition) => [
                  condition.originalKey,
                  matchingRows[0]?.[condition.currentKey],
                ])
              );
        const leftRows = mapValues(accumulatedReport.headers, () => null);

        return { ...nullRow, ...leftRows, ...joinKeys, ...rightRows };
      })
    );
  }

  const newHeaders = fromPairs(
    fieldsToCopy.map((field) => [
      field.toField,
      field.overrideHeaderName ?? report.headers[field.fromField] ?? field.fromField,
    ])
  );

  return {
    headers: { ...accumulatedReport.headers, ...newHeaders },
    data: data as Record<string, string | number>[],
  };
}
