import { useIsTextOverflowing } from "@/hooks/useIsTextOverflowing";
import { Box, Tooltip, TooltipProps } from "@mui/material";
import { ComponentProps, FC } from "react";

export const TooltipOnOverflow: FC<{
  title: TooltipProps["title"];
  overrideTitleOnTooltip?: TooltipProps["title"];
  tooltipProps?: Partial<Omit<TooltipProps, "title">>;
  boxProps?: ComponentProps<typeof Box>;
}> = ({ title, tooltipProps, boxProps, overrideTitleOnTooltip }) => {
  const { ref: subtitleRef, isTextOverflowing } = useIsTextOverflowing();
  const shouldShowTooltip = isTextOverflowing || overrideTitleOnTooltip;
  return (
    <Tooltip
      title={overrideTitleOnTooltip ? overrideTitleOnTooltip : isTextOverflowing ? title : ""}
      followCursor
      sx={{ display: "inline-block" }}
      {...tooltipProps}
    >
      <Box
        ref={subtitleRef}
        {...boxProps}
        sx={{
          borderBottom: shouldShowTooltip ? "1px dashed" : "none",
          borderColor: (theme) => theme.palette.divider,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          ...boxProps?.sx,
        }}
      >
        {title}
      </Box>
    </Tooltip>
  );
};
