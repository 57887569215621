import { Avatar, Box, Button, IconButton, Popover, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FC, MouseEvent, useEffect, useState } from "react";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ClearIcon from "@mui/icons-material/Clear";
import { PopOverTeams } from "./PopOverTeam";
import { CheckboxInput } from "../CheckboxInput";
import { useTranslation } from "react-i18next";
import { ManagerTeamCustom, ManagerTeamHierarchy, TeamCategoryType, isManagerTeamCustom } from "@/hooks/useTeams";
import { ProfileAvatar } from "../profile-avatar/ProfileAvatar";
import { AvatarSize, TeamType, TeamWithMembers } from "@/types/common";
import { createTargetEvent } from "@/utils/createTargetEvent";
import { getTeam, listTeams } from "@/services/teamsService";

export interface SelectTeamInputNewProps {
  id?: string;
  name: string;
  onChange: (event: { target: { name: string; value: unknown } }, value: string | null) => void;
  value: string | null;
  disabled?: boolean;
  placeholder?: string;
  label?: string;
  visible?: boolean;
}

function mapItemAndCheckboxToId(
  currentItemTeam: ManagerTeamCustom | ManagerTeamHierarchy | undefined,
  isDirectReport: boolean
): string | null {
  if (!currentItemTeam) {
    return null;
  }

  if (isManagerTeamCustom(currentItemTeam)) {
    return currentItemTeam.customTeamId;
  }

  return isDirectReport ? currentItemTeam.directReportTeamId : currentItemTeam.indirectReportTeamId;
}

async function mapTeamDetailToManagerTeam(
  teamDetail: TeamWithMembers
): Promise<ManagerTeamCustom | ManagerTeamHierarchy | undefined> {
  const [manager] = teamDetail.managers;

  if (teamDetail.type === TeamType.CUSTOM) {
    const teamCustom: ManagerTeamCustom = {
      customTeamId: teamDetail.id,
      teamName: teamDetail.name,
      type: TeamCategoryType.CUSTOM,
      managerName: teamDetail.managers[0]?.fullName || "",
      managerId: manager.id,
    };

    return teamCustom;
  }

  if (teamDetail.type === TeamType.DIRECT_REPORTS || teamDetail.type === TeamType.INDIRECT_REPORTS) {
    const hierarchyTeams = await listTeams({ managerId: teamDetail.managers[0].id });

    const hierarchyTeam: ManagerTeamHierarchy = {
      teamName: teamDetail.managers[0].fullName,
      type: TeamCategoryType.HIERARCHY,
      directReportTeamId: hierarchyTeams.elements.find((t) => t.type === TeamType.DIRECT_REPORTS)?.id || "",
      indirectReportTeamId: hierarchyTeams.elements.find((t) => t.type === TeamType.INDIRECT_REPORTS)?.id || "",
      managerId: manager.id,
      parentManagerId: undefined,
    };

    return hierarchyTeam;
  }
}

export const SelectTeamInputNew: FC<SelectTeamInputNewProps> = ({
  onChange,
  name,
  label,
  value,
  placeholder,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const [firstValueLoading, setFirstValueLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [currentItem, setCurrentItem] = useState<ManagerTeamCustom | ManagerTeamHierarchy | undefined>();
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [openPopOver, setOpenPopOver] = useState(false);
  const [onlyDirectReports, setOnlyDirectReports] = useState(false);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const openDialog = (event: MouseEvent<HTMLButtonElement>) => {
    if (disabled) {
      return;
    }
    setAnchorEl(event.currentTarget);
    setOpenPopOver(true);
  };

  const handleSelectItem = (item: ManagerTeamCustom | ManagerTeamHierarchy | undefined) => {
    setCurrentItem(item);
    setOpenPopOver(false);
  };

  useEffect(() => {
    if (!currentItem) {
      setShowCheckbox(false);
      return;
    }

    if (isManagerTeamCustom(currentItem)) {
      setShowCheckbox(false);
      return;
    }

    setShowCheckbox(true);
  }, [currentItem]);

  useEffect(() => {
    const teamValue = mapItemAndCheckboxToId(currentItem, onlyDirectReports);
    onChange(createTargetEvent(name, teamValue), teamValue);
  }, [currentItem, onlyDirectReports]);

  const loadFirstTeamValue = async (teamId: string) => {
    const loadedTeam = await getTeam(teamId);

    if (loadedTeam) {
      const mappedValue = await mapTeamDetailToManagerTeam(loadedTeam);

      if (mappedValue && !isManagerTeamCustom(mappedValue)) {
        if (mappedValue.directReportTeamId === value) {
          setOnlyDirectReports(true);
        }
      }

      setCurrentItem(mappedValue);
    }

    setFirstValueLoading(false);
  };

  useEffect(() => {
    if (value && firstValueLoading) {
      loadFirstTeamValue(value);
      return;
    }

    setFirstValueLoading(false);
  }, []);

  const resetValue = () => {
    setCurrentItem(undefined);
    setTimeout(() => setOpenPopOver(false), 10);
  };

  return (
    <Box
      sx={{
        display: {
          md: "flex",
          xs: "initial",
        },
      }}
    >
      <Box
        sx={{
          justifyContent: "space-between",
          width: "100%",
          height: "56px",
          display: "flex",
          alignItems: "center",
          border: "1px solid #c4c4c4",
          borderRadius: "4px",
          cursor: "pointer",
          flex: 1,
        }}
      >
        {firstValueLoading ? (
          <Box display="flex" gap={1} p={1} alignItems={"center"} justifyContent={"center"}>
            <Skeleton variant="circular" width="38px" height="38px" />
            <Skeleton variant="text" width="120px" />
          </Box>
        ) : (
          <Button sx={{ display: "flex", mx: 1, width: "100%", justifyContent: "space-between" }} onClick={openDialog}>
            {!currentItem ? (
              <Box sx={{ display: "flex", gap: 1 }}>
                <Avatar sx={{ mr: 1, width: 24, height: 24 }}>
                  <PersonSearchIcon sx={{ width: 18, height: 18 }} />
                </Avatar>
                <Typography color={"textSecondary"}>{label || placeholder}</Typography>
              </Box>
            ) : (
              <>
                <ProfileAvatar
                  size={AvatarSize.SMALL}
                  user={{
                    name: currentItem.teamName,
                  }}
                  hideArrow
                />
                <IconButton sx={{ ml: 1 }} onClick={resetValue} size="medium">
                  <ClearIcon fontSize="small" />
                </IconButton>
              </>
            )}
          </Button>
        )}

        {openPopOver && (
          <Popover
            open={openPopOver}
            onClose={() => setOpenPopOver(false)}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <PopOverTeams handleSelectItem={handleSelectItem} />
          </Popover>
        )}
      </Box>
      {showCheckbox && (
        <Box sx={{ width: isMd ? 225 : "100%", ml: isMd ? 2 : 0, mt: isMd ? 0 : "15px" }}>
          <CheckboxInput
            value={onlyDirectReports}
            label={t("questions.home.filters.onlyDirectReports")}
            name="onlyDirectReports"
            onChange={(event) => setOnlyDirectReports(event.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};
