import { combineReducers } from "@reduxjs/toolkit";
import { reducer as riskCaseDetailReducer } from "@/modules/case_management/stores/riskCaseDetailSlice";
import { reducer as employeeDetailReducer } from "@/modules/employee/stores/employeeDetailSlice";

const rootReducer = combineReducers({
  riskCaseDetail: riskCaseDetailReducer,
  employeeDetail: employeeDetailReducer,
});

export default rootReducer;
