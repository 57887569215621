import type { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { AppBar, Box, Chip, IconButton, Theme, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import type { AppBarProps } from "@mui/material";
import MenuIcon from "@/icons/Menu";
import AccountPopover from "./AccountPopover";
import EmployeeSearch from "./ContentSearch";
import { LaraLogo } from "@/svgs/LaraLogo";
import { LogoColor } from "@/theme/logo";
import { userHasRoles } from "@/utils/userHasRole";
import useAuth from "@/hooks/useAuth";
import HelpPopover from "./HelpPopover";
import LanguagePopover from "./LanguagePopover";
import { OldRoleCode } from "@/types/user";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const DashboardNavbar: FC<DashboardNavbarProps> = ({ onSidebarMobileOpen, sx, ...other }) => {
  const lgUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));
  const smDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const { user, isImpersonating, stopImpersonating } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { t } = useTranslation();

  function stopImpersonatingHandle() {
    stopImpersonating().catch((error) => {
      console.error("[Impersonate Modal] Failed to stop impersonating", error);
      enqueueSnackbar(t("navbar.impersonateModal.stopImpersonationFailed"), {
        anchorOrigin: {
          horizontal: "left",
          vertical: "bottom",
        },
        variant: "error",
      });
    });
  }

  return (
    <AppBar
      sx={{
        ...(theme.palette.mode === "light" && {
          backgroundColor: isImpersonating ? theme.palette.primary.dark : theme.palette.primary.main,
          boxShadow: "none",
          color: theme.palette.primary.contrastText,
        }),
        ...(theme.palette.mode === "dark" && {
          backgroundColor: isImpersonating ? theme.palette.background.default : theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.divider}`,
          boxShadow: "none",
        }),
        zIndex: theme.zIndex.drawer + 1,
      }}
      {...other}
    >
      <Toolbar sx={{ minHeight: 64 }}>
        {lgUp ? (
          <RouterLink to="/">
            <LaraLogo
              theme={LogoColor.WHITE}
              sx={{
                height: 40,
              }}
            />
          </RouterLink>
        ) : (
          <IconButton color="inherit" onClick={onSidebarMobileOpen} size="large">
            <MenuIcon fontSize="small" />
          </IconButton>
        )}
        {isImpersonating && (
          <>
            <Box sx={{ flexGrow: 1, ml: 2 }} />
            <Box sx={{ ml: 1 }}>
              <Chip
                color="primary"
                label={t("navbar.mainButton.stopImpersonating")}
                onClick={stopImpersonatingHandle}
                sx={{ border: "1px solid rgba(255,255,255,0.1)" }}
              />
            </Box>
          </>
        )}
        <Box sx={{ flexGrow: 1 }} />
        {(!isImpersonating || !smDown) && userHasRoles(user, [OldRoleCode.ADMIN, OldRoleCode.HRBP]) ? (
          <Box sx={{ ml: 1 }}>
            <EmployeeSearch />
          </Box>
        ) : null}
        {(!isImpersonating || !smDown) && (
          <Box sx={{ ml: 1 }}>
            <LanguagePopover />
          </Box>
        )}
        <Box sx={{ ml: 1 }}>
          <HelpPopover />
        </Box>
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardNavbar;
