import type { FC } from "react";
import { Box, Divider, List, Skeleton, Typography } from "@mui/material";

const EmployeeMetadataSkeleton: FC = () => {
  // Determines the length of the text skeleton
  const stringPlaceholder = "AAAAAAAAA";

  return (
    <Box
      sx={{
        color: "text.primary",
        backgroundColor: "background.paper",
        height: "100%",
        px: 2,
        py: 3,
        overflow: "hidden",
      }}
    >
      <List>
        <Skeleton variant="text">
          <Typography variant="h6">{stringPlaceholder}</Typography>
        </Skeleton>
        <Skeleton />
        <Skeleton />
      </List>
      <Divider sx={{ my: 2 }} />
      <List>
        <Skeleton variant="text">
          <Typography variant="h6">{stringPlaceholder}</Typography>
        </Skeleton>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </List>
      <Divider sx={{ my: 2 }} />
      <List>
        <Skeleton variant="text">
          <Typography variant="h6">{stringPlaceholder}</Typography>
        </Skeleton>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </List>
      <Divider sx={{ my: 2 }} />
      <List>
        <Skeleton variant="text">
          <Typography variant="h6">{stringPlaceholder}</Typography>
        </Skeleton>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </List>
    </Box>
  );
};

export default EmployeeMetadataSkeleton;
