import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Note, RiskCase } from "@/types/common";

interface RiskCaseDetailState {
  riskCase: RiskCase | null;
}

const initialState: RiskCaseDetailState = {
  riskCase: null,
};

const slice = createSlice({
  name: "riskCaseDetail",
  initialState,
  reducers: {
    loadRiskCase(_state: RiskCaseDetailState, action: PayloadAction<RiskCase>): RiskCaseDetailState {
      return {
        riskCase: action.payload,
      };
    },
    resetRiskCase(_state: RiskCaseDetailState): RiskCaseDetailState {
      return {
        riskCase: null,
      };
    },
    addNote(state: RiskCaseDetailState, action: PayloadAction<Note>): RiskCaseDetailState {
      if (!state.riskCase) return state;
      return {
        riskCase: {
          ...state.riskCase,
          notes: [action.payload, ...(state.riskCase?.notes || [])],
        },
      };
    },

    removeNote(state: RiskCaseDetailState, action: PayloadAction<Note>): RiskCaseDetailState {
      if (!state.riskCase) return state;
      return {
        riskCase: {
          ...state.riskCase,
          notes: [...(state.riskCase?.notes || []).filter((note) => note.id !== action.payload.id)],
        },
      };
    },
    replaceNote(state: RiskCaseDetailState, action: PayloadAction<Note>): RiskCaseDetailState {
      if (!state.riskCase) return state;
      return {
        riskCase: {
          ...state.riskCase,
          notes: [
            ...(state.riskCase?.notes || []).map((note) => {
              if (note.id === action.payload.id) {
                return action.payload;
              }
              return note;
            }),
          ],
        },
      };
    },
  },
});

export const { reducer } = slice;

export const { loadRiskCase, resetRiskCase, addNote, replaceNote, removeNote } = slice.actions;

export default slice;
