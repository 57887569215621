import axiosInstance, { CancelTokenSource } from "axios";

export class CancelTokenManager {
  private cancelTokens: Map<string, CancelTokenSource> = new Map<string, CancelTokenSource>();

  get(id: string): CancelTokenSource | undefined {
    return this.cancelTokens.get(id);
  }

  create(stringId: string): CancelTokenSource {
    this.cancel(stringId);

    this.cancelTokens.set(stringId, axiosInstance.CancelToken.source());

    return this.get(stringId)!;
  }

  cancel(id: string): void {
    this.get(id)?.cancel();
  }
}

export const cancelTokenManager = new CancelTokenManager();
