import { EmployeeDropdown, SearchEmployeeDropdownParams, searchEmployeeDropdown } from "@/services/employeeService";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { Box, FormControl, FormLabel, Button, FormHelperText } from "@mui/material";
import { FC, useEffect, useState } from "react";

import { EmployeeSelectorDialog } from "./EmployeeSelectorDialog";
import { useDeepCompareMemo } from "use-deep-compare";
import { trackEditCloseMemberDialog, trackEditOpenMemberDialog } from "@/modules/team/track";
import { useTranslation } from "react-i18next";
import { EmployeeActionable, EmployeeActionableSkeleton } from "./EmployeeActionable";
import { usePager } from "@/hooks/usePager";
import { uniqBy } from "lodash";

export enum EmployeeGroupEntityType {
  MANAGER = "manager",
  MEMBER = "member",
}

interface EmployeeGroupInputProps {
  members: { id: string; fullName: string; jobTitle?: string }[];
  handleAddMember: (member: { id: string; label: string; avatar: string; jobTitle: string }) => void;
  handleRemoveMember: (member: string) => void;
  helperText: string;
  loadingMembers?: boolean;
  type: EmployeeGroupEntityType;
  editable: boolean;
}

const buildExtraParamsCancelRequestToken = (type, page, pageSize, search = ""): string => {
  return `${type}-${page}-${pageSize}-${search}`;
};

export const EmployeeGroupInput: FC<EmployeeGroupInputProps> = ({
  type,
  members,
  handleAddMember,
  handleRemoveMember,
  loadingMembers,
  helperText,
  editable,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>("");
  const { page, restart, pageSize, loading, goNextPage, setLoading, stop, isLastPage } = usePager();
  const [employees, setEmployees] = useState<EmployeeDropdown[]>();

  useEffect(() => {
    const params: SearchEmployeeDropdownParams = { page, pageSize };

    if (search !== "") {
      params.search = search;
    }

    setLoading(true);

    searchEmployeeDropdown(params, buildExtraParamsCancelRequestToken(type, page, pageSize, search))
      .then((res) => {
        setEmployees((e) => {
          const newEmployees = uniqBy([...(e || []), ...res.elements], (e) => e.id);

          if (isLastPage(res)) {
            stop();
          }

          return newEmployees;
        });
      })
      .finally(() => setLoading(false));
  }, [page, search]);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
    trackEditOpenMemberDialog();
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    trackEditCloseMemberDialog();
  };

  const handleAccept = (newMember) => {
    handleCloseDialog();
    handleAddMember(newMember);
    setSearch("");
    restart();
  };

  const memberOptions = useDeepCompareMemo(() => {
    return employees?.filter((e) => !members.some((m) => m.id === e.id)) || [];
  }, [employees, members]);

  const handleSearchChange = (s: string) => {
    setEmployees([]);
    setSearch(s);
    restart();
  };

  return (
    <FormControl fullWidth sx={{ border: "1px solid #ccc", p: 2, borderRadius: "4px" }}>
      {/* i18next-extract-mark-context-next-line ["member", "manager"] */}
      <FormLabel sx={{ mb: 2 }}>{t("team.edit.labels.type", { context: type })}</FormLabel>

      <Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          {loadingMembers && (
            <>
              <EmployeeActionableSkeleton />
              <EmployeeActionableSkeleton />
              <EmployeeActionableSkeleton />
              <EmployeeActionableSkeleton />
            </>
          )}
          {members.map((employee) => {
            return (
              <EmployeeActionable
                key={`member-actions-${employee.id}`}
                editable={editable}
                employee={employee}
                handleRemoveMember={handleRemoveMember}
              />
            );
          })}
        </Box>
        {editable && (
          <Box sx={{ textAlign: "center" }}>
            <Button
              variant="text"
              startIcon={type === EmployeeGroupEntityType.MANAGER ? <PersonAddIcon /> : <GroupAddIcon />}
              onClick={handleOpenDialog}
            >
              {/* i18next-extract-mark-context-next-line ["member", "manager"] */}
              {t("team.edit.labels.add.type", { context: type })}
            </Button>
          </Box>
        )}
        {dialogOpen && (
          <EmployeeSelectorDialog
            employees={memberOptions}
            handleClose={handleCloseDialog}
            handleAccept={handleAccept}
            loading={loading}
            handleLoadNextPage={goNextPage}
            handleSearchChange={handleSearchChange}
            // i18next-extract-mark-context-next-line ["member", "manager"]
            title={t("team.edit.labels.add.type", { context: type })}
          />
        )}
      </Box>
      <FormHelperText sx={{ m: 1, textAlign: "center" }} error>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};
