import type { FC, ElementType } from "react";
import PropTypes from "prop-types";
import { Avatar, AvatarProps, AvatarTypeMap } from "@mui/material";
import getInitials from "@/utils/getInitials";
import { getAvatarSize } from "./UserAvatar";
import { AvatarSize } from "@/types/common";
import { getHSLFromString } from "@/utils/colors";

export type InitialsAvatarProps<T extends ElementType = AvatarTypeMap["defaultComponent"]> = AvatarProps<
  T,
  {
    name: string;
    size?: AvatarSize;
  }
>;

const InitialsAvatar: FC<InitialsAvatarProps> = (props) => {
  const { name, sx, size = AvatarSize.NORMAL, ...other } = props;
  const sizeProps = getAvatarSize(size);
  return (
    <Avatar
      sx={{
        ...sizeProps,
        ...sx,
        background: getHSLFromString(name),
      }}
      {...other}
    >
      {getInitials(name)}
    </Avatar>
  );
};

InitialsAvatar.propTypes = {
  name: PropTypes.string.isRequired,
};

export default InitialsAvatar;
