import { FC } from "react";
import { AutocompleteOption, InputAutocomplete } from "@/components/InputAutocomplete";
import { useDriverContext } from "@/hooks/useDriverContext";
import { driversForSelectOptions } from "@/services/driverService";

export type SelectDriverInputProps = {
  id?: string;
  name: string;
  onChange: (event: { target: { name: string; value: unknown } }, value: string | null) => void;
  value: string | null;
  disabled?: boolean;
  placeholder?: string;
  label?: string;
  visible?: boolean;
} & ({ type: "driver" } | { type: "subDriver"; driverCode: string });

export const SelectDriverInput: FC<SelectDriverInputProps> = ({
  onChange,
  name,
  label,
  value,
  placeholder,
  disabled = false,
  ...rest
}) => {
  const { drivers, loading, findDriverOrSubdriverByCode } = useDriverContext();

  const options: AutocompleteOption[] =
    rest.type === "subDriver"
      ? findDriverOrSubdriverByCode(rest.driverCode)?.driver?.subDrivers?.map((subDriver) => ({
          label: subDriver.name,
          value: subDriver.subDriverCode,
        })) || []
      : driversForSelectOptions(drivers);

  return (
    <InputAutocomplete
      name={name}
      label={label}
      value={value}
      loading={loading}
      options={options}
      onChange={onChange}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
};
