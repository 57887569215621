import { useRef, FC } from "react";
import useAuth from "@/hooks/useAuth";
import { InputAutocomplete } from "@/components/InputAutocomplete";
import { listOrganizations } from "@/services/userService";
import { useHotkeys } from "react-hotkeys-hook";
import { Box, Typography } from "@mui/material";
import { Shortcut } from "@/types/shortcuts";
import { ShortcutHint } from "@/components/ShortcutHint";

async function fetchOrganizations(): Promise<{ label: string; value: string }[]> {
  const orgs = await listOrganizations();
  return orgs.map<{ label: string; value: string }>((o) => ({ value: o.code, label: o.name }));
}

const OrganizationSwitcher: FC<{
  onSwitchOrganizationShortcut?: () => void;
}> = ({ onSwitchOrganizationShortcut }) => {
  const { changeOrg, organizationReference, user } = useAuth();

  const inputRef = useRef<HTMLInputElement>(null);

  const isLaraOrg = user?.organizationReference === "lara";

  useHotkeys(
    Shortcut.SWITCH_ORGANIZATION,
    () => {
      onSwitchOrganizationShortcut?.();

      setTimeout(() => {
        inputRef.current?.focus();
        // Select the text in the input so the user can start typing immediately
        inputRef.current?.select();
      }, 100);
    },
    { enabled: isLaraOrg }
  );

  if (!isLaraOrg) return null;

  return (
    <Box>
      <InputAutocomplete
        innerRef={inputRef}
        fullWidth
        disableClearable
        id="org-select"
        name="org-select"
        sx={{ background: "white", minWidth: 200 }}
        value={organizationReference}
        onChange={(_, org) => {
          changeOrg(org);
          window.location.reload();
        }}
        options={fetchOrganizations}
      />
      {/* Shortcut hint */}
      <Typography
        sx={{
          pt: 1,
          pl: 0.2,
          color: "text.secondary",
          fontSize: 11,
        }}
      >
        Press <ShortcutHint shortcut={Shortcut.SWITCH_ORGANIZATION} /> to change organization
      </Typography>
    </Box>
  );
};

export default OrganizationSwitcher;
