import { User } from "@/types/user";

const LARA_USER_ID = "0";

export function isUserLara(user: User): boolean {
  return user.id === LARA_USER_ID;
}

export function isUserIdLara(userId: string): boolean {
  return userId === LARA_USER_ID;
}
