import Kbd from "@/components/Kbd";
import { Shortcut } from "@/types/shortcuts";
import { FC, Fragment, useMemo } from "react";

const MODIFIER_KEYS_MAP: Record<string, (os: "mac" | "windows") => string> = {
  mod: (os) => (os === "mac" ? "⌘" : "ctrl"),
  ctrl: (os) => (os === "mac" ? "⌃" : "ctrl"),
  alt: (os) => (os === "mac" ? "⌥" : "alt"),
  shift: () => "shift",
};

export const ShortcutHint: FC<{ shortcut: Shortcut | string }> = ({ shortcut }) => {
  const os = useMemo(
    () => (navigator.userAgent.toLocaleLowerCase().includes("mac") ? "mac" : "windows"),
    [navigator.userAgent]
  );

  const keys = shortcut.split("+");

  return (
    <>
      {keys.map((key, index) => (
        <Fragment key={key}>
          <Kbd>{MODIFIER_KEYS_MAP[key.trim()]?.(os) ?? key.trim()}</Kbd>
          {index < keys.length - 1 && " + "}
        </Fragment>
      ))}
    </>
  );
};
