import type { FC } from "react";
import { Chip, ChipProps } from "@mui/material";
import { Severity } from "@/types/common";
import { useTranslation } from "react-i18next";
import { riskCaseSeverityLocale } from "../utils/localizeConstants";
import { severityColor } from "@/theme/severityColor";

export interface SeverityChipProps extends Exclude<ChipProps, "color"> {
  severity?: Severity | null;
  variant?: "outlined" | "filled";
}

const SeverityChip: FC<SeverityChipProps> = (props) => {
  const { severity, variant = "outlined", sx, ...other } = props;
  const { t } = useTranslation();

  if (!severity) return <>-</>;

  const color = severityColor(severity);

  return (
    <Chip
      size="small"
      variant={variant}
      label={riskCaseSeverityLocale(t, severity).toLowerCase()}
      sx={{
        ...sx,
        border: variant === "outlined" ? `2px solid ${color}` : "none",
        background: variant === "outlined" ? "transparent" : color,
        color: variant === "outlined" ? "inherit" : "#444444",
      }}
      {...other}
    />
  );
};

export default SeverityChip;
