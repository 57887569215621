import { ManagerTeamCustom, ManagerTeamHierarchy, isManagerTeamCustom } from "@/hooks/useTeams";
import { ListItemButton } from "@mui/material";
import { FC } from "react";
import { ProfileAvatar } from "../profile-avatar/ProfileAvatar";
import { AvatarSize } from "@/types/common";

export const TeamItem: FC<{
  team: ManagerTeamCustom | ManagerTeamHierarchy;
  onClick?: (item: ManagerTeamCustom | ManagerTeamHierarchy) => void;
}> = ({ team, onClick }) => {
  const title = team.teamName;
  const subtitle = isManagerTeamCustom(team) ? team.managerName : "";

  return (
    <ListItemButton onClick={() => onClick?.(team)}>
      <ProfileAvatar
        size={AvatarSize.SMALL}
        user={{
          name: title,
          jobTitle: subtitle,
        }}
      />
    </ListItemButton>
  );
};
