import { ErrorBox } from "@/components/ErrorBox";
import { SplitTreatment, SplitTreatmentState, useBooleanTreatments } from "@/hooks/useSplitTreatment";
import LoadingScreen from "@/modules/skeleton/components/LoadingScreen";
import NotFound from "@/pages/NotFound";
import { Card, CardContent, Grid } from "@mui/material";
import { compact } from "lodash";

import { FC, useMemo } from "react";

const TreatmentErrorState = () => {
  const reload = () => {
    document.location.reload();
  };

  return (
    <Grid container alignContent={"center"} justifyContent={"center"} height="100%">
      <Grid item m={2}>
        <img alt="empty state" width={300} src={"/static/error/undraw_qa_engineers.svg"} />
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <ErrorBox onRetry={() => reload()} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

enum TreatmentGuardOperation {
  OR = "or",
  AND = "and",
}

export const TreatmentGuard: FC<{
  treatments: SplitTreatment[];
  children: React.ReactNode;
  operation?: TreatmentGuardOperation;
}> = ({ treatments, children, operation = TreatmentGuardOperation.OR }) => {
  const [treatmentsValues, readyState] = useBooleanTreatments(
    treatments,
    treatments.map(() => false)
  );

  const trueValues = compact(treatmentsValues);

  /*
  This value is calculated from whether the operation to display the child is OR or AND,
  OR: any of the treatments is TRUE it will display it,
  AND :all the treatments must be TRUE
  */
  const shouldShowChildren = useMemo(() => {
    return operation === TreatmentGuardOperation.AND ? trueValues.length === treatments.length : trueValues.length > 0;
  }, [treatmentsValues]);

  if (readyState === SplitTreatmentState.LOADING) {
    return <LoadingScreen />;
  }

  if (readyState === SplitTreatmentState.ERROR) {
    return <TreatmentErrorState />;
  }

  return shouldShowChildren ? <>{children}</> : <NotFound />;
};
