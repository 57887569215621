export class MfaChallengeFailedError extends Error {
  constructor() {
    super(`mfa_challenge_failed`);
  }
}

export class MfaRegisterFailedError extends Error {
  constructor() {
    super(`mfa_register_error`);
  }
}
