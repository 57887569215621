import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Employee } from "@/types/common";

interface EmployeeDetailState {
  employee: Employee | null;
}

const initialState: EmployeeDetailState = {
  employee: null,
};

const slice = createSlice({
  name: "employeeDetail",
  initialState,
  reducers: {
    loadEmployeeDetails(state: EmployeeDetailState, action: PayloadAction<Employee>): EmployeeDetailState {
      return {
        employee: action.payload,
      };
    },
    resetEmployeeDetails(state: EmployeeDetailState): EmployeeDetailState {
      return {
        employee: null,
      };
    },
  },
});

export const { reducer } = slice;

export const { loadEmployeeDetails, resetEmployeeDetails } = slice.actions;

export default slice;
