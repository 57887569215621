import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TextField, CircularProgress, Box, debounce } from "@mui/material";
import { AsyncFunctionOrValue, valueFromAsyncFunctionOrValue } from "./FunctionOrValue";
import { createTargetEvent } from "@/utils/createTargetEvent";
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteProps,
  AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete";
import UserAvatar from "@/components/UserAvatar";
import { isArray, isNil, throttle } from "lodash";
import { mapStringToAutocompleteOption } from "@/modules/settings/components/integrations/steps/MapLaraFieldsStep";

export type AutocompleteOption = { label: string; value: string };

const SCROLL_END_VISIBLE_ITEMS = 3;

export const LOADING_ITEM_VALUE = "loading_value";

export type InputAutocompleteProps<
  T extends AutocompleteOption,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  "value" | "onChange" | "options" | "renderInput"
> & {
  options: AsyncFunctionOrValue<T[], string>;
  filterOption?: (item: AutocompleteOption) => boolean;
  value: Multiple extends true ? string[] : string | null;
  name: string;
  label?: string;
  error?: boolean;
  minWidth?: number;
  required?: boolean;
  onChange?: (
    event: { target: { name: string; value: unknown } },
    value: Multiple extends true ? T[] : string | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T>
  ) => void;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  trackOnChange?: () => void;
  onScrollEnd?: () => void;
  loadingNextPage?: boolean;
  handleSearchChange?: (search: string) => void;
  innerRef?: React.Ref<HTMLInputElement>;
};

export function InputAutocomplete<
  T extends AutocompleteOption,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  innerRef,
  onChange,
  label,
  name,
  id,
  sx,
  value,
  options,
  error,
  required,
  minWidth,
  renderInput,
  renderOption,
  filterOption,
  trackOnChange,
  onScrollEnd,
  loadingNextPage,
  handleSearchChange,
  ...props
}: InputAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>): JSX.Element {
  const [inputValue, setInputValue] = useState<string>("");
  const [optionsLoaded, setOptionsLoaded] = useState<T[]>(value ? [{ label: value, value } as T] : []);
  const [loading, setLoading] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [listboxNode, setListboxNode] = useState<EventTarget & Element>();
  const inputEl = useRef(null);

  function mapValue(value: string | null | undefined, options: T[]): T | null {
    if (isNil(value)) return null;

    const option = options.find((option) => option.value === value);

    return props.freeSolo && !option ? (mapStringToAutocompleteOption(value) as T) : option ?? null;
  }

  const onScrollEndFn = useCallback(() => {
    onScrollEnd?.();
  }, [onScrollEnd]);
  const onScrollEndDebounce = useMemo(() => debounce(onScrollEndFn, 300), [onScrollEndFn]);

  const handleSearchChangeDebounce = debounce((search: string) => {
    handleSearchChange?.(search);
  }, 300);

  useEffect(() => {
    let wasUnmounted = false;
    async function getResponses() {
      try {
        setLoading(true);

        let responseOptions = await valueFromAsyncFunctionOrValue(options, inputValue);
        if (wasUnmounted) return;

        setOptionsLoaded(responseOptions);

        if (listboxNode) {
          // move scroll to last position
          listboxNode.scrollTop = scrollPosition;
        }
      } catch (err) {
        console.error("Cant load Autocomplete options", err);
      } finally {
        if (wasUnmounted) return;
        setLoading(false);
      }
    }

    getResponses();

    return () => {
      wasUnmounted = true;
    };
  }, [options]);

  const valueMapped: any = useMemo(() => {
    if (!value || (!props.freeSolo && optionsLoaded?.length === 0)) return null;
    if (isArray(value)) {
      return value.map((v) => mapValue(v, optionsLoaded)).filter((v): v is T => !isNil(v));
    }
    const mapped = mapValue(value, optionsLoaded);
    return props.multiple ? [mapped] : mapped;
  }, [value, optionsLoaded]);

  let filteredOptions = optionsLoaded;

  if (filterOption) {
    filteredOptions = filteredOptions.filter(filterOption);
  }

  if (loadingNextPage) {
    if (!filteredOptions.find((o) => o.value === LOADING_ITEM_VALUE)) {
      // add loading item
      filteredOptions.push({ value: LOADING_ITEM_VALUE, label: LOADING_ITEM_VALUE } as T);
    }
  } else {
    filteredOptions = filteredOptions.filter((f) => !isOptionItemLoading(f));
  }

  const handleInputSearchChange = (e) => {
    handleSearchChangeDebounce?.(e.target.value);
  };

  const handleScroll = throttle((event: React.SyntheticEvent) => {
    if (loadingNextPage) return;

    const listboxNode = event.currentTarget;

    if (!listboxNode) {
      return;
    }

    const wrapperHeight = listboxNode.clientHeight;
    const contentHeight = listboxNode.scrollHeight;
    const yScrollPosition = listboxNode.scrollTop + listboxNode.clientHeight;

    setListboxNode(event.currentTarget);

    setScrollPosition(listboxNode.scrollTop);

    if (contentHeight <= wrapperHeight) {
      // no scroll case, exit
      return;
    }

    const firstElementList = listboxNode.firstElementChild;
    const itemHeight = firstElementList!.getClientRects()[0].height;
    const bottomOffset = itemHeight * SCROLL_END_VISIBLE_ITEMS;

    if (yScrollPosition >= listboxNode.scrollHeight - bottomOffset) {
      onScrollEndDebounce();
    }
  }, 50);
  return (
    <Autocomplete
      {...props}
      sx={{ minWidth: minWidth || 150, ...sx }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.label}
      options={filteredOptions}
      loading={loading}
      value={valueMapped}
      onChange={
        onChange &&
        ((_event, value, ...params) => {
          if (props.multiple) {
            return onChange(
              createTargetEvent(name, value),
              value.map((v: T) => v),
              ...params
            );
          }
          const stringValue = value ? value.value || null : null;

          if (trackOnChange) {
            trackOnChange();
          }

          if (!stringValue) {
            handleSearchChangeDebounce("");
          }

          return onChange(createTargetEvent(name, stringValue), stringValue, ...params);
        })
      }
      inputValue={inputValue}
      onInputChange={(_, value) => setInputValue(value)}
      renderOption={renderOption}
      ListboxProps={{
        onScroll: handleScroll,
      }}
      renderInput={
        renderInput ||
        ((params) => {
          return (
            <TextField
              {...params}
              error={error}
              name={name}
              label={label}
              required={required}
              inputRef={inputEl}
              onChange={handleInputSearchChange}
              placeholder={props.placeholder}
              InputProps={{
                ...params.InputProps,
                required: false,
                inputRef: innerRef,
                endAdornment: (
                  <>
                    {props.loading || loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          );
        })
      }
    />
  );
}

export function isOptionItemLoading(option: { value: string }): boolean {
  return option.value === LOADING_ITEM_VALUE;
}

export type AutocompleteOptionWithAvatar = AutocompleteOption & { avatar?: string };
export const OptionWithAvatar: FC<{
  props: React.HTMLAttributes<HTMLLIElement>;
  option: AutocompleteOptionWithAvatar;
}> = ({ props, option }) => (
  <Box component="li" {...props}>
    <UserAvatar name={option.label} src={option.avatar} sx={{ mr: 1 }} />
    {option.label}
  </Box>
);
