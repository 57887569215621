import hashCode from "@/utils/hashCode";

export function hexToRGB(hex: string, alpha: number): string {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

export function getContrastColor(hex: string, blackColor: string, whiteColor: string): string {
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error("Invalid HEX color.");
  }
  var r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);

  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? blackColor : whiteColor;
}

export function getHueFromString(str: string): number {
  const h = hashCode(str);
  return h % 255;
}

export function getHSLFromString(str: string, saturation = 55, light = 60): string {
  return `hsl(${getHueFromString(str)}, ${saturation}%, ${light}%)`;
}
