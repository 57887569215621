import { useFormik } from "formik";
import { TFunction, useTranslation } from "react-i18next";
import { object as yupObject, string as yupString, array as yupArray } from "yup";
import HelpIcon from "@mui/icons-material/Help";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { useFormikWithTranslations } from "@/utils/formikConnector";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Skeleton,
  TextField,
  Typography,
  FormControl,
  FormLabel,
} from "@mui/material";
import { FC, useState } from "react";
import { Team, TeamManager, TeamType, TeamWithMembers } from "@/types/common";
import { useSnackbar } from "notistack";
import { EmployeeGroupEntityType, EmployeeGroupInput } from "@/components/employee-group-input/EmployeeGroupInput";
import { chunk, map } from "lodash";
import {
  TeamPendingChangeStatus,
  updateTeam,
  createTeam,
  isEmployeeIdsSegmentFilter,
  getDepartmentOrTeamSegment,
  UpdateOrCreateTeamBody,
} from "@/services/teamsService";
import { trackCreateTeam, trackEditAddMember, trackEditRemoveMember, trackEditTeamSave } from "@/modules/team/track";
import { Tooltip } from "@/components/Tooltip";
import { ObjectShape } from "yup/lib/object";
import { searchEmployee } from "@/services/employeeService";
import { usePromise } from "@/hooks/usePromise";
import { ErrorBox } from "@/components/ErrorBox";

interface TeamFormProps {
  team?: TeamWithMembers;
  handleSuccessUpdated: () => void;
  handleStopUpdating: () => void;
  canEditManagers: boolean;
  defaultManagers?: TeamManager[];
}

const FIELD_NAME_BY_ENTITY_TYPE: Record<EmployeeGroupEntityType, "managers" | "members"> = {
  [EmployeeGroupEntityType.MANAGER]: "managers",
  [EmployeeGroupEntityType.MEMBER]: "members",
};

export const TeamFormSkeleton = () => (
  <Box>
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Skeleton animation="wave" height={80} width="100%" style={{ marginBottom: 6 }} />
    </Box>
    <Skeleton animation="wave" height={20} width={100} style={{ marginBottom: 6 }} />
    <Box sx={{ display: "flex" }}>
      <Skeleton animation="wave" variant="circular" width={40} height={40} sx={{ mr: 2 }} />
      <Box>
        <Skeleton animation="wave" height={10} width={200} style={{ marginBottom: 6 }} />
        <Skeleton animation="wave" height={10} width={200} style={{ marginBottom: 6 }} />
      </Box>
    </Box>
  </Box>
);

export function labelFilter(t: TFunction, propName: "department" | "team"): string {
  // i18next-extract-mark-context-next-line ["department", "team"]
  return t("team.edit.labels.filter.module", { context: propName });
}

const INITIAL_EMPTY_TEAM = (managers: TeamManager[] = []): FormValues => ({
  id: "-1",
  name: "",
  members: [],
  managers,
  type: TeamType.CUSTOM,
  childrenTeams: [],
  createdAt: new Date().toString(),
});

interface FormValues extends Team {
  columnName?: string;
  members: { id: string; fullName: string; jobTitle?: string }[];
}

function makeValidationSchema(t: TFunction, canEditManagers: boolean, team?: Team) {
  const validations: ObjectShape = {
    name: yupString().min(3, t("team.edit.errors.nameMinimalLength")).required(t("team.edit.errors.nameIsRequired")),
  };

  if (canEditManagers) {
    validations.managers = yupArray().min(1, t("team.edit.errors.managerIsRequired"));
  }

  if (!team || isEmployeeIdsSegmentFilter(team?.filters!)) {
    validations.members = yupArray().min(3, t("team.edit.errors.membersIsRequired"));
  } else if (getDepartmentOrTeamSegment(team?.filters!)) {
    validations.columnName = yupString()
      .min(1, t("team.edit.errors.nameMinimalLength"))
      .required(t("team.edit.errors.nameIsRequired"));
  }

  return yupObject(validations);
}

function mapFormToBodyService(team: FormValues, canEditManagers: boolean, isCreating: boolean): UpdateOrCreateTeamBody {
  const body: UpdateOrCreateTeamBody = {
    name: team.name,
  };

  if (team.managers && (canEditManagers || isCreating)) {
    body.managers = map(team.managers, "id");
  }

  if (isCreating || isEmployeeIdsSegmentFilter(team.filters!)) {
    body.filters = {
      employee_ids: map(team.members, "id"),
    };
  }

  return body;
}

function getEmployeeMembers(employeeIds: string[]): Promise<FormValues["members"]> {
  const chunks = chunk(employeeIds, 100);

  return chunks.reduce(
    (promise, chunk) => {
      return promise.then(async (members) => {
        const fetchedMembers = await searchEmployee({
          pageSize: 100,
          showFormer: true,
          id: chunk,
        });

        return [
          ...members,
          ...fetchedMembers.elements.map((m) => ({
            fullName: `${m.firstName} ${m.lastName}`,
            id: m.id,
            jobTitle: m.jobTitle,
          })),
        ];
      });
    },
    Promise.resolve([] as FormValues["members"])
  );
}

async function mapTeamMembersToFormValues(team: TeamWithMembers): Promise<FormValues["members"]> {
  if (isEmployeeIdsSegmentFilter(team.filters!)) {
    return await getEmployeeMembers(team.filters.employee_ids);
  }

  return team.members.map((m) => ({ ...m, fullName: `${m.firstName} ${m.lastName}` }));
}

export const TeamForm: FC<TeamFormProps> = ({
  team,
  defaultManagers,
  handleSuccessUpdated,
  handleStopUpdating,
  canEditManagers,
}) => {
  const { t } = useTranslation();
  const [modalPending, setModalPending] = useState(false);
  const isCreating = !team;
  const hasDefaultManagers = Boolean(defaultManagers?.length);
  const isMembersEditable = team && team.filters ? isEmployeeIdsSegmentFilter(team.filters) : true;
  const departmentOrTeamSegmentValue = team && team.filters ? getDepartmentOrTeamSegment(team.filters) : undefined;

  const formik = useFormik<FormValues>({
    initialValues: isCreating
      ? INITIAL_EMPTY_TEAM(defaultManagers)
      : {
          ...{ ...team, members: [] },
          columnName: departmentOrTeamSegmentValue?.value,
        },
    validationSchema: makeValidationSchema(t, canEditManagers, team),
    validateOnBlur: false,
    onSubmit: async (values) => {
      let success = false;

      try {
        const body = mapFormToBodyService(values, canEditManagers, !Boolean(team));

        const response = isCreating ? await createTeam(body) : await updateTeam(team.id, body);

        success = true;

        if (response.status === TeamPendingChangeStatus.PENDING_APPROVAL) {
          setModalPending(true);
        } else {
          enqueueSnackbar(t("team.edit.success"), { variant: "success" });
          handleSuccessUpdated();
        }
      } catch {
        enqueueSnackbar(t("team.edit.errors.server"), { variant: "error" });
      }

      const paramTracking = success ? "success" : "error";

      if (isCreating) {
        trackCreateTeam(paramTracking);
      } else {
        trackEditTeamSave(paramTracking);
      }
    },
  });

  const loadedMembers = usePromise(async () => {
    if (!team) return;
    const members = await mapTeamMembersToFormValues(team);
    formik.setFieldValue("members", members);
    return members;
  }, [team]);

  const { enqueueSnackbar } = useSnackbar();

  const formikField = useFormikWithTranslations(formik);

  const handleAddMember =
    (type: EmployeeGroupEntityType) => (newMember: { id: string; label: string; avatar: string; jobTitle: string }) => {
      const fieldName = FIELD_NAME_BY_ENTITY_TYPE[type];

      formik.setFieldValue(fieldName, [
        ...formik.values[fieldName],
        { ...newMember, fullName: newMember.label, id: newMember.id },
      ]);
      trackEditAddMember();
    };

  const handleRemoveMember = (type: EmployeeGroupEntityType) => (removeMemberId: string) => {
    const fieldName = FIELD_NAME_BY_ENTITY_TYPE[type];
    formik.setFieldValue(
      fieldName,
      (formik.values[fieldName] as { id: string }[]).filter((m) => m.id !== removeMemberId)
    );
    trackEditRemoveMember();
  };

  return (
    <Box sx={{ m: 1 }}>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ gap: 1 }}>
          <Box>
            <TextField label={t("team.edit.labels.name")} fullWidth variant="outlined" {...formikField("name")} />
          </Box>
          {(canEditManagers || hasDefaultManagers) && (
            <Box sx={{ mt: 3 }}>
              <EmployeeGroupInput
                members={formik.values.managers}
                handleAddMember={handleAddMember(EmployeeGroupEntityType.MANAGER)}
                handleRemoveMember={handleRemoveMember(EmployeeGroupEntityType.MANAGER)}
                helperText={formikField("managers").helperText as string}
                type={EmployeeGroupEntityType.MANAGER}
                editable={canEditManagers}
              />
            </Box>
          )}

          {departmentOrTeamSegmentValue && (
            <Box sx={{ mt: 3 }}>
              <FormControl fullWidth sx={{ border: "1px solid #ccc", p: 2, borderRadius: "4px" }}>
                <FormLabel sx={{ mb: 2, display: "flex", gap: 0.5, alignItems: "center" }}>
                  {labelFilter(t, departmentOrTeamSegmentValue.propName)}
                  <Tooltip title={t("team.edit.labels.filter.helper") || ""}>
                    <HelpIcon fontSize="inherit" color="action" />
                  </Tooltip>
                </FormLabel>
                <TextField
                  /**
                   * TODO: Sacar el disabled.
                   * Si la idea es poder editar este campo hay que modificar
                   * el endpoint ya que actualmente únicamente soporta employee_ids
                   */
                  disabled
                  label={t("team.edit.labels.filter.placeholder")}
                  fullWidth
                  variant="outlined"
                  {...formikField("columnName")}
                />
              </FormControl>
            </Box>
          )}

          <Box sx={{ mt: 3 }}>
            {loadedMembers.render(
              () => (
                <EmployeeGroupInput
                  members={formik.values.members}
                  loadingMembers
                  handleAddMember={handleAddMember(EmployeeGroupEntityType.MEMBER)}
                  handleRemoveMember={handleRemoveMember(EmployeeGroupEntityType.MEMBER)}
                  helperText={formikField("members").helperText as string}
                  type={EmployeeGroupEntityType.MEMBER}
                  editable={isMembersEditable}
                />
              ),
              () => (
                <ErrorBox onRetry={() => loadedMembers.retry()} />
              ),
              () => (
                <EmployeeGroupInput
                  members={formik.values.members}
                  loadingMembers={false}
                  handleAddMember={handleAddMember(EmployeeGroupEntityType.MEMBER)}
                  handleRemoveMember={handleRemoveMember(EmployeeGroupEntityType.MEMBER)}
                  helperText={formikField("members").helperText as string}
                  type={EmployeeGroupEntityType.MEMBER}
                  editable={isMembersEditable}
                />
              )
            )}
          </Box>
        </Box>
        <Box sx={{ mt: 3, display: "flex", gap: 1, justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            disabled={formik.isSubmitting || !formik.dirty}
            size="large"
            sx={{ mt: 2, float: "right" }}
            type="submit"
          >
            {t("team.edit.buttons.save")}
          </Button>
          <Button
            variant="outlined"
            disabled={formik.isSubmitting}
            size="large"
            sx={{ mt: 2, mr: 1, float: "right" }}
            onClick={handleStopUpdating}
          >
            {t("team.edit.buttons.cancel")}
          </Button>
        </Box>
      </form>

      <Dialog
        open={modalPending}
        onClose={handleSuccessUpdated}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ display: "flex" }}>
          <PriorityHighIcon sx={{ color: "#d9cb31" }} />
          {t("team.edit.modalApproval.title")}
        </DialogTitle>
        <DialogContent>
          <Typography>{t("team.edit.modalApproval.description")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessUpdated}>{t("common.prompt.understand")}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
