import { ReactElement, useEffect, useState } from "react";
import { useDeepCompareEffect } from "use-deep-compare";

export enum PromiseStateStatus {
  PENDING = "pending",
  RESOLVED = "resolved",
  REJECTED = "rejected",
}

export class PromiseState<T> {
  public error: unknown | null;

  public value: T | null;

  public status: PromiseStateStatus;

  public internalPromise: Promise<T | void> | null;

  public isRejected = false;
  public isResolved = false;
  public isPending = false;

  public setPromiseStateFn: (state: PromiseState<T>) => void = () => {};
  public retryFn: () => Promise<T> | false;

  public retryCount: number;

  constructor(retryFn: () => Promise<T> | false, retryCount = 0, value?: T, error?: unknown) {
    this.retryFn = retryFn;
    this.retryCount = retryCount;
    this.value = null;
    this.error = null;

    if (value !== undefined) {
      this.value = value;
      this.status = PromiseStateStatus.RESOLVED;
      this.isResolved = true;
      return;
    }

    if (error !== undefined) {
      this.error = error;
      this.status = PromiseStateStatus.REJECTED;
      this.isRejected = true;
      return;
    }

    this.status = PromiseStateStatus.PENDING;
    this.isPending = true;
  }

  setChangeFn = (fn: (state: PromiseState<T>) => void) => {
    this.setPromiseStateFn = fn;
  };

  nextState = (retryFn: () => Promise<T> | false, retryCount: number, value?: T, error?: unknown): PromiseState<T> => {
    const state = new PromiseState<T>(retryFn, retryCount, value, error);
    state.setChangeFn(this.setPromiseStateFn);
    this.setPromiseStateFn(state);
    return state;
  };

  retry = () => {
    this.retryCount++;
    const retryResult = this.retryFn();
    this.fill(retryResult);
    return retryResult;
  };

  clean = () => {
    this.nextState(this.retryFn, this.retryCount);
  };

  resolve = (value: T) => {
    this.nextState(this.retryFn, this.retryCount, value);
  };

  reject = (error: unknown) => {
    this.nextState(this.retryFn, this.retryCount, undefined, error);
  };

  reset = (fn: () => Promise<T> | false) => {
    const state = this.nextState(fn, 0);
    state.fill(state.retryFn());
  };

  fill = (promise: Promise<T> | false) => {
    this.clean();

    if (promise === false) return;

    promise
      .then((val) => {
        this.resolve(val);
        return val;
      })
      .catch((error: unknown) => {
        this.reject(error);
      });
  };

  render = (
    onPending: () => ReactElement<any, any> | JSX.Element | null,
    onReject: (error: unknown) => ReactElement<any, any> | JSX.Element | null,
    onResolve: (value: T) => ReactElement<any, any> | JSX.Element | null
  ) => {
    if (this.isResolved) return onResolve(this.value as T);
    if (this.isRejected) return onReject(this.error);
    return onPending();
  };
}

export function usePromise<T>(fn: () => Promise<T> | false, change: unknown[] = []) {
  const [state, setState] = useState<PromiseState<T>>(new PromiseState(fn));

  state.setChangeFn(setState);

  useEffect(() => {
    state.reset(fn);

    return () => {
      state.clean();
    };
  }, change);

  return state;
}

export function useDeepComparePromise<T>(fn: () => Promise<T> | false, change: unknown[] = []) {
  const [state, setState] = useState<PromiseState<T>>(new PromiseState(fn));

  state.setChangeFn(setState);

  useDeepCompareEffect(() => {
    state.reset(fn);

    return () => {
      state.clean();
    };
  }, change);

  return state;
}
