import { es, enUS, pt } from "date-fns/locale";
import {
  format,
  formatDistance,
  Locale,
  formatDistanceToNowStrict,
  formatDistanceToNow,
  formatDistanceStrict,
} from "date-fns";
import { useTranslation } from "react-i18next";
import { newDate } from "@/utils/newDate";
import { capitalize } from "lodash";
import { Lang } from "@/translations/AvailableLanguages";
import { TFunction } from "i18next";

function assertDate(fn: (date: Date) => string) {
  return (date: Date | string) => {
    const realDate = date instanceof Date ? date : newDate(date);
    return fn(realDate);
  };
}

function assertTwoDate(fn: (date1: Date, date2: Date) => string) {
  return (date1: Date | string, date2: Date | string) => {
    const realDate1 = date1 instanceof Date ? date1 : newDate(date1);
    const realDate2 = date2 instanceof Date ? date2 : newDate(date2);
    return fn(realDate1, realDate2);
  };
}

const localeMap: Record<Lang, Locale> = {
  pt: pt,
  es: es,
  en: enUS,
};

const mapLocale = (locale: string) => localeMap[locale as Lang] || es;

export const shortDate = (t: TFunction, locale: string, ciMode: boolean = false) =>
  assertDate((date) =>
    format(date, ciMode ? "dd/MM/yyyy" : t("common.dates.shortDate", "dd/MM/yyyy"), { locale: mapLocale(locale) })
  );

export const shortDateWithTime = (t: TFunction, locale: string, ciMode: boolean = false) =>
  assertDate((date) =>
    format(date, ciMode ? "dd/MM/yyyy HH:mm" : t("common.dates.shortDateWithTime", "dd/MM/yyyy HH:mm"), {
      locale: mapLocale(locale),
    })
  );

export const timeOnly = () => assertDate((date) => format(date, "HH:mm"));

export const microDate = (t: TFunction, locale: string, ciMode: boolean = false) =>
  assertDate((date) =>
    format(date, ciMode ? "d/M/yy" : t("common.dates.microDate", "d/M/yy"), { locale: mapLocale(locale) })
  );

export const microMonth = (t: TFunction, locale: string) =>
  assertDate((date) => capitalize(format(date, "MMM yy", { locale: mapLocale(locale) })));

export const customDateFormat = (customFormat: string, locale: string) =>
  assertDate((date) =>
    format(date, customFormat, {
      locale: mapLocale(locale),
    })
  );

export function useDateFns() {
  const { t, i18n } = useTranslation();

  const locale = mapLocale(i18n.language);
  const ciMode = i18n.language === "cimode";

  return {
    shortDate: shortDate(t, i18n.language, ciMode),
    shortDateWithTime: shortDateWithTime(t, i18n.language, ciMode),
    microDate: microDate(t, i18n.language, ciMode),
    microMonth: microMonth(t, i18n.language),
    timeOnly: timeOnly(),
    formatDistance: assertTwoDate((date, baseDate) => formatDistance(date, baseDate, { locale })),
    formatDistanceStrict: assertTwoDate((date, baseDate) => formatDistanceStrict(date, baseDate, { locale })),
    formatDistanceToNow: assertDate((date) => formatDistanceToNow(date, { locale })),
    formatDistanceToNowStrict: (
      date: string | Date,
      { unit }: { unit?: "day" | "month" | "year" | "second" | "minute" | "hour" } = {}
    ) => assertDate((date) => formatDistanceToNowStrict(date, { locale, unit }))(date),
    locale,
  };
}
