import { FC } from "react";
import { Card, CardContent, Grid, IconButton } from "@mui/material";
import Page from "@/components/Page";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router";
import { TeamForm } from "@/modules/teams/components/custom-teams/TeamForm";
import useAuth from "@/hooks/useAuth";
import { ErrorBox } from "@/components/ErrorBox";

const CreateTeamPage: FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  const handleComplete = () => navigate("../");

  return (
    <Page
      track={{ pageName: "dashboard.createTeam" }}
      locales={{
        title: t("team.create.title"),
        headTitle: t("team.create.title"),
      }}
      containerWidth="lg"
      preButton={
        <IconButton onClick={goBack} sx={{ mr: 0 }}>
          <ArrowBackIcon />
        </IconButton>
      }
    >
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={8}>
          <Card>
            <CardContent>
              {user?.employeeReference ? (
                <TeamForm
                  handleStopUpdating={handleComplete}
                  handleSuccessUpdated={handleComplete}
                  canEditManagers={false}
                  defaultManagers={[
                    {
                      id: user?.employeeReference,
                      fullName: user?.fullName,
                      // FIXME: This is a hack to make the form work
                      principalManagerId: "",
                    },
                  ]}
                />
              ) : (
                <ErrorBox error={t("team.edit.errors.managerIsRequired")} />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
};

export default CreateTeamPage;
