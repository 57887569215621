import { FC } from "react";
import { Container, Divider, Grid, Link, Tab, Tabs, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMatch, useNavigate } from "react-router";
import { isNil } from "lodash";
import { HELP_LINKS } from "@/helpLinks";

const TAB_TEAMS_VALUE = "/teams";
const TAB_CUSTOM_TEAMS_VALUE = "/teams/custom";

const TeamsLayoutPage: FC = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleTabClick = (_, link: string) => {
    navigate(link);
  };

  const match = useMatch("/teams");
  const tabValue = !isNil(match) ? TAB_TEAMS_VALUE : TAB_CUSTOM_TEAMS_VALUE;
  const matchCustomTeams = useMatch("/teams/custom");

  return (
    <Container
      maxWidth={"xxl"}
      sx={{
        display: "flex",
        flexDirection: "column",
        mt: 4,
        pb: 1,
      }}
    >
      <Typography color="textPrimary" variant="h5">
        {t("teams.title")}
      </Typography>

      <Typography color="textPrimary" variant="body1" mt={1}>
        {t("teams.home.subtitle")}
        {matchCustomTeams && (
          <Link ml={1} variant="body1" target="_blank" href={HELP_LINKS.customTeams}>
            {t("teams.home.subtitleLink")}
          </Link>
        )}
      </Typography>

      <Grid container mt={3}>
        <Grid item xs={12}>
          <Tabs value={tabValue} onChange={handleTabClick} aria-label="basic tabs example">
            <Tab value={TAB_TEAMS_VALUE} label={t("teams.tabs.adminTeams")} />
            <Tab value={TAB_CUSTOM_TEAMS_VALUE} label={t("teams.tabs.adminCustomTeams")} />
          </Tabs>
          <Divider />
        </Grid>
      </Grid>
      {children}
    </Container>
  );
};

export default TeamsLayoutPage;
