import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useScrollReset = (): null => {
  const location = useLocation();

  useEffect(() => {
    document.querySelector("#content-scroll-element")?.scrollTo(0, 0);
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

export default useScrollReset;
