import { omit, uniqBy } from "lodash";
import { SearchEmployeesParams, searchEmployee } from "@/services/employeeService";
import { searchUsers } from "@/services/userService";
import { EmployeeBasics } from "@/types/common";
import { User, OldRoleCode } from "@/types/user";
import { FC, useEffect, useState } from "react";
import {
  AutocompleteOption,
  InputAutocomplete,
  InputAutocompleteProps,
  OptionWithAvatar,
  isOptionItemLoading,
} from "./InputAutocomplete";
import { usePager } from "@/hooks/usePager";
import { AutocompleteChangeReason, Skeleton } from "@mui/material";
import { useDebounce } from "@/hooks/useDebounce";

type AutocompleteOptionWithAvatar = AutocompleteOption & { avatar?: string };

type InputAutocompleteEmployeeProps = InputAutocompleteProps<AutocompleteOption> & {
  readonly?: boolean;
  userRole?: OldRoleCode[];
  showFormer?: boolean;
  labelWithEmail?: boolean;
  disabledIds?: string[];
  pageSize?: number;
  onChangeEmployee?: (value: EmployeeBasics | string | null) => void;
};

export const InputAutocompleteEmployee: FC<Omit<InputAutocompleteEmployeeProps, "options">> = ({ ...props }) => {
  const { page, pageSize, stop, restart, loading, goNextPage, setLoading, isLastPage } = usePager(
    undefined,
    props.pageSize
  );
  const [search, setSearch] = useState("");
  const [employees, setEmployees] = useState<EmployeeBasics[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const debouncedSearch = useDebounce(search, 300);

  const userRole = props.userRole || [];
  const isRoleManagerRequired = userRole.includes(OldRoleCode.MANAGER);
  const rolesWithoutManager = userRole.filter((ur) => ur !== OldRoleCode.MANAGER);

  useEffect(() => {
    let wasUnmounted = false;
    const executeEmployeePromise = async () => {
      if (userRole.length === 0 || isRoleManagerRequired) {
        setLoading(true);

        const params: SearchEmployeesParams = {
          manager: isRoleManagerRequired,
          showFormer: props.showFormer,
          page,
          pageSize,
        };

        if (debouncedSearch) {
          params.searchTerm = debouncedSearch;
        }
        if (props.readonly && props.value) {
          params.id = props.value;
        }

        const employeesResponse = await searchEmployee(params, String(page));
        if (wasUnmounted) return;

        setEmployees((e) => {
          const newEmployees = uniqBy(
            [...e, ...employeesResponse.elements.filter((elem) => !props.disabledIds?.includes(elem.id))],
            "id"
          );

          if (isLastPage(employeesResponse)) {
            stop();
          }

          return newEmployees;
        });

        setLoading(false);
      }
    };

    executeEmployeePromise().catch(() => {});

    return () => {
      wasUnmounted = true;
    };
  }, [page, debouncedSearch]);

  useEffect(() => {
    const executeUsersPromise = async () => {
      const usersResponse = await searchUsers({ role: rolesWithoutManager });
      setUsers(usersResponse);
    };

    if (rolesWithoutManager.length > 0) {
      executeUsersPromise();
    }
  }, [userRole]);

  const handleSearchChange = (s: string) => {
    if (search === s) return;

    setSearch(s);
    restart();
  };

  const mappedOptions: AutocompleteOptionWithAvatar[] = [
    ...employees.map((e) => ({
      value: e.id,
      label: props.labelWithEmail ? `${e.name} (${e.email})` : e.name,
      avatar: e.avatar,
    })),
    ...users.map((u) => ({
      value: u.employeeReference as string,
      label: `${u.firstName} ${u.lastName}`,
      avatar: u.avatar,
    })),
  ];

  const handleChange = (evt, value: string | null, reason: AutocompleteChangeReason) => {
    const selectedEmployee = employees?.find((e) => e.id === value) ?? null;
    props.onChange?.(evt, value, reason);
    props.onChangeEmployee?.(selectedEmployee);
  };

  return (
    <InputAutocomplete
      {...omit(props, [
        "onChange",
        "onChangeEmployee",
        "userRole",
        "showFormer",
        "labelWithEmail",
        "disabledIds",
        "pageSize",
        "disabled",
        "readonly",
      ])}
      sx={{ minWidth: 260, ...props.sx }}
      options={mappedOptions}
      renderOption={(props, option) =>
        isOptionItemLoading(option) ? (
          <Skeleton key={option.value} variant={"rectangular"} width={"100%"} height={"3px"} />
        ) : (
          <OptionWithAvatar key={option.value} props={props} option={option} />
        )
      }
      handleSearchChange={handleSearchChange}
      onChange={handleChange}
      loadingNextPage={loading}
      onScrollEnd={goNextPage}
      disabled={props.readonly || props.disabled}
    />
  );
};

InputAutocompleteEmployee.defaultProps = {
  labelWithEmail: false,
  disabledIds: [],
  readonly: false,
};
