import { Box } from "@mui/material";
import type { FC } from "react";

const LogoSpinner: FC = () => {
  return (
    <Box
      sx={{
        display: "grid",
        placeItems: "center",
        height: 60,
        overflow: "hidden",
      }}
    >
      <video autoPlay loop muted src="/static/logo.mov" height="62" />
    </Box>
  );
};

export default LogoSpinner;
