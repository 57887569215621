import type { FC, ReactNode } from "react";
import useAuth from "@/hooks/useAuth";
import NotFound from "@/pages/NotFound";
import { userHasRoles } from "@/utils/userHasRole";
import { Outlet } from "react-router-dom";
import { OldRoleCode } from "@/types/user";

interface RoleGuardProps {
  roles: OldRoleCode | OldRoleCode[];
  children?: ReactNode;
}

export const RoleGuard: FC<RoleGuardProps> = ({ roles, children }) => {
  const auth = useAuth();

  if (!userHasRoles(auth.user, roles)) {
    return <NotFound />;
  }

  return children ? <>{children}</> : <Outlet />;
};
