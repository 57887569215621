import { MetricDataFilter } from "@/modules/board/models/metricDataSource";
import { FilterHelper } from "@/services/helpers/reportTransformer/FilterHelper";
import { Report } from "@/services/reportService";

export function filterReport(report: Report, filterDefinition: MetricDataFilter): Report {
  const filterHelper = new FilterHelper(filterDefinition);

  return {
    ...report,
    data: filterHelper.filterRows(report.data),
  };
}
