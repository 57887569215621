import type { FC } from "react";
import { Theme, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import type { SxProps } from "@mui/system";
import { ThemeName } from "@/types/constants";
import { LogoColor } from "@/theme/logo";

interface LogoProps {
  theme?: LogoColor;
  sx?: SxProps<Theme>;
}

const LogoRoot = styled("svg")({});

export const LaraLogo: FC<LogoProps> = ({ theme, ...props }) => {
  const generalTheme = useTheme();
  const fill = theme || (generalTheme.palette.mode === ThemeName.LIGHT ? LogoColor.BLUE : LogoColor.WHITE);

  return (
    <LogoRoot height="50.454" viewBox="0 0 100 50.454" version="1.1" {...props}>
      <title>Lara</title>
      <path
        d="M 0.039 38.774 L 0.039 11.677 L 5.12 11.677 L 5.12 34.172 L 16.912 34.172 L 16.912 38.774 Z"
        fill={fill}
        origin="2.969 0.506"
      />
      <polygon
        points="32.681 21.519 40.863 38.777 46.308 38.777 33.336 11.677 32.12 11.677 19.053 38.777 24.5 38.777 32.681 21.519"
        fill={fill}
        origin="1.141 0.506"
      />
      <polygon
        points="86.33 21.519 94.511 38.777 99.961 38.777 86.982 11.677 85.754 11.677 72.703 38.777 78.153 38.777 86.33 21.519"
        fill={fill}
        origin="-0.828 0.506"
      />
      <path
        d="M 93.599 43.334 C 91.903 44.757 89.246 45.686 86.351 45.686 C 83.454 45.686 80.797 44.757 79.102 43.334 L 72.7 43.334 C 74.717 47.477 80.079 50.454 86.351 50.454 C 92.624 50.454 97.985 47.477 100 43.334 Z"
        fill={LogoColor.LIGHT_BLUE}
        origin="-0.828 -2.536"
      />
      <path
        d="M 64.986 38.774 L 53.833 24.906 L 53.833 23.804 L 58.306 23.804 C 60.911 23.804 62.748 22.333 62.748 20.065 C 62.748 17.748 60.911 16.279 58.306 16.279 L 53.229 16.279 L 53.229 38.774 L 48.147 38.774 L 48.147 11.677 L 58.597 11.677 C 64.412 11.677 67.912 15.387 67.912 20.049 C 67.912 23.835 65.065 27.048 61.807 27.446 L 71.251 38.758 L 64.988 38.758 Z"
        fill={fill}
        origin="0.086 0.506741"
      />
    </LogoRoot>
  );
};
