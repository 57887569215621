import { MetricDataSource } from "@/modules/board/models/metricDataSource";
import { joinReports } from "./joinReports";
import { Report } from "@/services/reportService";
import { transformReport } from "@/services/helpers/reportTransformer/transformReport";

export function mergeReport(accumulatedReport: Report, dataSource: MetricDataSource, report: Report): Report {
  const transformed = transformReport(dataSource.transform, report);

  if (!dataSource.join) {
    return {
      headers: { ...accumulatedReport.headers, ...transformed.headers },
      data: [...accumulatedReport.data, ...(transformed.data ?? [])],
    };
  }

  return joinReports(accumulatedReport, transformed, dataSource.join);
}
