import useAuth from "@/hooks/useAuth";
import { PromiseState, usePromise } from "@/hooks/usePromise";
import { match } from "@/utils/match";
import type { JWTUser, Permission } from "@alanszp/jwt";
import { isString, uniqBy } from "lodash";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { usePager } from "./usePager";
import { getPermissions } from "@/services/authService";

interface UserPermissionResponse {
  granted: boolean;
  state: PromiseState<boolean>["status"];
  render: PromiseState<boolean>["render"];
}

/**
 * Internal hook to check permissions, do not export
 * Given a bag of permissions, and a function to check them, it returns the granted status and the state of the check
 */
const useInternalPermissionCheck = (
  check: (jwtUser: JWTUser) => Promise<boolean>,
  initialValue: boolean = false
): UserPermissionResponse => {
  const { jwtUser } = useAuth();
  const permissionTestPromise = usePromise((): Promise<boolean> => {
    if (!jwtUser) {
      return Promise.resolve(false);
    }
    return check(jwtUser);
  }, [jwtUser]);

  if (permissionTestPromise.status === "rejected") {
    // Send to Sentry.
    console.error("Error evaluating permissions", permissionTestPromise.error);
  }

  return {
    granted: permissionTestPromise.value ?? initialValue,
    state: permissionTestPromise.status,
    render: permissionTestPromise.render,
  };
};

type BasePermissionCheck = {
  initialValue?: boolean;
};

interface SomePermissionCheck extends BasePermissionCheck {
  code: string[];
  strategy: "some";
}

interface EveryPermissionCheck extends BasePermissionCheck {
  code: string[];
  strategy: "every";
}

export interface SinglePermissionCheck extends BasePermissionCheck {
  code: string;
  strategy: "single";
}

export interface DenyPermissionCheck extends BasePermissionCheck {
  strategy: "deny";
}

export type PermissionCheckStrategy =
  | SomePermissionCheck
  | EveryPermissionCheck
  | SinglePermissionCheck
  | DenyPermissionCheck;
export type InferablePermissionCheck = InferPermissionCheck | PermissionCheckStrategy;

export type InferPermissionCheck = Omit<SinglePermissionCheck, "strategy"> & {
  strategy?: undefined;
};

export function isInferPermissionCheck(options: InferablePermissionCheck): options is InferPermissionCheck {
  return "code" in options && isString(options.code) && (!("strategy" in options) || options.strategy === undefined);
}

export async function evaluatePermissionStrategy(
  jwtUser: JWTUser,
  options: InferablePermissionCheck
): Promise<boolean> {
  const permissionCheckStrategy: PermissionCheckStrategy = isInferPermissionCheck(options)
    ? { ...options, strategy: "single" }
    : options;

  return match(permissionCheckStrategy, "strategy", {
    single: ({ code }) => jwtUser.hasPermission(code),
    every: ({ code }) => jwtUser.hasEveryPermission(code),
    some: ({ code }) => jwtUser.hasSomePermission(code),
    deny: () => false,
  });
}

/**
 * Intended to be used in a guard to check permissions programmatically
 */
export const usePermissionStrategy = (options: InferablePermissionCheck): UserPermissionResponse => {
  return useInternalPermissionCheck(
    (jwtUser) => evaluatePermissionStrategy(jwtUser, options),
    options.initialValue ?? false
  );
};

/**
 * Custom hook to check if a user has a specific permission
 * If the user is not logged in, the permission is considered false
 * @param permission Permission code to check
 * @param initialValue Initial value of the permission, it's used while the permission check is loading
 * @returns Granted and state
 * @example
 * const { granted, status } = usePermission("test:test");
 */
export const usePermission = (code: string, initialValue: boolean = false): UserPermissionResponse => {
  return usePermissionStrategy({ code, strategy: "single", initialValue });
};

/**
 * Custom hook to check if a user has all the permissions in a list
 * @param permissions List of permissions to check
 * @param initialValue Initial value of the permission, it's used while the permission check is loading
 * @returns Granted and state
 * @example
 * const { granted, status } = useEveryPermission(["test:test", "test:other"]);
 */
export const useEveryPermission = (codes: string[], initialValue: boolean = false): UserPermissionResponse => {
  return usePermissionStrategy({ code: codes, strategy: "every", initialValue });
};

/**
 * Custom hook to check if a user has at least one of the permissions in a list
 * @param permissions List of permissions to check
 * @param initialValue Initial value of the permission, it's used while the permission check is loading
 * @returns Granted and state
 * @example
 * const { granted, status } = useSomePermission(["test:test", "test:other"]);
 */
export const useSomePermission = (codes: string[], initialValue: boolean = false): UserPermissionResponse => {
  return usePermissionStrategy({ code: codes, strategy: "some", initialValue });
};

interface UsePermissionListResponse {
  permissions: Permission[];
  loading: boolean;
  firstLoading: boolean;
  goNextPage: () => void;
  restart: () => void;
  isEmpty: boolean;
  isStop: boolean;
}

/**
 * Paginated list of permissions
 */
export function usePermissionList(): UsePermissionListResponse {
  const { enqueueSnackbar } = useSnackbar();
  const { page, pageSize, loading, setLoading, goNextPage, restart, isLastPage, stop, isStop } = usePager(1, 200);
  const [permissions, setPermissions] = useState<Permission[]>([]);

  useEffect(() => {
    async function loadNextPermissionsPage() {
      setLoading(true);

      try {
        const permissionsResponse = await getPermissions(page, pageSize);
        setPermissions((prevPerm) => uniqBy([...prevPerm, ...permissionsResponse.elements], (b) => b.code));

        if (isLastPage(permissionsResponse)) {
          stop();
        }
      } catch (error) {
        enqueueSnackbar("Error loading permissions page, contact tech team", { variant: "error" });
      } finally {
        setLoading(false);
      }
    }

    loadNextPermissionsPage();
  }, [page, pageSize]);

  const firstLoading = permissions.length === 0 && loading;
  const isEmpty = permissions.length === 0 && !loading;

  return { permissions, loading, firstLoading, goNextPage, restart, isEmpty, isStop };
}
