import { severityList } from "@/services/severityService";
import { Severity } from "@/types/common";
import { riskCaseSeverityLocale } from "@/utils/localizeConstants";
import { Chip } from "@mui/material";
import { isNil } from "lodash";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { SelectInput, SelectInputProps } from "./SelectInput";
import SeverityChip from "./SeverityChip";

const DEFAULT_VALUE = "all";

type SelectSeverityProps = Omit<SelectInputProps, "options"> & { hideAllOption?: boolean };

export const SelectSeverity: FC<SelectSeverityProps> = (props) => {
  const { t } = useTranslation();
  return (
    <SelectInput
      label={t("common.filters.labels.severity")}
      {...props}
      defaultOption={
        props.hideAllOption ? undefined : { label: t("questions.home.filters.allFeminine"), value: DEFAULT_VALUE }
      }
      options={severityList.map((s) => ({ value: s, label: riskCaseSeverityLocale(t, s) }))}
      renderValue={(value: (Severity | typeof DEFAULT_VALUE)[]) => {
        if (value[0] === DEFAULT_VALUE || isNil(value[0])) {
          return (
            <Chip
              size="small"
              label={t("questions.home.filters.allFeminine")}
              sx={{
                border: "2px color gray",
              }}
            />
          );
        }
        return <SeverityChip severity={value[0] as Severity} />;
      }}
    />
  );
};
