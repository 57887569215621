import {
  FilterConfiguration,
  FilterOperatorType,
  FilterType,
  createBooleanFilterConfiguration,
  createDateFilterConfiguration,
  createDriverFilterConfiguration,
  createEmployeeFilterConfiguration,
  createHelpdeskFeedbackFilterConfiguration,
  createLeavingReasonTypeFilterConfiguration,
  createNumericFilterConfiguration,
  createSelectFilterConfiguration,
  createStringFilterConfiguration,
  createSubDriverFilterConfiguration,
  createTeamFilterConfiguration,
} from "@/components/advanced-filters/FilterConfiguration";
import { extractOperatorFromKey } from "@/components/advanced-filters/utils";
import { EmployeeFieldTranslationGetter, EmployeeFieldWithTranslation } from "@/hooks/useEmployeeFields";
import {
  ViewOrgDataScope,
  overrideActiveFilterComponentForViewOrgScope,
} from "@/modules/board/components/BoardSwitchScope";
import { DateFieldReference, MetricWidget, Widget, WidgetMetric, isMetricWidget } from "@/modules/board/models/board";
import { MetricDataQueryFilter } from "@/modules/board/models/metricDataSource";
import { retrocompatibleMetricData } from "@/services/boardService";
import {
  DateRangeEnumTypeConfig,
  EmployeeFieldType,
  EmployeeFieldValues,
  getEmployeeFieldValues,
} from "@/services/employeeService";
import { ALL_MODULE_VALUES, ALL_REPORTS_CHAT_STATUS_VALUES, FAVORABILITY_OPTIONS } from "@/types/common";
import { FavorabilityGender, favorabilityLocale, moduleLocale, chatStatusLocale } from "@/utils/localizeConstants";
import { countryLocale } from "@/utils/localizeCountry";
import { JWTUser } from "@alanszp/jwt";
import { Typography } from "@mui/material";
import { TFunction } from "i18next";
import { compact, isArray, partition, uniqBy } from "lodash";

const CUSTOM_FILTERS = ["reachable", "contactEnabled"] as const;

type FilterFactory = (field: EmployeeFieldWithTranslation, locale: string, t: TFunction) => FilterConfiguration;

const FILTER_FACTORY_BY_EMPLOYEE_FIELD_TYPE: Record<EmployeeFieldType, FilterFactory> = {
  [EmployeeFieldType.TEXT]: (field) =>
    createStringFilterConfiguration({ name: field.fieldCode, label: field.translation }),
  [EmployeeFieldType.NUMBER]: (field) =>
    createNumericFilterConfiguration({ name: field.fieldCode, label: field.translation }),
  [EmployeeFieldType.BOOLEAN]: (field) =>
    createBooleanFilterConfiguration({ name: field.fieldCode, label: field.translation }),
  [EmployeeFieldType.SELECT]: (field, locale) =>
    createSelectFilterConfiguration({
      name: field.fieldCode,
      label: field.translation,
      values: (filters) =>
        getEmployeeFieldValues(field.fieldCode, filters)
          .then((response: EmployeeFieldValues) => {
            return response.fieldValues.map((fieldValue) => ({
              label: response.fieldName === "country" ? countryLocale(locale, fieldValue) : fieldValue,
              value: fieldValue,
            }));
          })
          .catch((error) => {
            console.error("errorGettingEmployeeFieldValue", error);
            return [];
          }),
    }),
  [EmployeeFieldType.DATE]: (field) =>
    createDateFilterConfiguration({
      name: field.fieldCode,
      label: field.translation,
      required: false,
      defaultValue: undefined,
    }),
  [EmployeeFieldType.TEAM]: (field) =>
    // TODO Make a new team selector component, that filters by direct, indirect and custom teams based on field config
    createTeamFilterConfiguration({ name: field.fieldCode, label: field.translation }),
  [EmployeeFieldType.DATE_RANGE_ENUM]: (field, locale): FilterConfiguration => {
    return createSelectFilterConfiguration({
      name: field.fieldCode,
      label: field.translation,
      values: (field.typeConfig as DateRangeEnumTypeConfig).steps.map((step) => ({
        label: step.label[locale],
        value: step.name,
      })),
    });
  },
  [EmployeeFieldType.EMPLOYEE]: (field) =>
    // TODO Support type config to filter by roles?
    createEmployeeFilterConfiguration({ name: field.fieldCode, label: field.translation }),
};

const FILTER_FACTORY_FOR_CUSTOM_FILTERS: Record<(typeof CUSTOM_FILTERS)[number], FilterFactory> = {
  reachable: (field, _, t) =>
    createSelectFilterConfiguration({
      name: field.fieldCode,
      label: t("reporting.list.filters.channel"),
      operators: [FilterOperatorType.EQUALS],
      values: [
        { value: "true", label: t("reporting.list.filters.reachable.withAtLeastOne") },
        { value: "false", label: t("reporting.list.filters.reachable.withoutChannels") },
      ],
      overrideActiveFilterLabel(filter, _o, value) {
        return (
          <>
            <Typography variant="body2">
              {value === "true"
                ? t("reporting.list.filters.reachable.withAtLeastOne")
                : t("reporting.list.filters.reachable.withoutChannels")}
            </Typography>
            <Typography variant="body2" fontWeight="bold">
              {filter.label}
            </Typography>
          </>
        );
      },
    }),
  // Contact enabled filter has a custom filter chip
  contactEnabled: (field, _, t) =>
    createBooleanFilterConfiguration({
      name: field.fieldCode,
      label: field.translation,
      overrideActiveFilterLabel(_, __, value) {
        return (
          <Typography variant="body2" fontWeight="bold">
            {value === "true"
              ? t("reporting.list.filters.contactEnabled.activeChipLabel")
              : t("reporting.list.filters.contactEnabled.inactiveChipLabel")}
          </Typography>
        );
      },
    }),
};

const filterFactoryByEmployeeField = (
  field: EmployeeFieldWithTranslation,
  locale: string,
  t: TFunction
): FilterConfiguration => {
  // Some fields have custom filters, we need to handle them separately
  if (CUSTOM_FILTERS.includes(field.typeConfig.customFilter as (typeof CUSTOM_FILTERS)[number])) {
    return FILTER_FACTORY_FOR_CUSTOM_FILTERS[field.typeConfig.customFilter as (typeof CUSTOM_FILTERS)[number]](
      field,
      locale,
      t
    );
  }

  const filterFactory = FILTER_FACTORY_BY_EMPLOYEE_FIELD_TYPE[field.type];
  return filterFactory(field, locale, t);
};

export const isMultipleOperator = (operator: FilterOperatorType): boolean =>
  [FilterOperatorType.IN, FilterOperatorType.NOT_IN].includes(operator);

/**
 * Filters available for all HE metrics
 */
export const commonHEFilters = (
  t: TFunction,
  getEmployeeFieldTranslation: EmployeeFieldTranslationGetter,
  locale: string,
  employeeFields: EmployeeFieldWithTranslation[]
): FilterConfiguration[] => {
  const dynamicFilters = employeeFields
    .filter((field) => field.options.actions.allowFiltering)
    .map((field) => filterFactoryByEmployeeField(field, locale, t));

  // TODO Support team filters to be constructed from custom, direct and indirect teams filters
  const [teamFilters, _otherFilters] = partition(dynamicFilters, (filter) => filter.type === FilterType.TEAM);

  const [leavingReasonTypeFilter, otherFilters] = partition(
    _otherFilters,
    (filter) => filter.name === "leavingReasonType"
  );

  return compact([
    ...otherFilters,
    // Check if the leavingReasonType filter is present, if so, we add it to the filters with custom values
    leavingReasonTypeFilter.length > 0
      ? createLeavingReasonTypeFilterConfiguration(t, getEmployeeFieldTranslation)
      : undefined,
    // For now, if is at least one team filter, we add the generic team filter
    teamFilters.length > 0
      ? createTeamFilterConfiguration({ name: "teamId", label: t("reporting.list.filters.team") })
      : undefined,
  ]);
};

async function calculateScopesForUser(t: TFunction, jwtUser: JWTUser | null) {
  const allOrgPermission = (await jwtUser?.hasPermission("reporting.scope.org:fetch")) ?? false;

  return [
    {
      value: ViewOrgDataScope.MY_EMPLOYEES,
      label: t("board.detail.widget.filters.viewOrgData.myEmployees"),
    },
    ...(allOrgPermission
      ? [
          {
            value: ViewOrgDataScope.ALL_ORG,
            label: t("board.detail.widget.filters.viewOrgData.allOrg"),
          },
        ]
      : []),
  ];
}

export function createReportScopeFilter(t: TFunction, jwtUser: JWTUser | null): FilterConfiguration {
  return createSelectFilterConfiguration({
    name: "viewOrgData",
    label: t("board.detail.widget.filters.viewOrgData.label"),
    values: () => calculateScopesForUser(t, jwtUser),
    disabled: jwtUser?.segmentReference === null,
    required: true,
    defaultValue: async () => {
      const scopes = await calculateScopesForUser(t, jwtUser);
      const allOrgValue = scopes.find((scope) => scope.value === ViewOrgDataScope.ALL_ORG);
      const myEmployeesValue = scopes.find((scope) => scope.value === ViewOrgDataScope.MY_EMPLOYEES);
      if (jwtUser?.segmentReference === null) {
        return allOrgValue ?? myEmployeesValue!;
      }
      return myEmployeesValue!;
    },
    operators: [FilterOperatorType.EQUALS],
    overrideActiveFilterComponent: overrideActiveFilterComponentForViewOrgScope,
  });
}

/**
 * Returns the global filters available in a Board
 */
export const getGlobalFiltersForBoard = (
  t: TFunction,
  getEmployeeFieldTranslation: EmployeeFieldTranslationGetter,
  locale: string,
  jwtUser: JWTUser | null,
  employeeFields: EmployeeFieldWithTranslation[],
  widgets?: Widget[]
): FilterConfiguration[] => {
  const widgetMetricAdditionalFilters: FilterConfiguration[] = uniqBy(
    (widgets?.filter(isMetricWidget) ?? [])
      .map((widget) => getFilterConfigurationsForWidget(widget, t, getEmployeeFieldTranslation, locale, employeeFields))
      .flat()
      .filter((filter) => {
        const employeeField = employeeFields.find((field) => field.fieldCode === filter.name);
        // If the field is an employee field, we only show it if it allows filtering
        return !employeeField || employeeField.options.actions.allowFiltering;
      }),
    "name"
  );

  return uniqBy(
    compact([
      createReportScopeFilter(t, jwtUser),
      createDateFilterConfiguration({
        name: "date",
        label: t("reporting.list.filters.date"),
        required: false,
        defaultValue: undefined, // Reset the default value
        operators: [FilterOperatorType.BETWEEN],
      }),
      ...commonHEFilters(t, getEmployeeFieldTranslation, locale, employeeFields),
      ...widgetMetricAdditionalFilters,
    ]),
    "name"
  );
};

// Source of truth for the filters available for each metric
// This is used to generate the filters in the advanced filters component
// Will come in handy when we add more metrics and create the widget wizard
export const AVAILABLE_FILTERS_FOR_METRIC: Record<
  WidgetMetric,
  (
    t: TFunction,
    getEmployeeFieldTranslation: EmployeeFieldTranslationGetter,
    locale: string,
    employeeFields: EmployeeFieldWithTranslation[]
  ) => FilterConfiguration[]
> = {
  [WidgetMetric.ENPS]: (t, org, locale, employeeFields) => [
    createDateFilterConfiguration({ name: "date", label: t("reporting.list.filters.date") }),
    ...commonHEFilters(t, org, locale, employeeFields),
  ],
  [WidgetMetric.ENPS_ANSWERS]: (t, org, locale, employeeFields) => [
    createDateFilterConfiguration({ name: "date", label: t("reporting.list.filters.date") }),
    ...commonHEFilters(t, org, locale, employeeFields),
    createSelectFilterConfiguration({
      name: "favorability",
      label: t("board.detail.widget.filters.favorability"),
      values: [
        { value: "neverContacted", label: t("board.detail.widget.filters.labels.neverContacted") },
        { value: "pendingAnswer", label: t("board.detail.widget.filters.labels.pendingAnswer") },
        { value: "detractor", label: t("board.detail.widget.filters.labels.detractor") },
        { value: "passive", label: t("board.detail.widget.filters.labels.passive") },
        { value: "promoter", label: t("board.detail.widget.filters.labels.promoter") },
      ],
      hideAsNewFilter: true,
    }),
    createSelectFilterConfiguration({
      name: "contactStatus",
      label: t("board.detail.widget.filters.contactStatus"),
      values: [
        { value: "neverContacted", label: t("board.detail.widget.filters.labels.neverContacted") },
        { value: "contacted", label: t("board.detail.widget.filters.labels.contacted") },
      ],
      hideAsNewFilter: true,
    }),
    createSelectFilterConfiguration({
      name: "answerStatus",
      label: t("board.detail.widget.filters.answerStatus"),
      values: [
        { value: "neverContacted", label: t("board.detail.widget.filters.labels.neverContacted") },
        { value: "pendingAnswer", label: t("board.detail.widget.filters.labels.pendingAnswer") },
        { value: "answered", label: t("board.detail.widget.filters.labels.answered") },
      ],
      hideAsNewFilter: true,
    }),
  ],
  [WidgetMetric.QUESTIONS]: (t, org, locale, employeeFields) => [
    ...commonHEFilters(t, org, locale, employeeFields),
    createSelectFilterConfiguration({
      name: "questionType",
      label: t("board.detail.widget.filters.questionType"),
      values: [
        { value: "mood", label: t("board.detail.widget.filters.labels.questionType.mood") },
        { value: "two_options", label: t("board.detail.widget.filters.labels.questionType.two_options") },
        { value: "three_options", label: t("board.detail.widget.filters.labels.questionType.three_options") },
        { value: "five_options", label: t("board.detail.widget.filters.labels.questionType.five_options") },
        { value: "seven_options", label: t("board.detail.widget.filters.labels.questionType.seven_options") },
        { value: "text", label: t("board.detail.widget.filters.labels.questionType.text") },
        { value: "nps", label: t("board.detail.widget.filters.labels.questionType.nps") },
        { value: "recognition", label: t("board.detail.widget.filters.labels.questionType.recognition") },
      ],
      hideAsNewFilter: true,
    }),
    createSelectFilterConfiguration({
      name: "questionModule",
      label: t("reporting.list.filters.module"),
      values: ALL_MODULE_VALUES.map((module) => ({ value: module, label: moduleLocale(t, module) })),
      hideAsNewFilter: true,
    }),
    createSelectFilterConfiguration({
      name: "answered",
      label: t("board.detail.widget.filters.answered"),
      values: [
        { value: "pendingAnswer", label: t("board.detail.widget.filters.labels.pendingAnswer") },
        { value: "answered", label: t("board.detail.widget.filters.labels.answered") },
      ],
      hideAsNewFilter: true,
      overrideActiveFilterLabel: (_, __, value) =>
        value === "answered"
          ? t("board.detail.widget.filters.labels.answeredFilterLabel")
          : t("board.detail.widget.filters.labels.pendingAnswerFilterLabel"),
    }),
    createSelectFilterConfiguration({
      name: "favorability",
      label: t("board.detail.widget.filters.favorability"),
      values: FAVORABILITY_OPTIONS.map((favorability) => ({
        value: favorability,
        label: favorabilityLocale(t, favorability, FavorabilityGender.FEMALE),
      })),
      hideAsNewFilter: true,
    }),
    createStringFilterConfiguration({
      name: "questionId",
      label: t("board.detail.widget.filters.questionId"),
      overrideActiveFilterLabel: (_, operator, value) => {
        const count = isMultipleOperator(operator) && isArray(value) ? value.length : 1;
        return t("board.detail.widget.filters.labels.questionId", { count });
      },
      hideAsNewFilter: true,
    }),
    createDriverFilterConfiguration({
      name: "driverCode",
      label: t("board.detail.widget.filters.driverCode"),
      hideAsNewFilter: true,
    }),
    createSubDriverFilterConfiguration({
      name: "subDriverCode",
      label: t("board.detail.widget.filters.subDriverCode"),
      hideAsNewFilter: true,
    }),
    createStringFilterConfiguration({
      name: "touchpointId",
      label: t("board.detail.widget.filters.touchpointId"),
      hideAsNewFilter: true,
    }),
    createStringFilterConfiguration({
      name: "touchpointName",
      label: t("board.detail.widget.filters.touchpointName"),
      hideAsNewFilter: true,
    }),
  ],
  [WidgetMetric.RESPONSE_RATE]: (t, org, locale, fields) => commonHEFilters(t, org, locale, fields),
  [WidgetMetric.INDIVIDUAL_RESPONSES]: (t, org, locale, employeeFields) => [
    ...commonHEFilters(t, org, locale, employeeFields),
    createSelectFilterConfiguration({
      name: "questionType",
      label: t("board.detail.widget.filters.questionType"),
      values: [
        { value: "mood", label: t("board.detail.widget.filters.labels.questionType.mood") },
        { value: "two_options", label: t("board.detail.widget.filters.labels.questionType.two_options") },
        { value: "three_options", label: t("board.detail.widget.filters.labels.questionType.three_options") },
        { value: "five_options", label: t("board.detail.widget.filters.labels.questionType.five_options") },
        { value: "seven_options", label: t("board.detail.widget.filters.labels.questionType.seven_options") },
        { value: "text", label: t("board.detail.widget.filters.labels.questionType.text") },
        { value: "nps", label: t("board.detail.widget.filters.labels.questionType.nps") },
        { value: "recognition", label: t("board.detail.widget.filters.labels.questionType.recognition") },
      ],
      hideAsNewFilter: true,
    }),
    createSelectFilterConfiguration({
      name: "questionModule",
      label: t("reporting.list.filters.module"),
      values: ALL_MODULE_VALUES.map((module) => ({ value: module, label: moduleLocale(t, module) })),
      hideAsNewFilter: true,
    }),
    createSelectFilterConfiguration({
      name: "answered",
      label: t("board.detail.widget.filters.answered"),
      values: [
        { value: "pendingAnswer", label: t("board.detail.widget.filters.labels.pendingAnswer") },
        { value: "answered", label: t("board.detail.widget.filters.labels.answered") },
      ],
      hideAsNewFilter: true,
      overrideActiveFilterLabel: (_, __, value) =>
        value === "answered"
          ? t("board.detail.widget.filters.labels.answeredFilterLabel")
          : t("board.detail.widget.filters.labels.pendingAnswerFilterLabel"),
    }),
    createSelectFilterConfiguration({
      name: "favorability",
      label: t("board.detail.widget.filters.favorability"),
      values: FAVORABILITY_OPTIONS.map((favorability) => ({
        value: favorability,
        label: favorabilityLocale(t, favorability, FavorabilityGender.FEMALE),
      })),
      hideAsNewFilter: true,
    }),
    createStringFilterConfiguration({
      name: "questionId",
      label: t("board.detail.widget.filters.questionId"),
      overrideActiveFilterLabel: (_, operator, value) => {
        const count = isMultipleOperator(operator) && isArray(value) ? value.length : 1;
        return t("board.detail.widget.filters.labels.questionId", { count });
      },
      hideAsNewFilter: true,
    }),
    createDriverFilterConfiguration({
      name: "driverCode",
      label: t("board.detail.widget.filters.driverCode"),
      hideAsNewFilter: true,
    }),
    createSubDriverFilterConfiguration({
      name: "subDriverCode",
      label: t("board.detail.widget.filters.subDriverCode"),
      hideAsNewFilter: true,
    }),
    createStringFilterConfiguration({
      name: "touchpointId",
      label: t("board.detail.widget.filters.touchpointId"),
      hideAsNewFilter: true,
    }),
    createStringFilterConfiguration({
      name: "touchpointName",
      label: t("board.detail.widget.filters.touchpointName"),
      hideAsNewFilter: true,
    }),
  ],
  [WidgetMetric.CASES]: (t, org, locale, fields) => [
    createSelectFilterConfiguration({
      name: "dateFieldFilter",
      label: t("board.detail.widget.filters.dateFieldFilter"),
      values: [
        { value: DateFieldReference.DETECTED_AT, label: t("board.detail.widget.filters.labels.detected_at") },
        { value: DateFieldReference.OPENED_AT, label: t("board.detail.widget.filters.labels.opened_at") },
        { value: DateFieldReference.CLOSED_AT, label: t("board.detail.widget.filters.labels.closed_at") },
      ],
      required: true,
      operators: [FilterOperatorType.EQUALS],
      hideAsNewFilter: true,
    }),
    ...commonHEFilters(t, org, locale, fields),
    createSelectFilterConfiguration({
      name: "status",
      label: t("board.detail.widget.filters.caseStatus"),
      values: [
        { value: "detected", label: t("board.detail.widget.filters.labels.detected") },
        { value: "inProgress", label: t("board.detail.widget.filters.labels.inProgress") },
        { value: "closed", label: t("board.detail.widget.filters.labels.closed") },
      ],
      hideAsNewFilter: true,
    }),
    createSelectFilterConfiguration({
      name: "severity",
      label: t("board.detail.widget.filters.severity"),
      values: [
        { value: "low", label: t("board.detail.widget.filters.labels.low") },
        { value: "medium", label: t("board.detail.widget.filters.labels.medium") },
        { value: "high", label: t("board.detail.widget.filters.labels.high") },
      ],
      hideAsNewFilter: true,
    }),
  ],
  [WidgetMetric.MOOD]: (t, org, locale, employeeFields) => commonHEFilters(t, org, locale, employeeFields),
  [WidgetMetric.MOOD_ANSWERS]: (t, org, locale, employeeFields) => [
    ...commonHEFilters(t, org, locale, employeeFields),
    createSelectFilterConfiguration({
      name: "answerStatus",
      label: t("board.detail.widget.filters.answerStatus"),
      values: [
        { value: "neverContacted", label: t("board.detail.widget.filters.labels.neverContacted") },
        { value: "pendingAnswer", label: t("board.detail.widget.filters.labels.pendingAnswer") },
        { value: "answered", label: t("board.detail.widget.filters.labels.answered") },
      ],
      hideAsNewFilter: true,
    }),
  ],
  [WidgetMetric.DRIVER]: (t, org, locale, employeeFields) => [
    ...commonHEFilters(t, org, locale, employeeFields),
    createDriverFilterConfiguration({
      name: "driverCode",
      label: t("board.detail.widget.filters.driverCode"),
      hideAsNewFilter: true,
    }),
    createSubDriverFilterConfiguration({
      name: "subDriverCode",
      label: t("board.detail.widget.filters.subDriverCode"),
      hideAsNewFilter: true,
    }),
  ],
  [WidgetMetric.DRIVER_ANSWERS]: (t, org, locale, employeeFields) => [
    ...commonHEFilters(t, org, locale, employeeFields),
    createDriverFilterConfiguration({
      name: "driverCode",
      label: t("board.detail.widget.filters.driverCode"),
      hideAsNewFilter: true,
    }),
    createSubDriverFilterConfiguration({
      name: "subDriverCode",
      label: t("board.detail.widget.filters.subDriverCode"),
      hideAsNewFilter: true,
    }),
    createSelectFilterConfiguration({
      name: "answerStatus",
      label: t("board.detail.widget.filters.answerStatus"),
      values: [
        { value: "neverContacted", label: t("board.detail.widget.filters.labels.neverContacted") },
        { value: "pendingAnswer", label: t("board.detail.widget.filters.labels.pendingAnswer") },
        { value: "answered", label: t("board.detail.widget.filters.labels.answered") },
      ],
      hideAsNewFilter: true,
    }),
  ],
  [WidgetMetric.LARA_SCORE]: (t, org, locale, employeeFields) => commonHEFilters(t, org, locale, employeeFields),
  [WidgetMetric.CHATS]: (t, org, locale, employeeFields) => [
    ...commonHEFilters(t, org, locale, employeeFields),
    createSelectFilterConfiguration({
      name: "module",
      label: t("reporting.list.filters.module"),
      values: ALL_MODULE_VALUES.map((module) => ({ value: module, label: moduleLocale(t, module) })),
      hideAsNewFilter: true,
    }),
    createSelectFilterConfiguration({
      name: "chatStatus",
      label: t("board.detail.widget.filters.chatStatus"),
      values: ALL_REPORTS_CHAT_STATUS_VALUES.map((status) => ({ value: status, label: chatStatusLocale(t, status) })),
      hideAsNewFilter: true,
    }),
    createStringFilterConfiguration({
      name: "touchpointId",
      label: t("board.detail.widget.filters.touchpointId"),
      hideAsNewFilter: true,
    }),
    createStringFilterConfiguration({
      name: "touchpointName",
      label: t("board.detail.widget.filters.touchpointName"),
      hideAsNewFilter: true,
    }),
  ],
  [WidgetMetric.EMPLOYEES]: (t, org, locale, employeeFields) => commonHEFilters(t, org, locale, employeeFields),
  [WidgetMetric.ONBOARDING_AND_OFFBOARDING]: (t, org, locale, employeeFields) =>
    commonHEFilters(t, org, locale, employeeFields),
  [WidgetMetric.METRIC_HELPDESK_CONVERSATIONS]: (t, org, locale, employeeFields) => [
    createDateFilterConfiguration({ name: "date", label: t("reporting.list.filters.date") }),
    ...commonHEFilters(t, org, locale, employeeFields),
    createHelpdeskFeedbackFilterConfiguration(t),
  ],
  [WidgetMetric.METRIC_HELPDESK_USED_ITEMS]: (t, org, locale, employeeFields) => [
    createDateFilterConfiguration({ name: "date", label: t("reporting.list.filters.date") }),
    ...commonHEFilters(t, org, locale, employeeFields),
    createHelpdeskFeedbackFilterConfiguration(t),
  ],
};

export function getFilterConfigurationsForWidget(
  widget: MetricWidget,
  t: TFunction,
  getEmployeeFieldTranslation: EmployeeFieldTranslationGetter,
  locale: string,
  employeeFields: EmployeeFieldWithTranslation[]
): FilterConfiguration[] {
  const widgetData = retrocompatibleMetricData(widget);
  const metrics = widgetData.sources.map((source) => source.query.metric);

  return uniqBy(
    metrics.flatMap((metric) =>
      getFilterConfigurationsForMetric(metric, t, getEmployeeFieldTranslation, locale, employeeFields)
    ),
    "name"
  );
}

export function getFilterConfigurationsForMetric(
  metric: WidgetMetric,
  t: TFunction,
  getEmployeeFieldTranslation: EmployeeFieldTranslationGetter,
  locale: string,
  employeeFields: EmployeeFieldWithTranslation[]
): FilterConfiguration[] {
  return AVAILABLE_FILTERS_FOR_METRIC[metric]?.(t, getEmployeeFieldTranslation, locale, employeeFields) ?? [];
}

export const OperatorEnum = {
  EQUAL: "eq",
  NOT_EQUAL: "notEq",
  INVALID_OPERATOR: "invalidOperator", // use for validation
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type OperatorEnum = (typeof OperatorEnum)[keyof typeof OperatorEnum];

export const DateOperatorEnum = {
  ...OperatorEnum,
  BETWEEN: "between",
  AFTER: "after",
  BEFORE: "before",
} as const;

export const DATE_OPERATOR_ENUM = Object.values(DateOperatorEnum);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DateOperatorEnum = (typeof DateOperatorEnum)[keyof typeof DateOperatorEnum];

export function mergeAndOverrideFilters(...filters: MetricDataQueryFilter[]): MetricDataQueryFilter {
  return Object.fromEntries(
    filters
      .reduce((acc, filter) => {
        Object.entries(filter).forEach(([key, value]) => {
          const [filterKey] = extractOperatorFromKey(key);

          // If the filter is already present in filters,
          // we have to remove it, no matter the operator

          // Remove the standalone filter
          acc.delete(filterKey);
          // Remove the filter with operators
          Object.values(FilterOperatorType).forEach((operator) => {
            const filterKeyWithOperator = `${filterKey}[${operator}]`;
            acc.delete(filterKeyWithOperator);
          });

          // Set the global filter
          acc.set(key, value.toString());
        });

        return acc;
      }, new URLSearchParams())
      .entries()
  );
}
