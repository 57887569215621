import * as Sentry from "@sentry/browser";
import { env } from "./config";

Sentry.init({
  dsn: "https://039862dac5484619be212d0abbab5203@o980590.ingest.sentry.io/5935089",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.captureConsoleIntegration({
      levels: ["error", "warn"],
    }),
  ],
  release: env.commit,
  tracesSampleRate: 0.75,
  enabled: env.isProd,
});
