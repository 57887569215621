import { ListResult } from "@alanszp/core";
import { useState } from "react";

interface UsePager {
  page: number;
  pageSize: number;
  goNextPage: () => void;
  setLoading: (loading: boolean) => void;
  stop: () => void;
  isStop: boolean;
  restart: () => void;
  isLastPage: <T>(pageObject: ListResult<T>) => boolean;
  loading: boolean;
}
export function usePager(defaultPage = 1, defaultPageSize = 10): UsePager {
  const [page, setPage] = useState(defaultPage);
  const [pageSize] = useState(defaultPageSize);
  const [loading, setLoading] = useState(false);
  const [stop, setStop] = useState<boolean>(false);

  const goNextPage = () => {
    if (loading || stop) {
      return;
    }

    setPage((p) => p + 1);
  };

  const handleStop = () => {
    setStop(true);
    setLoading(false);
  };

  const handleRestart = () => {
    setStop(false);
    setPage(defaultPage);
  };

  const isLastPage = <T>(pageObject: ListResult<T>): boolean => {
    return pageObject.page * pageObject.pageSize >= Number(pageObject.total);
  };

  return {
    page,
    pageSize,
    loading,
    setLoading,
    goNextPage,
    stop: handleStop,
    isStop: stop,
    restart: handleRestart,
    isLastPage,
  };
}
