import MuiTooltip, { TooltipProps as MuiTooltipProps } from "@mui/material/Tooltip";
import { FC } from "react";

export type TooltipProps = MuiTooltipProps;

export const Tooltip: FC<TooltipProps> = (props) => {
  return (
    <MuiTooltip enterTouchDelay={0} {...props}>
      {props.children}
    </MuiTooltip>
  );
};
