import tinygradient from "tinygradient";
import { LaraScoreAssessment } from "@/types/common";
import { uniqBy } from "lodash";
import { safeArrayIndex } from "@/utils/safeArrayIndex";

export const LARA_SCORE_ASSESSMENT_COLOR_MAP: Record<LaraScoreAssessment, number> = {
  [LaraScoreAssessment.CRITICAL]: 0,
  [LaraScoreAssessment.VERY_LOW]: 49,
  [LaraScoreAssessment.LOW]: 59,
  [LaraScoreAssessment.GOOD]: 60,
  [LaraScoreAssessment.GREAT]: 80,
  [LaraScoreAssessment.AMAZING]: 100,
};

export function laraScoreAssesmentColor(scoreAssessment: LaraScoreAssessment) {
  return GRADIENT_STEPS[LARA_SCORE_ASSESSMENT_COLOR_MAP[scoreAssessment]].toHex();
}

export function laraScoreColor(laraScore: number) {
  return `#${GRADIENT_STEPS[safeArrayIndex(laraScore)].toHex()}`;
}

const GRADIENT_STEPS = tinygradient([
  { color: "#ff4531", pos: 0 },
  { color: "#ff8181", pos: 0.59 },
  { color: "#f6c32b", pos: 0.6 },
  { color: "#75d275", pos: 1 },
]).rgb(100);

export interface GradientStop {
  offset: number;
  color: string;
  opacity: 1;
}

export function getGradientLaraScoreStops(minScore: number, maxScore: number): GradientStop[] {
  const gradients: GradientStop[] = [];
  const totalPercent = maxScore - minScore;
  const step = totalPercent / 100;

  for (let i = 99; i >= 0; i--) {
    gradients.push({
      offset: 99 - i,
      color: `#${GRADIENT_STEPS[safeArrayIndex(Math.floor(minScore + step * i))].toHex()}`,
      opacity: 1,
    });
  }

  return uniqBy(gradients, "color");
}
