import type { FC, ReactNode } from "react";
import { ListItem, ListItemAvatar, ListItemText, SvgIcon } from "@mui/material";

import { useTranslation } from "react-i18next";
import UserAvatar from "@/components/UserAvatar";
import { useNavigate } from "react-router-dom";

export interface EmployeeMetadataItemProps {
  primary: ReactNode | string;
  secondary?: ReactNode | string;
  icon?: typeof SvgIcon;
  avatar?: {
    src?: string;
    name: string;
  };
  link?: string;
}

const ListItemLinkeable: FC<{ link?: string; children }> = ({ link, children }) => {
  const navigate = useNavigate();
  const onClick = () => {
    if (link) {
      navigate(link);
    }
  };

  return Boolean(link) ? (
    <ListItem disableGutters sx={{ py: "6px" }} button onClick={onClick}>
      {children}
    </ListItem>
  ) : (
    <ListItem disableGutters sx={{ py: "6px" }}>
      {children}
    </ListItem>
  );
};

const EmployeeMetadataItem: FC<EmployeeMetadataItemProps> = (props) => {
  const { primary, secondary, icon: Icon, avatar, link } = props;
  const { t } = useTranslation();

  return (
    <ListItemLinkeable link={link}>
      {Icon && !avatar ? (
        <ListItemAvatar sx={{ minWidth: "0", mr: 1, height: "1rem" }}>
          <Icon fontSize="xsmall" />
        </ListItemAvatar>
      ) : null}
      {avatar ? (
        <ListItemAvatar sx={{ minWidth: "0", mr: 1 }}>
          <UserAvatar name={avatar.name} src={avatar.src} />
        </ListItemAvatar>
      ) : null}
      <ListItemText
        sx={{ m: 0 }}
        primaryTypographyProps={{ fontSize: "0.9rem", lineHeight: 1.1 }}
        secondaryTypographyProps={{ fontSize: "0.9rem", lineHeight: 1.1 }}
        primary={primary || t("employee.detail.layout.metadata.itemNotDefined")}
        secondary={secondary}
      />
    </ListItemLinkeable>
  );
};

export default EmployeeMetadataItem;
