import { useMemo, useRef, useState } from "react";
import type { FC } from "react";
import HelpIcon from "@mui/icons-material/HelpOutline";
import EmailIcon from "@mui/icons-material/Email";

import {
  Box,
  ButtonBase,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import useAuth from "@/hooks/useAuth";
import ArticleIcon from "@mui/icons-material/Article";
import { useTranslation } from "react-i18next";
import { userHasRoles } from "@/utils/userHasRole";
import { trackHelpContact, trackHelpOpenMenu, trackHelpWiki } from "./track";
import { HELP_LINKS } from "@/helpLinks";
import { OldRoleCode } from "@/types/user";

const WIKI_LINK = {
  [OldRoleCode.HRBP]: HELP_LINKS.home,
  [OldRoleCode.MANAGER]: HELP_LINKS.dashboardManagers,
};

const SUPPORT_LINK = "mailto:soporte@meetlara.ai";

const HelpPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const { user } = useAuth();
  const isManager = userHasRoles(user, OldRoleCode.MANAGER);

  const helpItem = useMemo(() => {
    const role = isManager ? OldRoleCode.MANAGER : OldRoleCode.HRBP;
    const link = WIKI_LINK[role];
    return {
      // i18next-extract-mark-context-next-line ["manager", "hrbp"]
      text: t(`navbar.help.popover.wiki`, { context: role }),
      link,
    };
  }, [isManager]);

  const handleOpen = (): void => {
    trackHelpOpenMenu();
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
          color: "white",
        }}
      >
        <Tooltip title={t("navbar.help.tooltip") || ""} placement="top">
          <HelpIcon fontSize="small" />
        </Tooltip>
      </IconButton>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {t("navbar.help.title")}
          </Typography>
        </Box>
        <Divider />
        <Box>
          <MenuItem component="a" href={helpItem.link} target="_blank" onClick={() => trackHelpWiki()}>
            <ListItemIcon sx={{ mr: 0, my: 1 }}>
              <ArticleIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              sx={{ textAlign: "left" }}
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  {helpItem.text}
                </Typography>
              }
            />
          </MenuItem>
        </Box>

        <Box>
          <MenuItem component="a" href={SUPPORT_LINK} onClick={() => trackHelpContact()}>
            <ListItemIcon sx={{ mr: 0, my: 1 }}>
              <EmailIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              sx={{ textAlign: "left" }}
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  {t("navbar.help.popover.contactSupport")}
                </Typography>
              }
            />
          </MenuItem>
        </Box>
      </Popover>
    </>
  );
};

export default HelpPopover;
