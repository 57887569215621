import type { FC } from "react";
import { GlobalStyles as Global } from "@mui/material";

const GlobalStyles: FC = () => (
  <Global
    styles={{
      "* :not(.mdxeditor *)": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
      },
      html: {
        webkitFontSmoothing: "antialiased",
        mozOsxFontSmoothing: "grayscale",
        height: "100%",
        width: "100%",
      },
      body: {
        height: "100%",
        width: "100%",
      },
      "#root": {
        height: "100%",
        width: "100%",
      },
      ".MuiSvgIcon-fontSizeXsmall": {
        fontSize: "1em",
      },
      ".PrivatePickersMonth-root": {
        background: "transparent",
        border: 0,
        fontSize: "0.75em",
      },
      ".PrivatePickersMonth-root:hover": {
        background: "#fafafa",
      },
      // Hack to fix the issue with text legends not being visible on hover in ApexCharts
      // This overrides the opacity set by the library for the text node but skips the background node
      "g.legend-mouseover-inactive:has(text)": {
        opacity: 1,
      },
      // Hack to fix the issue with the popover not being visible in mdxeditor
      ".mdxeditor-popup-container": {
        "z-index": "9999 !important",
      },
      "[data-radix-popper-content-wrapper]": {
        left: "64px !important",
      },
    }}
  />
);

export default GlobalStyles;
