import { MetricDataLimit, MetricDataLimitWithOffset } from "@/modules/board/models/metricDataSource";
import { Report } from "@/services/reportService";
import { isNumber, isObject } from "lodash";

function isMetricDataLimitWithOffset(value: unknown): value is MetricDataLimitWithOffset {
  const mapping = value as MetricDataLimitWithOffset;
  return isObject(mapping) && isNumber(mapping.limit) && isNumber(mapping.offset);
}

export function limitReport(report: Report, limitDefinition: MetricDataLimit): Report {
  const limit = (isMetricDataLimitWithOffset(limitDefinition) ? limitDefinition.limit : limitDefinition) ?? 0;
  const offset = (isMetricDataLimitWithOffset(limitDefinition) ? limitDefinition.offset : 0) ?? 0;

  if (!isNumber(limit) || limit <= 0) return report;

  return {
    headers: report.headers,
    data: report.data.slice(offset, offset + limit),
  };
}
