import reduce from "lodash/reduce";
import { useSearchParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import { parseArrayValue, safeSetValuesFromUrl } from "./utils";
import { forEach, isBoolean, isEmpty, isEqual, set } from "lodash";
import { FilterConfiguration, SelectFilterConfiguration } from "./FilterConfiguration";
import { objectToQueryParams, urlSearchParamsToObject } from "@/helpers/queryParams";
import { usePrevious } from "@/hooks/usePrevious";
import { trackEvent } from "@/lib/track";
import { valueFromFunctionOrValue } from "../FunctionOrValue";

const DEFAULT_FIELD_VALUE = null;

export type FormikHandleChangeType = {
  (e: React.ChangeEvent<any>): void;
  <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
    ? void
    : (e: string | React.ChangeEvent<any>) => void;
};

export enum FilterType {
  HIDDEN = "hidden",
  SELECT = "select",
  DATE = "date",
  AUTOCOMPLETE = "autoComplete",
  TEAM = "team",
  CHECKBOX = "checkbox",
  SEARCH = "search",
  SELECT_EMPLOYEE = "selectEmployee",
  SELECT_SEVERITY = "selectSeverity",
  SELECT_DRIVER = "selectDriver",
}

export const SEARCH_PARAMS_MAPPING_VALUES = new Map<string | null, unknown>([
  ["true", true],
  ["false", false],
]);

export interface UseFiltersState {
  filters: FilterConfiguration[];
  setProp: (filterName: string, propPath: string, value: unknown) => void;
  setDisabled: (filterName: string, disabled: boolean) => void;
  setValue: (filterName: string, newValue: any) => void;
  handleChange: FormikHandleChangeType;
  countFilterApplies: number;
  open: boolean;
  setOpen: (open) => void;
  values: {
    [field: string]: any;
  };
  filtersApplied: {
    [field: string]: any;
  };
}

function findFilterConfig(filters: FilterConfiguration[], filterName: string): FilterConfiguration | undefined {
  return filters.find((filter) => filter.name === filterName);
}

function replacePositionProp(filters: FilterConfiguration[], filterName: string, prop: string, value: unknown) {
  const newFilters = [...filters];
  const filter = findFilterConfig(newFilters, filterName);

  if (!filter) {
    console.error(`Cant find ${filterName} on ${JSON.stringify(filters)}`);
    return filters;
  }

  set(filter, prop, value);
  return newFilters;
}

export type FilterValues = Record<string, boolean | string | string[]>;

// TODO: try to remove this horrifying hackish solution to prevent loops
let triggerByUrlUpdate: boolean = false;

export function useFilters(
  filterConfiguration: FilterConfiguration[],
  useURLParameters = false,
  filterIdentifier = "default"
): UseFiltersState {
  const [filters, setFilters] = useState<FilterConfiguration[]>(filterConfiguration);
  const [open, setOpen] = useState<boolean>(false);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();

  function getDefaultValue(fieldName: string) {
    const defaultValue = findFilterConfig(filters, fieldName)?.defaultValue;
    return defaultValue !== undefined ? defaultValue : DEFAULT_FIELD_VALUE;
  }

  let initialFormikValues: FilterValues = {};

  if (isFirstRender) {
    initialFormikValues = filterConfiguration.reduce((acc, filter) => {
      acc[filter.name] = filter.defaultValue !== undefined ? filter.defaultValue : DEFAULT_FIELD_VALUE;

      return acc;
    }, {});

    if (useURLParameters) {
      initialFormikValues = safeSetValuesFromUrl(searchParams, initialFormikValues);
    }
  }

  const formik = useFormik({
    initialValues: initialFormikValues,
    onSubmit: () => {
      /*silence*/
    },
  });

  function setProp(filterName: string, propName: string, value: unknown) {
    setFilters((prevFilters) => replacePositionProp(prevFilters, filterName, propName, value));
  }

  function setValue(filterName: string, value: unknown) {
    const defaultValue = getDefaultValue(filterName);

    oldFiltersApplied && (oldFiltersApplied[filterName] = value === defaultValue ? undefined : value);
    formik.setFieldValue(filterName, value);
  }

  function setDisabled(filterName: string, disabled: boolean) {
    setFilters((prevFilters) => replacePositionProp(prevFilters, filterName, "fieldConfig.disabled", disabled));
  }

  const filtersApplied = useMemo(() => {
    const values = reduce(
      formik.values,
      (acc, value, prop) => {
        acc[prop] = getDefaultValue(prop) !== value ? value : undefined;
        return acc;
      },
      {}
    );

    if (!isEmpty(values) && !isFirstRender) {
      trackEvent(`${filterIdentifier}.applyFilters`, {
        values,
      });
    }

    forEach(filters, (filter) => {
      const fieldName = filter.name;
      const value = formik.values[fieldName];

      const isEmpty = value === null || value === filter.defaultValue;
      const visibleValue = valueFromFunctionOrValue(filter.visible, formik.values);
      const isVisible = isBoolean(visibleValue) ? visibleValue : true;

      if (!isVisible && !isEmpty) {
        formik.setFieldValue(fieldName, filter.defaultValue || null);
      }
    });

    return values;
  }, [formik.values]);

  const oldFiltersApplied = usePrevious(filtersApplied);

  useEffect(() => {
    if (useURLParameters && !isFirstRender && !isEqual(oldFiltersApplied, filtersApplied) && !triggerByUrlUpdate) {
      setSearchParams(objectToQueryParams({ ...urlSearchParamsToObject(searchParams), ...filtersApplied }));
    }

    triggerByUrlUpdate = false;
  }, [useURLParameters && filtersApplied]);

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  useEffect(() => {
    if (open) {
      trackEvent(`${filterIdentifier}.toggleFilters`);
    }
  }, [open]);

  useEffect(() => {
    Object.keys(formik.values).forEach((key) => {
      const defaultValue = getDefaultValue(key);
      const currentValue = formik.values[key];

      const urlValue = SEARCH_PARAMS_MAPPING_VALUES.has(searchParams.get(key))
        ? SEARCH_PARAMS_MAPPING_VALUES.get(searchParams.get(key))
        : searchParams.get(key);

      if (!useURLParameters || currentValue === urlValue || (urlValue === null && currentValue === defaultValue)) {
        return;
      }

      triggerByUrlUpdate = true;
      const fieldConfig = filters.find((f) => f.name === key);
      const nextValue = (fieldConfig as SelectFilterConfiguration)?.fieldConfig?.multiple
        ? parseArrayValue(urlValue as string)
        : urlValue;

      setValue(key, nextValue || defaultValue);
    });
  }, [useURLParameters && searchParams]);

  return {
    filters,
    values: { ...formik.values },
    handleChange: formik.handleChange,
    setValue,
    setProp,
    setDisabled,
    countFilterApplies: Object.keys(filtersApplied).filter((key) => filtersApplied[key] !== undefined).length,
    open,
    setOpen,
    filtersApplied,
  };
}
