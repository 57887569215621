import { reloadPage, hasNowInParams } from "@/utils/reloadPage";
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";

interface AssetLoadErrorBoundaryState {
  hasError: boolean;
}

class AssetLoadErrorBoundary extends React.Component<{ t: Function }, AssetLoadErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    if (!hasNowInParams()) {
      return reloadPage();
    }
  }

  onClickButton() {
    reloadPage();
  }

  render() {
    const { t } = this.props;

    if (this.state.hasError) {
      return (
        <Box
          sx={{
            display: "flex",
            minHeight: "90vh",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography color="primary" variant="h4">
            {t("errorPage.title")}
          </Typography>

          <Button onClick={this.onClickButton} sx={{ marginTop: 5 }} variant="contained" size="large" type="submit">
            {t("errorPage.button")}
          </Button>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(AssetLoadErrorBoundary);
