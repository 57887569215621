import { useContext, useEffect, useRef, useState } from "react";
import type { FC, KeyboardEvent } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Drawer,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  ListItemText,
  ListItemIcon,
  List,
  ListItem,
} from "@mui/material";
import SearchIcon from "@/icons/Search";
import XIcon from "@/icons/X";
import Scrollbar from "@/components/Scrollbar";
import { useFormik } from "formik";
import * as yup from "yup";
import { EmployeeBasics } from "@/types/common";
import { useFormikWithTranslations } from "@/utils/formikConnector";
import { useTranslation } from "react-i18next";
import ArrowRight from "@/icons/ArrowRight";
import UserAvatar from "@/components/UserAvatar";
import { searchEmployee } from "@/services/employeeService";
import SettingsContext from "@/contexts/SettingsContext";
import { trackOpenEmployeeSearchBar, trackEmployeeSearchBarInputText, trackEmployeeSearchSubmit } from "./track";

const EmployeeSearch: FC = () => {
  const { openSearch, setOpenSearch } = useContext(SettingsContext);
  const [results, setResults] = useState<EmployeeBasics[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      search: "",
    },
    validationSchema: yup.object({
      search: yup.string().trim().required(t("layout.navbar.employeeSearch.searchIsRequired")),
    }),
    validateOnBlur: false,
    onSubmit: async (values) => {
      trackEmployeeSearchSubmit();
      const employees = await searchEmployee({ searchTerm: values.search });
      setResults(employees.elements);
    },
  });
  const formikField = useFormikWithTranslations(formik);

  const handleResultClick = (id): void => {
    navigate(`/employees/${id}`);
    handleClose();
  };

  const handleOpen = (): void => {
    trackOpenEmployeeSearchBar();
    setOpenSearch(true);
  };

  const handleClose = (): void => {
    setOpenSearch(false);
    formik.resetForm();
    setResults([]);
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.code === "Enter") {
      formik.submitForm();
    }

    trackEmployeeSearchBarInputText();
  };

  const inputRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (openSearch) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 0);
    }
  }, [openSearch, inputRef]);

  return (
    <>
      <Tooltip title="Search">
        <IconButton color="inherit" onClick={handleOpen} size="large">
          <SearchIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="top"
        ModalProps={{ BackdropProps: { invisible: true } }}
        onClose={handleClose}
        open={openSearch}
        PaperProps={{
          sx: { width: "100%" },
        }}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        variant="temporary"
      >
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={handleClose} size="large">
              <XIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ pb: 3 }}>
          <Container maxWidth="md">
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <TextField
                  autoComplete="off"
                  type="search"
                  inputRef={inputRef}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  onKeyUp={handleKeyUp}
                  placeholder={t("layout.navbar.employeeSearch.placeholder")}
                  {...formikField("search")}
                />
                <Button
                  color="primary"
                  onClick={formik.submitForm}
                  size="large"
                  sx={{ ml: 2 }}
                  variant="contained"
                  disabled={formik.isSubmitting}
                >
                  Search
                </Button>
              </Box>
            </form>

            <Box sx={{ mt: 2 }}>
              <Scrollbar options={{ suppressScrollX: true }}>
                {formik.isSubmitting ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <List>
                    {results.map((e, i) => (
                      <ListItem button key={i} onClick={() => handleResultClick(e.id)}>
                        <ListItemIcon>
                          <UserAvatar name={e.name} src={e.avatar} />
                        </ListItemIcon>
                        <ListItemText>
                          {e.name} ({e.department})
                        </ListItemText>
                        <ArrowRight />
                      </ListItem>
                    ))}
                  </List>
                )}
              </Scrollbar>
            </Box>
          </Container>
        </Box>
      </Drawer>
    </>
  );
};

export default EmployeeSearch;
