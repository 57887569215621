import { SegmentOwnerType } from "@/types/common";

export interface Role {
  id: string;
  name: string;
  description: string | null;
  permissions: string[];
  organizationReference: string;
  oldRoleCodeEquivalent: OldRoleCode;
  segmentOwnerType: SegmentOwnerType | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  homeBoardReference: string | null;
}

export interface User {
  id: string;
  avatar?: string;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  employeeReference: string | null;
  organizationReference: string;
  segmentReference: string | null;
  mustRevalidate: boolean;
  role: Role;
  roleId: string;
  createdAt: string;
  lastAccess: string;
  homeBoardReference: string;
}

export enum OldRoleCode {
  ADMIN = "admin",
  HRBP = "hrbp",
  MANAGER = "manager",
  INTEGRATIONS = "integrations",
  VIEWER = "viewer",
}
