import { dashboardAxios as axios } from "@/lib/axios";
import { DriverMetrics } from "@/types/common";
import { fillObjectKeys } from "@/utils/fillMissingData";
import { mapValues } from "lodash";

const ONBOARDING_DRIVER_KEY = "onboarding";
const OFFBOARDING_DRIVER_KEY = "offboarding";

export function getOnboardingSubDriversCode(drivers: DriversWithSubDrivers[]): string[] {
  return drivers.find((d) => d.driverCode === ONBOARDING_DRIVER_KEY)?.subDrivers.map((sd) => sd.subDriverCode) || [];
}

export function getExperienceDriverCodes(drivers: DriversWithSubDrivers[]): string[] {
  return (
    drivers
      .filter((d) => ![OFFBOARDING_DRIVER_KEY, ONBOARDING_DRIVER_KEY].includes(d.driverCode))
      .map((d) => d.driverCode) || []
  );
}

export function getOffboardingSubDriversCode(drivers: DriversWithSubDrivers[]): string[] {
  return drivers.find((d) => d.driverCode === OFFBOARDING_DRIVER_KEY)?.subDrivers.map((sd) => sd.subDriverCode) || [];
}

export function fillEmptySubDrivers(
  driverMetrics: DriverMetrics,
  driversMetadata: DriversWithSubDrivers[]
): DriverMetrics {
  const filledSubDrivers = mapValues({ ...driverMetrics }, (driver, key) => {
    let withSubdrivers = { ...driver };
    const subDrivers = driversMetadata.find((d) => d.driverCode === key)?.subDrivers.map((sd) => sd.subDriverCode);

    withSubdrivers.subDrivers = fillObjectKeys(withSubdrivers.subDrivers || ({} as DriverMetrics), subDrivers || [], {
      value: 0,
    }) as DriverMetrics;

    return withSubdrivers;
  });

  return filledSubDrivers;
}

export interface SubDriver {
  driverCode: string;
  organizationReference: string;
  subDriverCode: string;
  icon: string;
  name: string;
  definition?: string;
}

export interface DriversWithSubDrivers {
  color: string;
  driverCode: string;
  organizationReference: string;
  icon: string;
  name: string;
  definition: string;
  subDrivers: SubDriver[];
}

const cache: Record<string, DriversWithSubDrivers[]> = {};

export enum DriverType {
  DRIVER = "driver",
  SUB_DRIVER = "subDriver",
}

export async function fetchDrivers(
  organizationReference: string | null,
  locale: string
): Promise<DriversWithSubDrivers[]> {
  const cacheKey = `${organizationReference}-${locale}`;

  if (!organizationReference) {
    throw new Error("organizationReference is null");
  }

  if (!cache[cacheKey]) {
    const response = await axios.get<DriversWithSubDrivers[]>(`/v1/chats/drivers?locale=${locale}`);
    cache[cacheKey] = response.data;
  }

  return cache[cacheKey];
}

export function findDriverOrSubdriverByCode(
  code: string,
  allDrivers: DriversWithSubDrivers[]
): { driver: DriversWithSubDrivers; subDriver?: SubDriver } | undefined {
  for (let i = 0; i < allDrivers.length; i++) {
    const driver = allDrivers[i];

    if (driver.driverCode === code) {
      return { driver };
    }

    for (let j = 0; j < driver.subDrivers.length; j++) {
      const subDriver = driver.subDrivers[j];
      if (subDriver.subDriverCode === code) {
        return { driver, subDriver };
      }
    }
  }
}

export function driversForSelectOptions(drivers: DriversWithSubDrivers[]): {
  label: string;
  value: string;
}[] {
  return drivers.map((driver) => ({
    label: driver.name,
    value: driver.driverCode,
  }));
}
