import { forwardRef } from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import type { ScrollBarProps as PerfectScrollbarProps } from "react-perfect-scrollbar";
import { Box } from "@mui/material";
import { isMobile } from "@/utils/isMobile";

interface ScrollbarProps extends PerfectScrollbarProps {}

const Scrollbar = forwardRef<HTMLDivElement, ScrollbarProps>((props, ref) => {
  const { children, ...other } = props;

  if (isMobile) {
    return (
      <Box ref={ref} sx={{ overflowX: "auto" }}>
        {children}
      </Box>
    );
  }

  return (
    <PerfectScrollbar
      // @ts-ignore
      ref={ref}
      {...other}
    >
      {children}
    </PerfectScrollbar>
  );
});

Scrollbar.propTypes = {
  children: PropTypes.node,
};

export default Scrollbar;
