import mixpanel from "mixpanel-browser";
import type { User } from "@/types/user";
import { mixPanelConfig, gtmConfig, isProd, env, APP_NAME } from "../config";
import { gtm, gtmPageView, gtmTrackEvent } from "./gtm";
import { useEffect } from "react";

const source = APP_NAME;

export function configure() {
  gtm.initialize(gtmConfig);

  mixpanel.init(mixPanelConfig, {
    debug: !isProd(),
    loaded: () => {
      mixpanel.register({
        appVersion: env.commit,
      });
    },
  });
}

export function identifyTrackingUser(user: User) {
  const slugUser = `u-${user.id}`;
  let identify = user.employeeReference || slugUser;

  mixpanel.identify(identify);
  mixpanel.people.set({
    org: user.organizationReference,
    role: user.role,
  });

  if (user.employeeReference) {
    mixpanel.alias(user.employeeReference, slugUser);
  }

  if (window.hj) {
    window.hj("identify", identify, {
      org: user.organizationReference,
      role: user.role,
    });
  }
}

export function logoutTrackingUser() {
  mixpanel.reset();
}

type ExtraSectionProperties = Record<string, unknown>;

export type PageTrack = { pageName: string; extraProperties?: ExtraSectionProperties };

export function trackPageView(pageName: string, extraProperties?: ExtraSectionProperties) {
  mixpanel.track(`${source}.pageView`, {
    pageName,
    source,
    ...extraProperties,
  });

  gtmPageView(pageName);
}

export function trackEvent(eventName: string, extraProperties: ExtraSectionProperties = {}) {
  mixpanel.track(`${source}.${eventName}`, { ...extraProperties, source });
  gtmTrackEvent(eventName, extraProperties);
}

export function useTrackPageView(track: PageTrack) {
  useEffect(() => {
    trackPageView(track.pageName, track.extraProperties);
  }, []);
}

export function withTrack(
  opts: { eventName: string; extraParams?: Record<string, string> },
  onChangeCallback: (values: unknown) => void
) {
  return (values: unknown) => {
    trackEvent(opts.eventName, { ...opts.extraParams, values });
    onChangeCallback(values);
  };
}
