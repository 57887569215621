import { Autocomplete, Box, Button, Divider, Grid, Stack, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StepProps } from "../../../hooks/useInstallableIntegration";
import { IntegrationMappableField } from "@/types/common";
import { mappableLaraFieldLocale } from "@/utils/localizeConstants";
import { Fragment, useEffect, useState } from "react";
import { AutocompleteOption } from "@/components/InputAutocomplete";
import { filter, includes, isArray, isEmpty, isString, map, uniq } from "lodash";
import { useEmployeeFields } from "@/hooks/useEmployeeFields";
import AddIcon from "@mui/icons-material/Add";
import CustomField from "../../CustomField";
import { EmployeeFieldType } from "@/services/employeeService";

interface MappableFieldProperties {
  required?: boolean;
  multiple?: boolean;
  commonColumnNames: string[];
}

export const GENERIC_MAPPABLE_LARA_FIELDS = [
  IntegrationMappableField.HRMS_REFERENCE,
  IntegrationMappableField.EMAIL,
  IntegrationMappableField.FIRST_NAME,
  IntegrationMappableField.LAST_NAME,
  IntegrationMappableField.STARTED_WORKING_DATE,
  IntegrationMappableField.COUNTRY,
  IntegrationMappableField.PRINCIPAL_MANAGER_ID,
  IntegrationMappableField.LEAVING_DATE,
  IntegrationMappableField.HRBP_IDS,
  IntegrationMappableField.LEAVING_REASON_TYPE,
  IntegrationMappableField.LEAVING_REASON_DETAIL,
  IntegrationMappableField.LOCALE,
  IntegrationMappableField.TEAMS,
  IntegrationMappableField.COMMUNICATIONS_PRIORITIES,
  IntegrationMappableField.NICKNAME,
  IntegrationMappableField.GENDER,
  IntegrationMappableField.BIRTHDATE,
  IntegrationMappableField.AVATAR,
  IntegrationMappableField.PHONE,
  IntegrationMappableField.JOB_TITLE,
  IntegrationMappableField.DEPARTMENT,
  IntegrationMappableField.SUB_DEPARTMENT,
  IntegrationMappableField.DIVISION,
  IntegrationMappableField.LOCATION,
  IntegrationMappableField.CITY,
  IntegrationMappableField.CONTACT_ENABLED,
  IntegrationMappableField.CONTACT_DISABLED,
  IntegrationMappableField.COHORT_REFERENCE,
  IntegrationMappableField.WORK_MODALITY,
  IntegrationMappableField.GRADE,
  IntegrationMappableField.PERFORMANCE_SCORE,
  IntegrationMappableField.FAQS_ENABLED,
  IntegrationMappableField.COMPANY_NAME,
];

const PROPERTIES_BY_LARA_FIELD: Record<IntegrationMappableField, MappableFieldProperties> = {
  [IntegrationMappableField.EMAIL]: {
    required: true,
    commonColumnNames: ["email", "mail", "e-mail", "workemail"],
  },
  [IntegrationMappableField.FIRST_NAME]: {
    required: true,
    commonColumnNames: ["first name", "nombre", "firstname"],
  },
  [IntegrationMappableField.LAST_NAME]: {
    required: true,
    commonColumnNames: ["last name", "apellido", "lastname"],
  },
  [IntegrationMappableField.NICKNAME]: {
    commonColumnNames: ["nickname", "apodo", "preferredname"],
  },
  [IntegrationMappableField.COUNTRY]: {
    required: true,
    commonColumnNames: ["country", "país", "nacionalidad"],
  },
  [IntegrationMappableField.STARTED_WORKING_DATE]: {
    required: true,
    commonColumnNames: ["fecha de ingreso", "started working date", "fecha de inicio", "hiredate"],
  },
  [IntegrationMappableField.LEAVING_DATE]: {
    required: true,
    commonColumnNames: ["leaving date", "fecha de salida", "terminationdate"],
  },
  [IntegrationMappableField.LEAVING_REASON_TYPE]: {
    commonColumnNames: ["leaving reason type", "motivo de salida"],
  },
  [IntegrationMappableField.LEAVING_REASON_DETAIL]: {
    commonColumnNames: ["leaving reason detail", "razon de salida"],
  },
  [IntegrationMappableField.PRINCIPAL_MANAGER_ID]: {
    required: true,
    commonColumnNames: ["manager", "manager id", "mail manager", "manager mail", "supervisoreid", "supervisorid"],
  },
  [IntegrationMappableField.HRBP_IDS]: {
    required: true,
    multiple: true,
    commonColumnNames: [
      "hrbps",
      "hrbp id",
      "mail hrbp",
      "hrbp mail",
      "mail hr a cargo",
      "customlistadehrsacargoenlara.customhracargo",
    ],
  },
  [IntegrationMappableField.TEAMS]: {
    multiple: true,
    commonColumnNames: ["teams", "team", "equipos", "equipo"],
  },
  [IntegrationMappableField.COMMUNICATIONS_PRIORITIES]: {
    multiple: true,
    commonColumnNames: ["canal prioritario", "customcommunicationchannel"],
  },
  [IntegrationMappableField.GENDER]: {
    commonColumnNames: ["gender", "género", "genero"],
  },
  [IntegrationMappableField.BIRTHDATE]: {
    commonColumnNames: ["birthdate", "birth date", "fecha de nacimiento", "cumpleaños", "dateofbirth"],
  },
  [IntegrationMappableField.AVATAR]: {
    commonColumnNames: ["avatar", "profile picture", "profile pic", "foto de perfil", "avatar url"],
  },
  [IntegrationMappableField.PHONE]: {
    commonColumnNames: [
      "phone",
      "phone number",
      "teléfono",
      "número de teléfono",
      "celular",
      "whatsapp",
      "mobilephone",
    ],
  },
  [IntegrationMappableField.JOB_TITLE]: {
    commonColumnNames: ["job title", "position", "cargo", "posición", "jobtitle"],
  },
  [IntegrationMappableField.LOCALE]: {
    commonColumnNames: ["locale", "idioma", "customlanguageinlara"],
  },
  [IntegrationMappableField.CONTACT_ENABLED]: {
    commonColumnNames: [],
  },
  [IntegrationMappableField.CONTACT_DISABLED]: {
    commonColumnNames: ["no contactar", "customdonotcontactinlara"],
  },
  [IntegrationMappableField.COHORT_REFERENCE]: {
    commonColumnNames: ["cohort", "cohorte", "customcohort", "customcohortinlara", "customcohortenlara"],
  },
  [IntegrationMappableField.HRMS_REFERENCE]: {
    commonColumnNames: ["id"],
  },
  [IntegrationMappableField.WORK_MODALITY]: {
    commonColumnNames: ["work modality", "modalidad de trabajo"],
  },
  [IntegrationMappableField.DEPARTMENT]: {
    commonColumnNames: ["department", "departamento"],
  },
  [IntegrationMappableField.SUB_DEPARTMENT]: {
    commonColumnNames: ["sub department", "sub departamento", "subdepartamento"],
  },
  [IntegrationMappableField.DIVISION]: {
    commonColumnNames: ["division", "división"],
  },
  [IntegrationMappableField.LOCATION]: {
    commonColumnNames: [],
  },
  [IntegrationMappableField.CITY]: {
    commonColumnNames: ["city", "ciudad"],
  },
  [IntegrationMappableField.GRADE]: {
    commonColumnNames: ["grade", "nivel", "seniority"],
  },
  [IntegrationMappableField.PERFORMANCE_SCORE]: {
    commonColumnNames: ["performance score", "performance"],
  },
  [IntegrationMappableField.FAQS_ENABLED]: {
    commonColumnNames: ["faqs enabled", "faqs habilitadas", "faqs"],
  },
  [IntegrationMappableField.COMPANY_NAME]: {
    commonColumnNames: ["customcompanyname"],
  },
};

function autoMapHeaderWithCommonColumnNames(field: IntegrationMappableField, headers: string[]): string | undefined {
  return headers.find((header) =>
    PROPERTIES_BY_LARA_FIELD[field].commonColumnNames.some(
      (commonName: string) => commonName === header.toLocaleLowerCase().trim()
    )
  );
}

export function mapStringToAutocompleteOption(value: string): { label: string; value: string } {
  return { label: value, value };
}

export interface WithMappingsContext {
  headers: string[];
  mappings: Record<string, string | string[]>;
  areValidCustomMappings: boolean;
}

function isValidCustomMapping(arr1: string[], arr2: string[]): boolean {
  const indexes: number[] = [];
  const set = new Set(arr2);

  arr1.forEach((word, index) => {
    if (set.has(word)) {
      indexes.push(index);
    }
  });

  return indexes.length <= 0;
}

export const validateMappings = (errors: string[], context: WithMappingsContext) => {
  const { mappings } = context;
  const mappedFields = Object.keys(mappings);
  if (mappedFields.length === 0) {
    errors.push("mappings");
  }
  const requiredFields = Object.entries(PROPERTIES_BY_LARA_FIELD)
    .filter(([, { required }]) => required)
    .map(([field]) => field);

  const missingRequiredFields = requiredFields.filter((field) => !mappedFields.includes(field));

  if (missingRequiredFields.length > 0) {
    errors.push("mappings");
  }

  const mappingsWithSpace = filter(mappedFields, (value) => value.includes(" "));

  if (mappingsWithSpace.length > 0) {
    errors.push("areValidCustomMappings");
  }
};

const getInitialCustomMappings = (
  record: Record<string, string | string[]>,
  notIn: string[] | IntegrationMappableField[]
): CustomFieldMapping[] => {
  return Object.keys(record)
    .filter((key) => isString(record[key]) && !includes(notIn, key))
    .reduce<CustomFieldMapping[]>((customMappings, key) => {
      customMappings.push({ key, value: record[key] as string });
      return customMappings;
    }, []);
};
export interface CustomFieldMapping {
  key: string;
  value: string;
}

const MapLaraFieldsStep = <Context extends WithMappingsContext>({
  context,
  validationErrors,
  handleSetContextKeyValue,
  isManager,
  externalFieldsTranslation,
}: StepProps<Context> & {
  externalFieldsTranslation: string;
}) => {
  const { getEmployeeFieldTranslation, fields } = useEmployeeFields();

  const mappableLaraFields = uniq([
    ...GENERIC_MAPPABLE_LARA_FIELDS,
    ...fields
      .filter(
        (f) =>
          ![EmployeeFieldType.TEAM, EmployeeFieldType.DATE_RANGE_ENUM, EmployeeFieldType.EMPLOYEE].includes(f.type) &&
          !["employeeId", "birthDate", "reachable"].includes(f.fieldCode) // Calculated fields and birthDate that mismatch the case with birthdate.
      )
      .map((f) => f.fieldCode),
  ]);

  const [customFieldMappings, setCustomFieldMappings] = useState(() =>
    getInitialCustomMappings(context.mappings, mappableLaraFields)
  );
  const { t } = useTranslation();

  const handleAddCustomField = () => {
    setCustomFieldMappings((prev) => [...prev, { key: "", value: "" }]);
  };

  const handleRemoveCustomField = (index: number) => {
    const customFieldsCopy = [...customFieldMappings];
    customFieldsCopy.splice(index, 1);
    setCustomFieldMappings(customFieldsCopy);
  };

  const handleCustomFieldNameChange = (index: number, newKey: string) => {
    const customFieldsCopy = [...customFieldMappings];
    customFieldsCopy[index] = { ...customFieldsCopy[index], key: newKey };
    setCustomFieldMappings(customFieldsCopy);
  };

  const handleCustomMappingChange = (index: number, value: AutocompleteOption | null) => {
    const parsedValue: string = value?.value ?? "";
    const customFieldsCopy = [...customFieldMappings];
    customFieldsCopy[index] = { ...customFieldsCopy[index], value: parsedValue };
    setCustomFieldMappings(customFieldsCopy);
  };

  useEffect(() => {
    handleSetContextKeyValue(
      "areValidCustomMappings",
      isValidCustomMapping(
        map(customFieldMappings, (value) => value.key),
        mappableLaraFields
      )
    );

    const customMappingsRecord = customFieldMappings.reduce<Record<string, string>>((record, { key, value }) => {
      record[key] = value;
      return record;
    }, {});

    const notCustomMappings = Object.fromEntries(
      Object.entries(context.mappings).filter(([key]) => mappableLaraFields.includes(key as IntegrationMappableField))
    );

    handleSetContextKeyValue("mappings", { ...notCustomMappings, ...customMappingsRecord });
  }, [customFieldMappings]);

  const handleMappingChange = (field: string, value: AutocompleteOption | AutocompleteOption[] | null) => {
    const parsedValue: string | string[] = isArray(value) ? value.map((v) => v.value) : value?.value ?? "";

    if ((!isArray(parsedValue) && !isString(parsedValue)) || isEmpty(parsedValue) || parsedValue === "none") {
      const copyOfMappings = { ...context.mappings };
      delete copyOfMappings[field];
      handleSetContextKeyValue("mappings", copyOfMappings);
      return;
    }

    handleSetContextKeyValue("mappings", { ...context.mappings, [field]: parsedValue });
  };

  useEffect(() => {
    if (isManager) {
      // If we are in the context of a manager, we don't want to preselect common headers
      // We just want to show the current mappings
      return;
    }
    // Preselect common headers
    const copyOfMappings = { ...context.mappings };

    mappableLaraFields.forEach((field) => {
      // If field is already pre-mapped, skip
      if (copyOfMappings[field] !== undefined && copyOfMappings[field] !== "") {
        return;
      }

      const commonHeader = autoMapHeaderWithCommonColumnNames(field as IntegrationMappableField, context.headers);
      if (commonHeader) {
        copyOfMappings[field] = commonHeader;
      }
    });

    handleSetContextKeyValue("mappings", copyOfMappings);
  }, [context.headers]);

  return (
    <Box sx={{ display: "flex", gap: 2, alignItems: "center", width: "100%" }}>
      <Stack spacing={2} width={"100%"}>
        {validationErrors.includes("mappings") && (
          <Typography variant="caption" color="error">
            {t("settings.integrationSettings.install.steps.mapFields.error")}
          </Typography>
        )}
        <Divider />
        <Grid container>
          <Grid container item xs={6} sx={{ alignItems: "center" }}>
            <Typography>{t("settings.integrationSettings.install.steps.mapFields.columnTitleLaraFields")}</Typography>
          </Grid>
          <Grid container item xs={6} sx={{ alignItems: "center" }}>
            <Typography>{externalFieldsTranslation}</Typography>
          </Grid>
        </Grid>
        <Divider />
        {mappableLaraFields.map((field) => {
          const properties = PROPERTIES_BY_LARA_FIELD[field] ?? {
            required: false,
            commonColumnNames: [],
          };
          const fieldMapping = context.mappings[field];

          const noneOption = {
            label: t("settings.integrationSettings.install.steps.mapFields.laraField_none"),
            value: "none",
          };
          const options: { label: string; value: string }[] = [
            ...(properties.multiple ? [] : [noneOption]),
            ...context.headers.map(mapStringToAutocompleteOption),
          ];

          const value = properties.multiple
            ? fieldMapping
              ? isArray(fieldMapping)
                ? fieldMapping.map((v) => mapStringToAutocompleteOption(v))
                : [mapStringToAutocompleteOption(fieldMapping)]
              : []
            : fieldMapping
              ? mapStringToAutocompleteOption(isArray(fieldMapping) ? fieldMapping[0] : fieldMapping)
              : noneOption;

          return (
            <Fragment key={field}>
              <Grid container>
                <Grid container item xs={6} sx={{ justifyContent: "center", flexDirection: "column" }}>
                  <Box display="flex">
                    <Typography>
                      {getEmployeeFieldTranslation(
                        field,
                        mappableLaraFieldLocale(t, field as IntegrationMappableField)
                      )}
                    </Typography>
                    {properties.required && <Typography color="error">*</Typography>}
                  </Box>

                  <Box>
                    {properties.multiple && (
                      <Typography variant="caption">
                        {t("settings.integrationSettings.install.steps.mapFields.multipleField")}
                      </Typography>
                    )}
                  </Box>
                </Grid>
                <Grid container item xs={6} sx={{ alignItems: "center" }}>
                  <Autocomplete
                    filterSelectedOptions
                    fullWidth
                    openOnFocus
                    multiple={properties.multiple}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onChange={(_, value) => handleMappingChange(field, value)}
                    value={value}
                    options={options}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          // Placeholder color black
                          "& .MuiInputBase-input::placeholder": {
                            color: "black",
                          },
                        }}
                        placeholder={isArray(value) && value.length === 0 ? noneOption.label : undefined}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Divider />
            </Fragment>
          );
        })}

        {customFieldMappings.map((field, index) => (
          <CustomField
            key={index}
            field={field}
            index={index}
            context={context}
            handleCustomFieldNameChange={handleCustomFieldNameChange}
            handleCustomMappingChange={handleCustomMappingChange}
            handleRemoveCustomField={handleRemoveCustomField}
          />
        ))}
        {validationErrors.includes("areValidCustomMappings") && (
          <Typography variant="caption" color="error">
            {t("settings.integrationSettings.install.steps.mapFields.areValidCustomMappings.error")}
          </Typography>
        )}
        <Button onClick={handleAddCustomField} endIcon={<AddIcon />}>
          {t("settings.integrationSettings.install.steps.mapFields.addField.button.label")}
        </Button>
      </Stack>
    </Box>
  );
};

export default MapLaraFieldsStep;
