import { isString } from "lodash";

export function newDate(rawDate: string | number): Date {
  const date = new Date(rawDate);

  if (isString(rawDate) && rawDate.length > 10) {
    return date;
  }

  var userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + userTimezoneOffset);
}
