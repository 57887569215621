import type { FC, ReactNode } from "react";
import useAuth from "@/hooks/useAuth";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";

interface AuthGuardProps {
  children: ReactNode;
}

const REDIRECT_KEY = "_redirect";

function redirectToPath(path: string, restQueryParams: string) {
  return <Navigate to={`${path}?${REDIRECT_KEY}=${encodeURIComponent(restQueryParams)}`} replace={true} />;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const { isAuthenticated, user, policy, isImpersonating } = useAuth();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const queryParams = searchParams.toString();
  const currentPath = `${location.pathname}${queryParams ? `?${queryParams}` : ""}`;

  if (policy) {
    if (policy === "mfa_challenge") {
      return redirectToPath("/authentication/mfa-challenge", currentPath);
    }
    if (policy === "mfa_required") {
      return redirectToPath("/authentication/mfa-setup", currentPath);
    }
  }

  if (!isAuthenticated) {
    return redirectToPath("/authentication/login", currentPath);
  }

  if (user && user.mustRevalidate && !isImpersonating) {
    return redirectToPath("/authentication/password-reset", currentPath);
  }

  return <>{children}</>;
};

export default AuthGuard;
