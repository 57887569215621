import HomeIcon from "@mui/icons-material/Home";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  Tooltip,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { Outlet, useNavigate, useParams } from "react-router";
import useLocalStorageValue from "@/hooks/useLocalStorageValue";
import { usePromise } from "@/hooks/usePromise";
import { searchFolders } from "@/services/boardService";
import { ErrorBox } from "@/components/ErrorBox";
import { useTranslation } from "react-i18next";
import Collapse from "@mui/material/Collapse";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useBoardsSkipAccessCheck } from "@/modules/board/hooks/useBoardsSkipAccessCheck";

const ToggleSidebarButton: FC<{ open: boolean; setOpen: (open: boolean) => void }> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        pr: 1,
        pl: 2,
        display: "flex",
        flexDirection: "row",
        mt: open ? -1 : -2,
        justifyContent: open ? "flex-end" : "flex-start",
        position: open ? undefined : "absolute",
      }}
    >
      <Tooltip
        title={open ? t<string>("board.list.sidebar.close") : t<string>("board.list.sidebar.open")}
        placement="right"
      >
        <IconButton onClick={() => setOpen(!open)} size="small">
          {open ? <ArrowBackIosNewIcon fontSize="xsmall" /> : <ArrowForwardIosIcon fontSize="xsmall" />}
        </IconButton>
      </Tooltip>
    </Box>
  );
};

const FolderListItem: FC<{ folderName: string; selected: boolean; onClick: () => void }> = ({
  folderName,
  selected,
  onClick,
}) => {
  return (
    <ListItem disablePadding>
      <ListItemButton
        selected={selected}
        onClick={onClick}
        sx={{ pl: 4, width: (theme) => `calc(100% - ${theme.spacing(4)})` }}
      >
        <ListItemIcon>{selected ? <FolderIcon /> : <FolderOpenIcon />}</ListItemIcon>
        <ListItemText
          primary={folderName}
          primaryTypographyProps={{
            sx: {
              width: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            },
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

const CollapsibleList: FC<{ title: string; children: React.ReactNode; defaultOpen: boolean }> = ({
  title,
  children,
  defaultOpen = false,
}) => {
  const [open, setOpen] = useState(defaultOpen);

  useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <FolderIcon />
        </ListItemIcon>
        <ListItemText primary={title} />
        {open ? <ExpandLess color={"action"} /> : <ExpandMore color={"action"} />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  );
};

const SkeletonListItem: FC = () => {
  return (
    <ListItem disablePadding>
      <ListItemButton disableRipple sx={{ py: 1.3 }}>
        <ListItemIcon>
          <Skeleton variant="circular" width={24} height={24} />
        </ListItemIcon>
        <Skeleton width={"100%"} />
      </ListItemButton>
    </ListItem>
  );
};

export const BoardListSidebar: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [boardSidebarOpen, setBoardSidebarOpen] = useLocalStorageValue("boardSidebarOpen", true);
  const { folderId } = useParams();
  const { skipAccessCheck } = useBoardsSkipAccessCheck();

  const foldersPromise = usePromise(
    () => searchFolders({ pageSize: 100, includesWithoutAccess: skipAccessCheck ?? undefined }),
    [skipAccessCheck]
  );

  const isHomeActive = !folderId && window.location.pathname === "/board";
  const isFolderActive = (id: string) => folderId === id;

  useEffect(() => {
    function retryFolderPromise() {
      foldersPromise.retry();
    }
    document.addEventListener("retryFoldersPromise", retryFolderPromise, false);
    return () => {
      document.removeEventListener("retryFoldersPromise", retryFolderPromise);
    };
  }, []);

  return (
    <>
      <Stack direction="row" sx={{ pt: boardSidebarOpen ? 0 : 2 }}>
        <Box
          sx={{
            backgroundColor: "background.default",
            width: boardSidebarOpen ? "100%" : 0,
            height: "calc(100vh - 64px)",
            maxWidth: 300,
            minWidth: boardSidebarOpen ? 200 : 0,
            paddingTop: 2,
            overflowX: "hidden",
            overflowY: "auto",
            position: { xs: boardSidebarOpen ? "fixed" : "initial", lg: "initial" },
            top: 64,
            left: 0,
            zIndex: 1199,
            borderRight: boardSidebarOpen ? "1px solid" : "none",
            borderColor: "divider",
          }}
        >
          <ToggleSidebarButton open={boardSidebarOpen} setOpen={setBoardSidebarOpen} />
          <List>
            <ListItem disablePadding>
              <ListItemButton selected={isHomeActive} onClick={() => navigate("/board")}>
                <ListItemIcon>{isHomeActive ? <HomeIcon /> : <HomeOutlinedIcon />}</ListItemIcon>
                <ListItemText primary={t("board.list.sidebar.all")} />
              </ListItemButton>
            </ListItem>
            {/*
              <ListItem disablePadding>
                <ListItemButton selected={activeId === "favorites"} onClick={() => navigate("/board/favorites")}>
                  <ListItemIcon>{activeId === "favorites" ? <FavoriteIcon /> : <FavoriteBorderIcon />}</ListItemIcon>
                  <ListItemText primary="Favorites" />
                </ListItemButton>
              </ListItem>
            */}

            <CollapsibleList title={t<string>("board.list.sidebar.folders")} defaultOpen={!!folderId}>
              {foldersPromise.render(
                () => (
                  <>
                    <SkeletonListItem />
                    <SkeletonListItem />
                    <SkeletonListItem />
                    <SkeletonListItem />
                    <SkeletonListItem />
                    <SkeletonListItem />
                    <SkeletonListItem />
                    <SkeletonListItem />
                    <SkeletonListItem />
                  </>
                ),
                () => (
                  <ErrorBox onRetry={() => foldersPromise.retry()} />
                ),
                (folders) => {
                  return (
                    <>
                      {folders.elements.map((folder) => (
                        <FolderListItem
                          key={folder.id}
                          folderName={folder.name}
                          selected={isFolderActive(folder.id)}
                          onClick={() => {
                            navigate(`/board/folder/${folder.id}`);
                          }}
                        />
                      ))}
                      {folders.total === 0 && (
                        <ListItem>
                          <ListItemText
                            primary={t("board.list.sidebar.noFolders")}
                            sx={{
                              fontSize: (theme) => theme.typography.caption.fontSize,
                              color: "text.secondary",
                            }}
                          />
                        </ListItem>
                      )}
                      {/* Implement pagination in a next iteration, for now just use a big enough pageSize */}
                      {/* {folders.total > folders.pageSize && (
                      <ListItem disablePadding>
                        <ListItemButton onClick={loadNextPage()}>
                          <ListItemText primary="Load more" />
                        </ListItemButton>
                      </ListItem>
                    )} */}
                    </>
                  );
                }
              )}
            </CollapsibleList>
          </List>
        </Box>
        <Outlet />
      </Stack>
    </>
  );
};
