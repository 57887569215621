import { LaraScoreAssessment } from "@/types/common";
import { reduce } from "lodash";

export const SCORE_LIMIT_VALUES = {
  [LaraScoreAssessment.CRITICAL]: 0, // Crítico
  [LaraScoreAssessment.VERY_LOW]: 30, // Muy bajo
  [LaraScoreAssessment.LOW]: 50, // Bajo
  [LaraScoreAssessment.GOOD]: 60, // Bueno
  [LaraScoreAssessment.GREAT]: 75, // Muy bueno
  [LaraScoreAssessment.AMAZING]: 90, // Increible
};

export function laraScoreLimit(assessment: LaraScoreAssessment): number {
  return SCORE_LIMIT_VALUES[assessment];
}

export function getLaraScoreAssessment(score: number): LaraScoreAssessment {
  return reduce(
    SCORE_LIMIT_VALUES,
    (result, value, key: LaraScoreAssessment) => {
      return score >= value ? key : result;
    },
    LaraScoreAssessment.CRITICAL
  );
}
