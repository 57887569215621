import { useEffect } from "react";
import { usePermission } from "@/hooks/usePermissions";
import useSettings from "@/hooks/useSettings";
import { SettingsObject } from "@/contexts/SettingsContext";
import { PermissionCode } from "@/types/PermissionCode";

interface UseBoardsSkipAccessCheckResponse {
  savePreference(value: boolean): void;
  skipAccessCheck: boolean;
  canConfigureSkipAccessCheck: boolean;
}

/**
 * Hook to get and save preference of boars access.
 */
export function useBoardsSkipAccessCheck(): UseBoardsSkipAccessCheckResponse {
  const { granted: canConfigureSkipAccessCheck, state } = usePermission(PermissionCode.BOARD_SKIP_ACCESS_CHECK, false);
  const { settings, saveSettings } = useSettings();

  function savePreference(value: boolean) {
    saveSettings((previousState: SettingsObject) => ({ ...previousState, boardSkipAccessCheck: value }));
  }

  useEffect(() => {
    if (state !== "resolved") return;
    if (!canConfigureSkipAccessCheck) {
      savePreference(false);
    } else if (settings.boardSkipAccessCheck === undefined) {
      savePreference(true);
    }
  }, [state]);

  return {
    savePreference,
    skipAccessCheck: settings.boardSkipAccessCheck ?? false,
    canConfigureSkipAccessCheck,
  };
}
