import { TeamTreeNavigation } from "@/hooks/useTeams";
import { Box, ListItemButton } from "@mui/material";
import { FC } from "react";
import { ProfileAvatar } from "../profile-avatar/ProfileAvatar";
import { AvatarSize } from "@/types/common";
import { IconPath, IconPathType } from "./IconPath";

export const RecursiveTreeNavigation: FC<{
  tree: TeamTreeNavigation[];
  onClick: (item: TeamTreeNavigation) => void;
  isSearching: boolean;
  isChildren?: boolean;
}> = ({ tree, onClick, isSearching, isChildren = false }) => {
  return (
    <>
      {tree.map((item, index) => (
        <>
          <ListItemButton onClick={() => onClick(item)}>
            <Box sx={{ position: "relative", zIndex: 2 }}>
              <ProfileAvatar
                size={AvatarSize.SMALL}
                user={{
                  name: item.teamName,
                }}
              />
            </Box>

            {!isSearching && !(index === tree.length - 1 && item.children.length === 0) && (
              <IconPath type={item.children.length > 0 ? IconPathType.LEFT_BOTTOM : IconPathType.BOTTOM} />
            )}
          </ListItemButton>

          {item.children && (
            <Box sx={{ position: "relative", left: "20px", top: "-10px" }}>
              <RecursiveTreeNavigation tree={item.children} onClick={onClick} isSearching={isSearching} isChildren />
            </Box>
          )}
        </>
      ))}
    </>
  );
};
