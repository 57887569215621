import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardContent, Typography, Box, Collapse } from "@mui/material";

interface FiltersContainerProps {
  children: React.ReactNode;
  open: boolean;
}

export const FiltersContainer: FC<FiltersContainerProps> = ({ children, open = true }) => {
  const { t } = useTranslation();

  return (
    <Collapse in={open}>
      <Card sx={{ marginBottom: 2 }}>
        <CardContent>
          <Typography sx={{ marginBottom: 2 }}>{t("common.filters.title")}</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 2, md: 0 },
              flexWrap: "wrap",
            }}
          >
            {children}
          </Box>
        </CardContent>
      </Card>
    </Collapse>
  );
};
