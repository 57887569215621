import { ManagerTeamCustom, ManagerTeamHierarchy, TeamCategoryType, useTeams } from "@/hooks/useTeams";
import InputAdornment from "@mui/material/InputAdornment";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import SearchIcon from "@mui/icons-material/Search";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import { Box, Grid, List, Skeleton, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { times } from "lodash";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchInput } from "../SearchInput";
import { TeamItem } from "./TeamItem";
import { RecursiveTreeNavigation } from "./RecursiveTreeNavigation";

const Tabs: FC<{
  category: TeamCategoryType;
  setCategory: (newCategory: TeamCategoryType) => void;
}> = ({ category, setCategory }) => {
  const { t } = useTranslation();

  const handleChangeCategory = (_, value: TeamCategoryType) => {
    if (!value) {
      return;
    }

    setCategory(value);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      exclusive
      onChange={handleChangeCategory}
      fullWidth
      sx={{ mx: "auto", mt: 0, mb: 1 }}
      size="small"
      value={category}
    >
      <ToggleButton value={TeamCategoryType.HIERARCHY} sx={{ textTransform: "none" }}>
        <Typography>{t("team.myTeam.filters.teams")}</Typography>
      </ToggleButton>
      <ToggleButton value={TeamCategoryType.CUSTOM} sx={{ textTransform: "none" }}>
        <Typography>{t("team.myTeam.filters.customTeams")}</Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const ListSkeleton = () => {
  return (
    <Box>
      {times(5, () => (
        <Box sx={{ display: "flex" }} m={1} gap={1} alignContent={"center"} alignItems={"center"}>
          <Skeleton variant="circular" width={"30px"} height={"30px"} />
          <Box>
            <Skeleton variant="text" width={"160px"} />
            <Skeleton variant="text" width={"160px"} />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const NotFoundResults = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ color: "#666", justifyContent: "center", alignItems: "center", display: "flex", gap: 1, p: 4 }}>
      <SentimentDissatisfiedIcon />
      <Typography>{t("team.myTeam.filters.teamsNotFound")}</Typography>
    </Box>
  );
};

const NoTeams = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ color: "#666", justifyContent: "center", alignItems: "center", display: "flex", gap: 1, p: 4 }}>
      <HourglassEmptyIcon />
      <Typography>{t("team.myTeam.filters.emptyTeams")}</Typography>
    </Box>
  );
};

export const PopOverTeams: FC<{
  handleSelectItem: (item: ManagerTeamCustom | ManagerTeamHierarchy) => void;
  viewAs?: string;
  selectedTeamCategory?: TeamCategoryType;
}> = ({ handleSelectItem, viewAs, selectedTeamCategory }) => {
  const { t } = useTranslation();
  const [category, setCategory] = useState<TeamCategoryType>(TeamCategoryType.HIERARCHY);
  const bottomRef = useRef(null);
  const scrollRef = useRef(null);
  const [search, setSearch] = useState();
  const { loading, teams, goNextPage, useTreeNavigation, treeTeamsNavigation } = useTeams(category, search, viewAs);

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const onIntersect = (entries) => {
    if (!entries[0].isIntersecting) {
      return;
    }

    goNextPage();
  };

  useEffect(() => {
    if (selectedTeamCategory) setCategory(selectedTeamCategory);
  }, [selectedTeamCategory]);

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersect);

    if (bottomRef.current) {
      observer.observe(bottomRef.current);
    }

    return () => {
      if (bottomRef.current) {
        observer.unobserve(bottomRef.current);
      }
    };
  }, [teams]);

  useEffect(() => {
    if (scrollRef?.current) {
      (scrollRef.current as { scrollTop: number }).scrollTop = 0;
    }
  }, [category, search]);

  const notFound = search !== "" && search !== undefined && !loading && teams?.length === 0;

  const noTeams = (search === "" || search === undefined) && !loading && teams?.length === 0;

  const loadingNextPage = loading && teams && teams.length > 0;

  return (
    <Grid container pt={1} pl={1} pr={1} width={"450px"}>
      <Box sx={{ position: "relative", width: "100%" }}>
        <Grid item xs={12} textAlign={"center"}>
          <Tabs category={category} setCategory={setCategory} />

          <SearchInput
            name="search"
            value={search || ""}
            onChange={handleChangeSearch}
            fullWidth
            size="small"
            placeholder={t("team.myTeam.filters.placeholderSearch")}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ maxHeight: "230px", overflow: "scroll", overflowX: "hidden" }} ref={scrollRef}>
            {loading && !teams && <ListSkeleton />}

            {teams && (
              <List>
                {useTreeNavigation && treeTeamsNavigation ? (
                  <RecursiveTreeNavigation
                    tree={treeTeamsNavigation}
                    onClick={handleSelectItem}
                    isSearching={!!search}
                  />
                ) : (
                  teams.map((team) => <TeamItem team={team} onClick={handleSelectItem} />)
                )}
              </List>
            )}

            {loadingNextPage && <Skeleton width="100%" height="10px" />}

            {notFound && <NotFoundResults />}

            {noTeams && <NoTeams />}

            {teams && teams?.length > 0 && <div ref={bottomRef} />}
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};
