import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Skeleton,
  Typography,
} from "@mui/material";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputAutocomplete, isOptionItemLoading } from "../InputAutocomplete";
import UserAvatar from "../UserAvatar";

interface EmployeeSelectorDialogProps {
  employees: { id: string; fullName: string; avatar: string; jobTitle: string }[];
  handleAccept: (value: { id: string; label: string; avatar: string; jobTitle: string }) => void;
  handleClose: () => void;
  title: string;
  loading?: boolean;
  handleLoadNextPage: () => void;
  handleSearchChange: (value: string) => void;
}

export const EmployeeSelectorDialog: FC<EmployeeSelectorDialogProps> = ({
  handleAccept,
  handleClose,
  employees,
  title,
  loading,
  handleLoadNextPage,
  handleSearchChange,
}) => {
  const { t } = useTranslation();
  const mapEmployeesToAutocomplete = useMemo(
    () => employees.map((e) => ({ value: e.id, label: e.fullName, avatar: e.avatar, jobTitle: e.jobTitle })),
    [employees]
  );

  const [value, setValue] = useState<{ name: string; value: string } | null>(null);

  const handleChangeValue = (e) => {
    setValue(e.target);
  };

  const handleAcceptValue = (e) => {
    if (!value?.value) return;

    const newMember = mapEmployeesToAutocomplete.find((e) => e.value === value.value);

    if (newMember) {
      handleAccept({ ...newMember, id: newMember.value });
    }
  };

  return (
    <Dialog disableEscapeKeyDown onClose={handleClose} open={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box component="form" sx={{ display: "flex", flexWrap: "wrap", mt: 1 }}>
          <FormControl sx={{ width: { xs: 310, sm: 320 } }}>
            <InputAutocomplete
              fullWidth
              name="employee"
              value={value?.value || null}
              label={t("team.edit.labels.employee")}
              options={mapEmployeesToAutocomplete}
              loadingNextPage={loading}
              handleSearchChange={handleSearchChange}
              onScrollEnd={handleLoadNextPage}
              renderOption={(props, option) => (
                <Box component="li" {...props} sx={{ pointerEvents: isOptionItemLoading(option) ? "none" : "inherit" }}>
                  {isOptionItemLoading(option) ? (
                    <Skeleton variant={"rectangular"} width={"100%"} height={"3px"} />
                  ) : (
                    <>
                      <UserAvatar name={option.label} src={option.avatar} sx={{ mr: 1 }} />
                      <Box>
                        <Typography>{option.label}</Typography>
                        <Typography fontSize={12} sx={{ color: "gray" }}>
                          {option.jobTitle}
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>
              )}
              onChange={handleChangeValue}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleAcceptValue}>Agregar</Button>
      </DialogActions>
    </Dialog>
  );
};
