type ReturnTypes<T> = T extends { [key: string]: (...args: any[]) => infer R } ? R : never;

/**
 * Given an object that is composed of a discriminated union type, a discriminant and a map of functions,
 * executes the function that matches the discriminant property of the object
 * Executes a match operation on the provided object based on a discriminant key.
 *
 * @description This function is a type-safe alternative to switch-case statements, with type inference of the return type and function params.
 * @async Support the inner functions to be async and return a Promise, making the match function async as well.
 * @example
 * const fooOption = { type: 'foo', value: 42 };
 * const barOption = { type: 'bar', message: 'Hello' };
 *
 * function exampleUsage(option): string {
 *   const result: string = match(
 *     option,
 *     'type',
 *     {
 *       foo: (f) => `Foo value: ${f.value}`,
 *       bar: (b) => `Bar message: ${b.message}`,
 *     }
 *   );
 *
 *   console.log(result);
 * }
 *
 * exampleUsage(fooOption); // Output: "Foo value: 42"
 * exampleUsage(barOption); // Output: "Bar message: Hello"
 */
export function match<
  T extends Record<Discriminant, string>,
  Discriminant extends keyof T,
  ExtraParams extends any[],
  Fn extends {
    [K in T[Discriminant]]: (prop: Extract<T, { [Key in Discriminant]: K }>, ...extraParams: ExtraParams) => any;
  }
>(object: T, discriminant: Discriminant, cases: Fn, ...extraParams: ExtraParams): ReturnTypes<Fn> {
  const prop = object[discriminant] as T[Discriminant];
  const caseFunction = cases[prop];

  return caseFunction?.(object as Extract<T, { [Key in Discriminant]: string }>, ...extraParams);
}
