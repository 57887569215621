import type { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Container, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Page from "@/components/Page";

const NotFound: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Page
      track={{ pageName: "notFound" }}
      locales={{
        title: t("notFound.title"),
        subtitle: "",
        headTitle: "",
      }}
      hideTitle
      outerSx={{ overflow: "hidden" }}
    >
      <Box sx={{ px: 3, py: 5 }}>
        <Container>
          <Typography align="center" color="textPrimary" variant={mobileDevice ? "h4" : "h3"}>
            {t("notFound.title")}
          </Typography>
          <Typography align="center" color="textSecondary" sx={{ mt: 2 }} variant="subtitle2">
            {t("notFound.body")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 6,
            }}
          >
            <Box
              alt="Under development"
              component="img"
              src={`/static/error/error404_${theme.palette.mode}.svg`}
              sx={{
                height: "auto",
                maxWidth: "100%",
                width: 300,
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 6,
            }}
          >
            <Button color="primary" component={RouterLink} to="/" variant="outlined">
              {t("notFound.backToHome")}
            </Button>
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default NotFound;
