import { DataPoint } from "@/types/common";
import { difference, flatMap, mapValues, sortBy, uniq } from "lodash";

export function fillDatapoints<T extends string | number | symbol>(
  data: Record<T, DataPoint[]>,
  fill: number | null = null
): Record<T, DataPoint[]> {
  const fetchedDates = uniq(flatMap(data, (dataPoints) => dataPoints.map((dp) => dp.date))).sort();

  return mapValues(data, (dataPoints) => {
    if (fetchedDates.length === dataPoints.length) return sortBy(dataPoints, "date");

    return fetchedDates.map((date) => {
      const dateIndex = dataPoints.findIndex((dp) => dp.date === date);
      const value = dataPoints[dateIndex]?.value || fill;
      return {
        date,
        value,
      };
    });
  });
}

export function fillObjectKeys<T extends string | number | symbol, V>(
  object: Record<T, V>,
  keys: T[],
  fill: V
): Record<T, V> {
  const actualKeys = Object.keys(object) as T[];
  const diff = difference(keys, actualKeys);
  const newObject = { ...object };

  diff.forEach((key) => {
    newObject[key] = fill;
  });

  return newObject;
}
