import { isFunction } from "lodash";

export type FunctionOrValue<Value, FnParams> = Value | ((values: FnParams) => Value);
export type AsyncFunctionOrValue<Value, FnParams> = Value | ((values: FnParams) => Promise<Value>);

/** This type utility returns the Value type given a FnOrValue */
export type ValueType<T> = T extends AsyncFunctionOrValue<infer Value, any>
  ? Value
  : T extends FunctionOrValue<infer Value, any>
  ? Value
  : T;

export function valueFromFunctionOrValue<Value, FnParams>(
  val: FunctionOrValue<Value, FnParams>,
  values: FnParams
): Value {
  if (isFunction(val)) return val(values);

  return val;
}

export function valueFromAsyncFunctionOrValue<Value, FnParams>(
  val: AsyncFunctionOrValue<Value, FnParams>,
  values: FnParams
): Promise<Value> {
  if (isFunction(val)) return val(values);

  return Promise.resolve(val);
}
