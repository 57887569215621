export function countryLocale(locale: string, country: string): string {
  const currentWindow = window as any;
  if (!currentWindow.Intl || !currentWindow.Intl.DisplayNames) return country;

  const countryName = new currentWindow.Intl.DisplayNames([locale], { type: "region" });
  try {
    return countryName.of(country)?.toString() || country;
  } catch (error) {
    console.error("countryLocale.errorWhileFormatting", {
      locale,
      country,
      error,
    });
    return country;
  }
}
